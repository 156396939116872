import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { cleanClasses } from '../../../../utils/utils';
import './mobile-navigation-link.scss';
import { hasPermission, useUser } from '../../../../providers/user';
import { useTemplate } from '../../../../providers/template';
import { useSidebar } from '../../../../providers/sidebar';
import { useLocation } from 'react-router-dom';

/**
 * individual navigation link component
 * for the main navigation column
 *
 * @param {object} props
 * @param {function} props.onClick
 * @param {string} props.classes
 * @param {JSX.Element} props.icon
 * @param {string} props.path
 * @param {string} props.name
 * @param {string} props.permission
 * @returns
 */
export default function MobileNavigationLink({ onClick, icon, path, name, permission, action, disabled }) {
    const { state: user } = useUser();
    const { state: template } = useTemplate();
    const { dispatch: sidebarDispatch } = useSidebar();
    const location = useLocation();

    const disable = name === 'Sectors' && template.sidebarToggleDisabled;

    if (permission && !hasPermission(user, permission)) {
        return null;
    }

    const handler = {};
    if (path) {
        handler.to = path;
        handler.onClick = () => {
            sidebarDispatch({ type: 'setIsOpen', isOpen: false });
        };
    }
    if (onClick) {
        handler.to = '#';
        handler.onClick = disable
            ? (e) => {
                  e.preventDefault();
              }
            : onClick;
    }
    if (disabled) {
        handler.to = '#';
        handler.onClick = (e) => {
            e.preventDefault();
            console.log('Coming soon!');
        };
    }

    const isActive = path === '/' ? location.pathname === path : location.pathname.startsWith(path);
    const mobileLinkClasses = cleanClasses([
        'mobile-navigation-link',
        isActive ? 'active' : '',
        action ? 'action' : '',
        disable || disabled ? 'disabled' : '',
    ]);

    return (
        <Link {...handler} className={mobileLinkClasses}>
            {icon}
            <span>{name}</span>
        </Link>
    );
}

MobileNavigationLink.propTypes = {
    onClick: PropTypes.func,
    classes: PropTypes.string,
    icon: PropTypes.element,
    path: PropTypes.string,
    name: PropTypes.string,
    permission: PropTypes.string,
    action: PropTypes.bool,
    disabled: PropTypes.bool,
};
