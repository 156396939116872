import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Alert from '../../../Alert';
import Button from '../../../Button';

const CoverImageInput = ({ onFileChange, mainText = 'Upload Image', subText, mbSizeLimit = 5, imageFilename }) => {
    const [imageSrc, setImageSrc] = useState(
        imageFilename ? `${process.env.REACT_APP_ASSETS_ENDPOINT}/${imageFilename}` : undefined
    );
    const [error, setError] = useState(null);

    const fileInputRef = useRef(null);

    const validateImage = (file) => {
        setError(null);
        if (file.size > mbSizeLimit * 1024 * 1024) {
            setError(`Cover image file size cannot exceed ${mbSizeLimit}MB`);
            return false;
        }
        return true;
    };

    const handleClick = () => {
        fileInputRef.current.click(); // Use the ref to trigger the file input click
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file && validateImage(file)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
                reader.abort();
            };
            reader.readAsDataURL(file);
            onFileChange(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && validateImage(file)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
                reader.abort();
            };
            reader.readAsDataURL(file);
            onFileChange(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClearClick = () => {
        setImageSrc(null);
        onFileChange(null);
    };

    return (
        <>
            {error && <Alert text={error} type="danger" />} {/* Display error message if there's any */}
            <div className="cover-image-wrapper">
                <div
                    className="cover-image-upload"
                    onClick={handleClick}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    style={{ backgroundImage: imageSrc ? `url(${imageSrc})` : 'none' }}
                >
                    <div className={imageSrc ? 'hidden' : 'placeholder'}>
                        <div className="placeholder-main-text">{mainText}</div>
                        <div className="placeholder-sub-text">{subText}</div>
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        className="hidden"
                    />
                </div>
            </div>
            <div className="button-container">
                <Button type="button" plain={true} text="Clear" onClick={handleClearClick} className="clear-button" />
                <Button type="button" plain={true} text="Change" onClick={handleClick} className="change-button" />
            </div>
        </>
    );
};

CoverImageInput.displayName = 'CoverImageInput';
export default CoverImageInput;

CoverImageInput.propTypes = {
    onFileChange: PropTypes.func.isRequired,
    mainText: PropTypes.string,
    subText: PropTypes.string,
    mbSizeLimit: PropTypes.number.isRequired,
    imageFilename: PropTypes.string,
};
