import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '..';
import './style.scss';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaDiscord } from 'react-icons/fa';
import { ReactSVG } from 'react-svg';

function Navigation({ items }) {
    return (
        <ul className="navigation">
            {items.map((item, index) => (
                <li key={index}>
                    <Link white plain bold to={item.link} text={item.text} />
                </li>
            ))}
        </ul>
    );
}

export default function Footer() {
    const columns = {
        second: [
            { link: '/', text: 'Home' },
            { link: '/about', text: 'About' },
            { link: '/privacy', text: 'Privacy' },
        ],
    };

    return (
        <div className="flank-footer">
            <div className="inner">
                <div className="column">
                    <Link to="/">
                        <ReactSVG className="footer-logo" src="../../assets/images/footer-flank.svg" />
                    </Link>
                </div>
                <div className="column">
                    <Navigation items={columns.second} />
                </div>
                <div className="column icons">
                    <a
                        className="social-icon"
                        href="https://discord.gg/PW5BNfv6Bq"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaDiscord />
                    </a>
                    <a
                        className="social-icon"
                        href="https://www.youtube.com/@davidflanks"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IoLogoYoutube />
                    </a>
                </div>
            </div>
        </div>
    );
}

Navigation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
};
