import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { SelectInput } from '../../../../components';
import AlertDialog from '../../../../components/AlertDialog/AlertDialog';
import { Button } from '../../../../components';
import { ReactSVG } from 'react-svg';
import { IoCloseOutline } from 'react-icons/io5';

function ReferenceSelector({ options, onChange, companySymbol, inputName, currentTopLevelResource }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(currentTopLevelResource);
    const [tempOption, setTempOption] = useState(currentTopLevelResource);

    const handleOptionChange = (option) => {
        setTempOption(option);
    };

    const handleOptionSave = () => {
        setSelectedOption(tempOption);
        if (onChange) {
            onChange(tempOption);
        }
        setIsOpen(false);
    };

    const pencilIcon = <ReactSVG src="/assets/images/icons/navigation-icons/pencil-outline.svg" />;
    return (
        <div className="reference-selector">
            <Button
                type="button"
                plain={true}
                skinny={true}
                text={`$${companySymbol}.${selectedOption.split(' ')[0]}`}
                iconAfter={pencilIcon}
                onClick={() => setIsOpen(true)}
                className="reference-select-button"
            />

            {isOpen && (
                <AlertDialog className="popup-box" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <AlertDialog.Header>
                        <Button
                            xButton={true}
                            plain={true}
                            icon={<IoCloseOutline />}
                            onClick={() => setIsOpen(false)}
                            className="x-button"
                        />
                        <h2 className="popup-header">CHANGE REFERENCE</h2>
                    </AlertDialog.Header>
                    <AlertDialog.Body>
                        <SelectInput
                            name={inputName}
                            options={options}
                            onChange={(e) => handleOptionChange(e.target.value)}
                            value={tempOption}
                        />
                    </AlertDialog.Body>
                    <AlertDialog.Footer className="button-group">
                        <Button plain={true} text="Cancel" onClick={() => setIsOpen(false)} />
                        <Button type="button" action={true} text="Save" onClick={handleOptionSave} />
                    </AlertDialog.Footer>
                </AlertDialog>
            )}
        </div>
    );
}

ReferenceSelector.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    companySymbol: PropTypes.string,
    inputName: PropTypes.string,
    currentTopLevelResource: PropTypes.string,
};

export default ReferenceSelector;
