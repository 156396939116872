import React from 'react';
import PropTypes from 'prop-types';
import { useTitle } from '../../hooks/use-title';
import { Link } from '../../components';
import './style.scss';

export default function ErrorPage({
    errorCode,
    errorShortDescription,
    errorReallyReallyLongAlmostExhaustinglyBoringDescriptionThatWillOnlyCaptivateTheFewPeopleWhoAreActuallyInterestedInReadingIt,
}) {
    useTitle(`${errorCode} ${errorShortDescription}`);
    return (
        <div className="flank-error-page">
            <div className="flank-error-page-content">
                <h1>Woah woah woah!</h1>
                {
                    errorReallyReallyLongAlmostExhaustinglyBoringDescriptionThatWillOnlyCaptivateTheFewPeopleWhoAreActuallyInterestedInReadingIt
                }

                <div className="return">
                    <div className="code">
                        {errorCode} {errorShortDescription}
                    </div>
                    <Link to="/">Go home</Link>
                </div>
            </div>
        </div>
    );
}

ErrorPage.propTypes = {
    errorCode: PropTypes.number.isRequired,
    errorShortDescription: PropTypes.string.isRequired,
    errorReallyReallyLongAlmostExhaustinglyBoringDescriptionThatWillOnlyCaptivateTheFewPeopleWhoAreActuallyInterestedInReadingIt:
        PropTypes.string,
};
