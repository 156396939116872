import createProvider from '../utils/context';

const [useCompany, CompanyProvider] = createProvider({
    initialState: {
        symbol: null,
        company: {},
        loading: false,
        sidebarLinks: null,
        companyColors: {},
        filterIds: [],
        currentPage: null,
        financialTimescale: 'Y',
        quickData: {},
        priceHistory: [],
        incomeStatements: {},
        balanceSheets: {},
        cashFlows: {},
        governance: {},
        strategy: {},
        currentJourney: null,
    },
    reducer: {
        setCompany: (state, action) => {
            return {
                ...state,
                company: action.company,
            };
        },
        setCurrentPage: (state, action) => {
            return {
                ...state,
                currentPage: action.currentPage,
            };
        },
        setQuickData: (state, action) => {
            return {
                ...state,
                quickData: action.quickData,
            };
        },
        setPriceHistory: (state, action) => {
            return {
                ...state,
                priceHistory: action.priceHistory,
            };
        },
        setIncomeStatements: (state, action) => {
            return {
                ...state,
                incomeStatements: action.incomeStatements,
            };
        },
        setBalanceSheets: (state, action) => {
            return {
                ...state,
                balanceSheets: action.balanceSheets,
            };
        },
        setCashFlows: (state, action) => {
            return {
                ...state,
                cashFlows: action.cashFlows,
            };
        },
        setGovernance: (state, action) => {
            return {
                ...state,
                governance: action.governance,
            };
        },
        setStrategy: (state, action) => {
            return {
                ...state,
                strategy: action.strategy,
            };
        },
        setThesis: (state, action) => {
            return {
                ...state,
                thesis: action.thesis,
            };
        },

        setFilterIds: (state, action) => {
            return {
                ...state,
                filterIds: action.filterIds,
            };
        },
        addFilterId: (state, action) => {
            return {
                ...state,
                filterIds: [...state.filterIds, action.id],
            };
        },
        removeFilterId: (state, action) => {
            return {
                ...state,
                filterIds: state.filterIds.filter((id) => id !== action.id),
            };
        },
        setCompanyColors: (state, action) => {
            return {
                ...state,
                companyColors: action.companyColors,
            };
        },
        setFinancialTimescale: (state, action) => {
            return {
                ...state,
                financialTimescale: action.financialTimescale,
            };
        },
        setCurrentJourney: (state, action) => {
            return {
                ...state,
                currentJourney: action.currentJourney,
            };
        },
    },
});

export { useCompany, CompanyProvider };
