import React, { useState } from 'react';
import PostForm from '../../company-post-form/post-form';
import { useCompany } from '../../../../providers/company';
import { useMutation } from '@apollo/client';
import { CREATE_COMPANY_POST_FOR_SELF_MUTATION } from '../../gql-data';
import { Alert } from '../../../../components';
import { useBeforeUnload, useNavigate } from 'react-router-dom';

const CompanyCreatePost = () => {
    const navigate = useNavigate();
    const { state: company } = useCompany();
    const [error, setError] = useState(null);
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useBeforeUnload(
        React.useCallback(() => {
            console.log('UNLOAD THIS');
        }, [unsavedChanges])
    );

    const [createPost] = useMutation(CREATE_COMPANY_POST_FOR_SELF_MUTATION, {
        onCompleted: ({ createPostForSelf }) => {
            setError(null);
            setUnsavedChanges(false);
            navigate(`/company/${company.symbol}/societe/${createPostForSelf.slug}`);
        },
        onError: (err) => {
            setError(err.message);
        },
    });

    const onSubmit = (data) => {
        const strEditor = JSON.stringify(data.editorData);
        setUnsavedChanges(false);
        createPost({
            context: {
                headers: {
                    'apollo-require-preflight': true,
                },
            },
            variables: {
                data: {
                    companyId: company.company.id,
                    companyFinancialsId: 1,
                    content: strEditor,
                    uploadImage: data.imageFile,
                    subtitle: data.subtitle,
                    title: data.title,
                    topLevelResource: data.topLevelResource,
                    type: 'social',
                },
            },
        });
    };

    // Function to handle form validation errors
    const onError = (error, message) => {
        setError(message);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <div>
            {error && <Alert text={error} type="danger" />}
            <PostForm
                onSubmit={onSubmit}
                onError={onError}
                clearError={clearError}
                onInputChange={() => {
                    setUnsavedChanges(true);
                }}
                currentCompanySymbol={company.symbol}
            />
        </div>
    );
};

export default CompanyCreatePost;
