import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';
import { useUser } from '../providers/user';

export function RedirectHandler() {
    let { state: user } = useUser();
    const location = useLocation();

    if (!user?.id) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (location.pathname.startsWith('/$')) {
        const symbol = location.pathname.substring(2);
        return <Navigate to={`/company/${symbol}/overview`} state={{ from: location }} replace />;
    }

    return (
        <ErrorPage
            errorCode={404}
            errorShortDescription="Not found"
            errorReallyReallyLongAlmostExhaustinglyBoringDescriptionThatWillOnlyCaptivateTheFewPeopleWhoAreActuallyInterestedInReadingIt="Looks like something should be different here... but I just can't place my finger on it..."
        />
    );
}
