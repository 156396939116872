import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import './style.scss';
import { useTitle } from '../../hooks/use-title';
import { ThinTemplate } from '../../templates';
import { Form, FormGroup, TextInput, Button, Alert, Link, CheckboxInput, Spacer } from '../../components';
import { ME, LOGIN, ACTIVATION_MUTATION } from '../../gql-data';
import { useUser } from '../../providers/user';
import { useSearchParams } from 'react-router-dom';

export default function Login({ loggedIn }) {
    useTitle('Log In');
    const { state: user, dispatch: userDispatch } = useUser();
    const [error, setError] = useState(null);
    const [loggingIn, setLoggingIn] = useState('');
    const [activated, setActivated] = useState(false);
    const [searchParams] = useSearchParams();

    const [activateUser] = useMutation(ACTIVATION_MUTATION, {
        onCompleted: () => {
            setActivated(true);
            setError(null);
        },
        onError: (error) => {
            setActivated(false);
            setError(error.message);
            console.log(error);
        },
    });

    useEffect(() => {
        const token = searchParams.get('t');
        const queryEmail = searchParams.get('e');
        if (token && queryEmail) {
            activateUser({ variables: { token, email: queryEmail } });
        }
    }, [searchParams, activateUser]);

    const [getMe] = useLazyQuery(ME, {
        onCompleted: (data) => {
            setLoggingIn('');
            userDispatch({
                type: 'setUser',
                user: data.me,
            });
            userDispatch({
                type: 'userLoaded',
                loaded: true,
            });
        },
        onError: (error) => {
            setLoggingIn('');
            setError(error.message);
        },
    });
    const [loginUser, { data: loginData }] = useMutation(LOGIN, {});

    const onSubmit = (data) => {
        setError(null);
        setLoggingIn('Logging In');
        loginUser({
            variables: {
                email: data.email,
                password: data.password,
            },
        }).catch((err) => {
            setError(err.message);
            setLoggingIn('');
        });
    };

    const onError = (error) => {
        setLoggingIn('');
        setError(error[Object.keys(error)[0]].message);
    };

    useEffect(() => {
        if (loginData && getMe) {
            getMe();
        }
    }, [loginData, getMe]);

    return (
        <ThinTemplate instructionTitle="To Continue, please sign into Flank">
            {error && <Alert text={error} type="danger" />}
            {activated && <Alert text={'Account activated!'} type="success" />}
            <Form className="flank-login-form" onSubmit={onSubmit} onError={onError}>
                {(user && user.loaded && user.id) || loggedIn ? (
                    <Alert text="You're already logged in." type="warning" />
                ) : (
                    <>
                        <TextInput
                            type="email"
                            label="Email address or username"
                            name="email"
                            placeholder="Email Address or Username"
                        />
                        <TextInput type="password" label="Password" name="password" placeholder="Password" />
                        <FormGroup chained>
                            <Link to="/forgot-password" dark>
                                Forgot your password?
                            </Link>
                        </FormGroup>
                        <CheckboxInput label="Remember me" name="rememberMe" />
                        <Button type="submit" text="Log In" loading={loggingIn} disabled={!!loggingIn} block action />
                    </>
                )}
            </Form>
            <Spacer />
            <span className="new-account-instruction">Need an account?</span>
            <Link to="/signup" text="Sign up for Flank" block button dark />
        </ThinTemplate>
    );
}

Login.propTypes = {
    loggedIn: PropTypes.bool,
};
