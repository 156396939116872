import React from 'react';
import { useCompany } from '../../../../providers/company';
import CompanyPages from '../../company-pages';
import { cleanClasses } from '../../../../utils/utils';
import CompanyPageNavigationButton from './page-navigation-button';

export default function CompanyPageNavigationButtons() {
    const { state } = useCompany();

    const buttons = [];

    // if there is no child page
    if (!state.currentPage) {
        return null;
    }

    // if there is a previous page, add a button to go back
    const page = CompanyPages[state.currentPage[0]];
    const pages = Object.keys(CompanyPages);
    const subPages = page.children ? Object.keys(page.children) : [];

    const pageType = state.currentPage[1] ? 'subpage' : 'page';

    const currentPageIndex = state.currentPage[1]
        ? subPages.indexOf(state.currentPage[1])
        : pages.indexOf(state.currentPage[0]);
    let nextIsTopLevel = false;
    let previousIsTopLevel = false;
    let nextPage;
    let previousPage;
    let previousPageParent;

    // if we are at a top level page
    if (pageType === 'page') {
        // and there are children to go to
        if (subPages.length > 0) {
            nextPage = subPages[0];

            // and there are no children to go to
        } else {
            // if there is another top level page to go to
            if (currentPageIndex < pages.length - 1) {
                nextPage = pages[currentPageIndex + 1];
                nextIsTopLevel = true;
            } else {
                nextPage = null;
            }
        }

        // and we are not the first page
        if (currentPageIndex !== 0) {
            // get the previous page
            previousPage = pages[currentPageIndex - 1];

            // if the previous page has children
            if (CompanyPages[previousPage].children) {
                previousPageParent = previousPage;

                // get the last child
                const previousPageSubPages = Object.keys(CompanyPages[previousPage].children);
                previousPage = previousPageSubPages[previousPageSubPages.length - 1];

                // if the previous page does not have children
            } else {
                previousIsTopLevel = true;
            }
        }
    } else {
        // if we are not the first subpage
        if (currentPageIndex !== 0) {
            previousPageParent = state.currentPage[0];

            // get the previous subpage
            previousPage = subPages[currentPageIndex - 1];
        } else {
            // get the top level page
            previousPage = state.currentPage[0];
            previousIsTopLevel = true;
        }

        // if there is another subpage to go to
        if (currentPageIndex < subPages.length - 1) {
            nextPage = subPages[currentPageIndex + 1];
        } else {
            // if there is another top level page to go to
            if (pages.indexOf(state.currentPage[0]) < pages.length - 1) {
                nextPage = pages[pages.indexOf(state.currentPage[0]) + 1];
                nextIsTopLevel = true;
            } else {
                nextPage = null;
            }
        }
    }

    if (previousPage) {
        buttons.push(
            <CompanyPageNavigationButton
                path={
                    previousIsTopLevel
                        ? `/company/${state.symbol}/${previousPage}`
                        : `/company/${state.symbol}/${previousPageParent}/${previousPage}`
                }
                text={
                    previousIsTopLevel
                        ? CompanyPages[previousPage].text
                        : CompanyPages[previousPageParent].children[previousPage].text
                }
                direction="previous"
                key={
                    previousIsTopLevel
                        ? `/company/${state.symbol}/${previousPage}`
                        : `/company/${state.symbol}/${previousPageParent}/${previousPage}`
                }
            />
        );
    } else {
        buttons.push(null);
    }

    if (nextPage) {
        buttons.push(
            <CompanyPageNavigationButton
                path={
                    nextIsTopLevel
                        ? `/company/${state.symbol}/${nextPage}`
                        : `/company/${state.symbol}/${state.currentPage[0]}/${nextPage}`
                }
                text={nextIsTopLevel ? CompanyPages[nextPage].text : page.children[nextPage].text}
                direction="next"
                key={
                    nextIsTopLevel
                        ? `/company/${state.symbol}/${nextPage}`
                        : `/company/${state.symbol}/${state.currentPage[0]}/${nextPage}`
                }
            />
        );
    } else {
        buttons.push(null);
    }

    return (
        <div className={cleanClasses(['flank-company-page-navigation-buttons', buttons[0] === null ? 'right' : null])}>
            {buttons}
        </div>
    );
}
