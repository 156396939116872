// StepTwo.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GuideHeadline } from '../../../components/Research/guide-headline';
import { ResearchBody } from '../../../components/Research/research-body';
import { ForwardButton } from '../../../components/Research/forward-button';
import { SectorFilters } from '../../Search/components';

export function StepTwo({ setCurrentPage, currentPage, selectedFilters, setSelectedFilters }) {
    const [isSectorSelected, setIsSectorSelected] = useState(false);
    function updateSelectedFilters(key, value) {
        setSelectedFilters((filters) => ({
            ...filters,
            [key]: value,
        }));
    }

    useEffect(() => {
        setIsSectorSelected(selectedFilters.sectors.length > 0);
    }, [selectedFilters.sectors]);

    const handleSectorChange = (sectors) => {
        updateSelectedFilters('sectors', sectors);
        setIsSectorSelected(sectors.length > 0);
    };

    const handleForwardButtonClick = () => {
        if (isSectorSelected) {
            setCurrentPage(currentPage + 1);
        } else {
            alert('Please select at least one sector to proceed.');
        }
    };
    return (
        <div className="guided-research">
            <GuideHeadline
                step="Step 2"
                title="Circle of Competence"
                onClick={() => setCurrentPage(currentPage > 0 ? currentPage - 1 : 0)}
            >
                <p>
                    <i>
                        &quot;Defining your circle of competence is the most important aspect of investing... I&apos;m
                        no genius, but I&apos;m smart in spots and I stay around those spots. That is the key.&quot;
                    </i>
                </p>
                <p style={{ textAlign: 'right' }}>-Warren Buffett</p>
            </GuideHeadline>
            <ResearchBody>
                <p>
                    You have <strong>unique skills</strong> and <strong>experiences</strong> that no one else does. This
                    is your circle of competence. Invest in what you know, and know why you own it. Your circle of
                    competence can grow, but always be aware that you don&#39;t know what you don&#39;t know.
                </p>
                <p>
                    If, for example, you work in property insurance, maybe try <strong>Financials</strong> or{' '}
                    <strong>Real Estate.</strong> If you are a biochemist or in medicine, consider{' '}
                    <strong>Healthcare.</strong>
                </p>
                <p>
                    Eventually, you will want a diversified portfolio of companies in different sectors you understand.
                    We will get there eventually, but first we will start with something we are more familiar with.
                </p>
            </ResearchBody>
            <ResearchBody title="Sectors of Strength" classStyle="section-title">
                <p>
                    These sectors of the Stock Market are defined in the Global Industry Classification Standard (GICS)
                </p>
                <p>
                    Using your circle of competence, select sector(s) that you would like to filter companies out of. We
                    recommend sectors that you have interest or experience in.
                </p>
            </ResearchBody>
            <div className="sector-selector">
                <SectorFilters layout="grid" onChange={handleSectorChange} selectedFilters={selectedFilters.sectors} />
            </div>
            <p>Next, we will narrow the search further by selecting an industry within the sector.</p>
            <ForwardButton text={'Industry Selection'} onClick={handleForwardButtonClick} />
        </div>
    );
}

StepTwo.propTypes = {
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    selectedFilters: PropTypes.object,
    setSelectedFilters: PropTypes.func,
};
