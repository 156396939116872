import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GuideHeadline } from '../../../components/Research/guide-headline';
import { ResearchBody } from '../../../components/Research/research-body';
import { ForwardButton } from '../../../components/Research/forward-button';
import { SectorMap } from '../../Search/search-filters-data';
import './style.scss';
import { ReactSVG } from 'react-svg';

export function StepThree({ setCurrentPage, currentPage, selectedSectors, onDoneResearch }) {
    const [selectedButton, setSelectedButton] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    console.log(selectedIndustry);

    const handleIndustrySelect = (industry) => {
        if (selectedButton.includes(industry)) {
            setSelectedButton(selectedButton.filter((item) => item !== industry));
        } else {
            setSelectedButton([...selectedButton, industry]);
        }
        setSelectedIndustry(industry);
    };

    return (
        <div className="guided-research">
            <GuideHeadline
                step="Step 3"
                title="Industries"
                onClick={() => setCurrentPage(currentPage > 0 ? currentPage - 1 : 0)}
            >
                <p>
                    <i>
                        &quot;The game of investing is one of making better predictions about the future than other
                        people. How are you going to do that? One way is to limit your tries to areas of competence. If
                        you try to predict the future of everything, you attempr too much&quot;
                    </i>
                </p>
                <p style={{ textAlign: 'right' }}>-Charlie Munger</p>
            </GuideHeadline>
            <ResearchBody title="Industry Groups" classStyle="section-title">
                <p>
                    Industry groups within each sector are also delined in the Global Industry Classification Standard
                    (GICS)
                </p>
                <p>
                    We will refine our selection further by choosing one or more industry groups within the sectors we
                    chose:
                </p>
            </ResearchBody>

            <div className="individual-sector">
                {selectedSectors &&
                    selectedSectors.length > 0 &&
                    selectedSectors.map((sector, index) => (
                        <div key={index} className="sector-container">
                            <h3 className="industry-title">
                                <ReactSVG
                                    src={`./../../assets/images/icons/sector-icons/${sector}.svg`}
                                    style={{ marginRight: '5px' }}
                                />
                                {`${sector}`}
                            </h3>
                            <div className="industry-buttons">
                                {SectorMap[sector].map((industry, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handleIndustrySelect(industry)}
                                        className={selectedButton.includes(industry) ? 'selected' : ''}
                                    >
                                        {industry}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
            <p>Next, we will sort a list of companies in the selected industry/industries.</p>
            <ForwardButton text={'Company Selection'} onClick={onDoneResearch} />
        </div>
    );
}

StepThree.propTypes = {
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    selectedSectors: PropTypes.array,
    onDoneResearch: PropTypes.func.isRequired,
    setSelectedIndustry: PropTypes.array,
    setSelectedFilters: PropTypes.object,
    selectedFilters: PropTypes.object,
};
