import { useEffect } from 'react';

export function useTitle(title) {
    useEffect(() => {
        if (!title) {
            document.title = 'Flank';
        } else {
            document.title = title + ' - Flank';
        }
    }, [title]);
}

export function updateTitle(title) {
    if (!title) {
        document.title = 'Flank';
    } else {
        document.title = title + ' - Flank';
    }
}
