import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Button, Spacer, Link, Form, FormGroup, TextInput, Alert } from '..';
import { IoMailOpenOutline, IoCloseOutline, IoPersonCircle, IoTrash, IoSad, IoKey } from 'react-icons/io5';
import { useUser } from '../../providers/user';
import { useMutation } from '@apollo/client';
import { UPDATE_CURRENT_USER_MUTATION, CHANGE_PW_MUTATION, LOGOUT } from '../../gql-data';

export default function AccountSettings() {
    const { state: user, dispatch: userDispatch } = useUser();
    const [feedback, setFeedback] = useState(null);
    const [name, setName] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
    });
    const [email, setEmail] = useState(user.email);
    const [submissionType, setSubmissionType] = useState(null);

    const [logoutUser, { error: logoutError, data: logoutData }] = useMutation(LOGOUT);

    useEffect(() => {
        if ((logoutData || logoutError) && userDispatch) {
            userDispatch({
                type: 'setUser',
                user: { id: null },
            });
        }
    }, [userDispatch, logoutData, logoutError]);

    const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER_MUTATION, {
        onCompleted: () => {
            if (submissionType === 'name-change') {
                setFeedback({ type: 'success', text: 'Name successfully updated!' });
                userDispatch({
                    type: 'setUser',
                    user: {
                        ...user,
                        firstName: name.firstName,
                        lastName: name.lastName,
                    },
                });
            }
            if (submissionType === 'email-change') {
                setFeedback({ type: 'success', text: 'Email successfully updated!' });
                userDispatch({
                    type: 'setUser',
                    user: {
                        ...user,
                        email: email,
                    },
                });
            }
        },
        onError: (error) => {
            setFeedback({ type: 'danger', text: error.message });
        },
    });

    const [changeCurrentPW] = useMutation(CHANGE_PW_MUTATION, {
        onCompleted: () => {
            setFeedback({ type: 'success', text: 'Password successfully updated!' });
        },
        onError: (error) => {
            setFeedback({ type: 'danger', text: error.message });
        },
    });

    const handleEmailSubmit = (data) => {
        setSubmissionType('email-change');
        setFeedback(null);
        setEmail(data.email);
        updateCurrentUser({
            variables: {
                data: {
                    email: data.email,
                },
            },
        });
    };

    const handleNameChangeSubmit = (data) => {
        setName({ firstName: data.firstName, lastName: data.lastName });
        setFeedback(null);
        setSubmissionType('name-change');
        updateCurrentUser({
            variables: {
                data: {
                    lastName: data.lastName,
                    firstName: data.firstName,
                },
            },
        });
    };
    const handlePWChangeSubmit = (data) => {
        setSubmissionType('pw-change');
        setFeedback(null);
        changeCurrentPW({
            variables: {
                oldPassword: data.currentPassword,
                newPassword: data.newPassword,
                confirmNewPassword: data.confirmNewPassword,
            },
        });
    };

    if (!user) {
        return null;
    }

    return (
        <div className="user-settings-wrapper">
            <div className="account-preferences">
                <Spacer header={'ACCOUNT PREFERENCES'} className="spacer" />
            </div>
            <AccountPreference
                title="Email Address"
                value={user.email}
                onChange="modal"
                modalTitle={'Update your email'}
                titleIcon={<IoMailOpenOutline />}
                setAlert={setFeedback}
                alert={feedback}
                modalDescription="Update your email below"
            >
                <Form
                    onSubmit={handleEmailSubmit}
                    defaultValues={{
                        email: user.email,
                    }}
                >
                    <FormGroup>
                        <TextInput
                            type="email"
                            label="Email Address"
                            name="email"
                            placeholder={'Enter your new email address'}
                        />
                    </FormGroup>
                    <Button type="submit" block={true} action={true} text="Update Email Address" />
                </Form>
            </AccountPreference>
            <AccountPreference
                title="Change Password"
                titleIcon={<IoKey />}
                value="Password must be at least 8 characters long"
                onChange="modal"
                modalTitle="Change Password"
                setAlert={setFeedback}
                alert={feedback}
                modalDescription="To change your password, please enter the following required fields:"
            >
                <Form onSubmit={handlePWChangeSubmit}>
                    <FormGroup>
                        <TextInput
                            type="password"
                            label="Current Password"
                            name="currentPassword"
                            placeholder="Enter your current password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextInput
                            type="password"
                            label="New Password"
                            name="newPassword"
                            placeholder="Enter your new password"
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextInput
                            type="password"
                            label="Confirm New Password"
                            name="confirmNewPassword"
                            placeholder="Please confirm your new password"
                        />
                    </FormGroup>

                    <Button type="submit" block={true} action={true} text="Update Password" />
                </Form>
            </AccountPreference>
            <AccountPreference
                title="Edit Name"
                value={user.firstName + '  ' + user.lastName}
                onChange="modal"
                modalTitle={'Update your name'}
                setAlert={setFeedback}
                alert={feedback}
                modalDescription="To change your name, please enter the following required fields:"
                titleIcon={<IoPersonCircle />}
            >
                <Form
                    onSubmit={handleNameChangeSubmit}
                    defaultValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                    }}
                >
                    <FormGroup>
                        <TextInput type="text" label="First Name" name="firstName" placeholder="John" />
                    </FormGroup>
                    <FormGroup>
                        <TextInput type="text" label="Last Name" name="lastName" placeholder="Doe" />
                    </FormGroup>
                    <Button type="submit" block={true} action={true} text="Update Name" />
                </Form>
            </AccountPreference>
            <div className="delete-spacer">
                <Spacer header={'MANAGE ACCOUNT'} />
            </div>
            <Button
                className="logout-link"
                type="submit"
                text={`Logout as ${user.username}`}
                onClick={logoutUser}
                plain
                danger
                style={{ border: '0.8px solid red' }}
            ></Button>
            <AccountPreference
                title=""
                value=""
                onChange="modal"
                deleteButton="deleteAccount"
                modalTitle={'Delete Account'}
                titleIcon={<IoSad />}
                modalDescription={`We hate to see you go, but if you must, please confirm with a quick email.
          
          We apologize for the inconvenience, but hey, this is Alpha testing for a reason!`}
            >
                <Form onSubmit={() => {}}>
                    <FormGroup></FormGroup>
                    <Link
                        button={true}
                        block={true}
                        external={true}
                        to={'mailto:david@theflank.io?subject=Delete%20Account'}
                        text="Email Us"
                        bold={true}
                    />
                </Form>
            </AccountPreference>
        </div>
    );
}

const AccountPreference = ({
    title,
    value,
    onChange,
    children,
    href,
    modalTitle,
    modalDescription,
    titleIcon,
    deleteButton,
    alert,
    setAlert,
}) => {
    const [changeModalOpen, setChangeModalOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClick = () => {
        if (onChange === 'modal') {
            setChangeModalOpen(!changeModalOpen);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOut, false);
        if (setAlert && typeof setAlert === 'function') {
            setAlert(null);
        }
        return () => {
            document.removeEventListener('click', handleClickOut, false);
        };
    }, [setAlert]);

    useEffect(() => {
        if (!changeModalOpen) {
            if (setAlert && typeof setAlert === 'function') {
                setAlert(null);
            }
        }
    }, [changeModalOpen, setAlert]);

    const handleClickOut = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setChangeModalOpen(false);
        }
    };

    return (
        <>
            <div className="account-preference">
                <div>
                    <div className="account-preference-title">{title}</div>
                    <div className="account-preference-tag">{value}</div>
                </div>
                <div ref={buttonRef} className="change-button">
                    {onChange === 'modal' && deleteButton === 'deleteAccount' ? (
                        <Button
                            type="submit"
                            text="Delete Account"
                            onClick={handleClick}
                            plain
                            danger
                            icon={<IoTrash />}
                        />
                    ) : onChange === 'modal' ? (
                        <Button type="button" text="Change" onClick={handleClick} greenOutline={true} />
                    ) : (
                        <Link text="Change" button={true} to={href} />
                    )}
                </div>
            </div>

            {changeModalOpen && children && (
                <div className="modal">
                    <div ref={menuRef} className="modal-content">
                        <div className="x-button-wrapper">
                            <Button
                                type="submit"
                                xButton={true}
                                plain={true}
                                icon={<IoCloseOutline />}
                                onClick={handleClick}
                            />
                        </div>
                        <div className="title-wrapper">
                            <div className="icon-wrapper">{titleIcon}</div>
                            <div className="modal-title">{modalTitle}</div>
                        </div>
                        {alert ? (
                            <Alert className="alert" text={alert.text} type={alert.type} />
                        ) : (
                            <div className="modal-description">{modalDescription}</div>
                        )}
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

AccountPreference.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.string,
    children: PropTypes.node,
    href: PropTypes.string,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
    titleIcon: PropTypes.node,
    deleteButton: PropTypes.string,
    alert: PropTypes.object,
    setAlert: PropTypes.func,
};
