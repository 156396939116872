import React from 'react';
import PropTypes from 'prop-types';

import { useState } from 'react';
import './style.scss';
import { useTitle } from '../../hooks/use-title';
import { GuideHeadline } from '../../components/Research/guide-headline';
import { ResearchBody } from '../../components/Research/research-body';
import { ForwardButton } from '../../components/Research/forward-button';
import { StepZero } from './steps/StepZero';
import { StepOne } from './steps/StepOne';
import { StepTwo } from './steps/StepTwo';
import { StepThree } from './steps/StepThree';
import { CompanySizeFilterBy } from '../Search/components';
import { useNavigate } from 'react-router-dom';

export default function GuidedResearch() {
    useTitle(null);
    const [currentPage, setCurrentPage] = useState(0);
    const navigate = useNavigate();
    const [selectedFilters, setSelectedFilters] = useState({
        sectors: [],
        industry: [],
        companySize: {
            field: CompanySizeFilterBy.MARKET_CAP,
            values: [],
        },
        ratiosAndMetrics: [],
    });

    const selectedSectors = selectedFilters.sectors;
    const selectedIndustries = selectedFilters.industry;

    function onDoneResearch() {
        navigate('/search', {
            state: {
                selectedFilters,
            },
        });
    }

    return (
        <>
            {currentPage === 0 && <StepZero setCurrentPage={setCurrentPage} currentPage={currentPage} />}
            {currentPage === 1 && <StepOne setCurrentPage={setCurrentPage} currentPage={currentPage} />}
            {currentPage === 2 && (
                <StepTwo
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
            )}
            {currentPage === 3 && (
                <StepThree
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    selectedSectors={selectedSectors}
                    onDoneResearch={onDoneResearch}
                    setSelectedIndustry={selectedIndustries}
                />
            )}
        </>
    );
}

GuideHeadline.propTypes = {
    step: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

ResearchBody.propTypes = {
    children: PropTypes.node.isRequired,
};

ForwardButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
