import React from 'react';
import { useUser, hasPermission } from '../providers/user';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import Home from '../pages/Home';
import AlphaSignupPage from '../pages/AlphaSignup';

export function RequireAuth() {
    let { state: user } = useUser();
    let location = useLocation();

    if (!user?.id) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export function RequireAdmin() {
    let { state: user } = useUser();
    let location = useLocation();

    if (!user?.id || !hasPermission(user, 'admin-rights')) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export function RequireAnon() {
    let { state: user } = useUser();
    let location = useLocation();

    if (user?.id) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return <Outlet />;
}

export function AlphaHome() {
    let { state: user } = useUser();

    if (user?.id) {
        return <Home />;
    }

    return <AlphaSignupPage />;
}
