import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonChain } from '../Button';

export default function TimescaleButtons({ activeTimescale, setActiveTimescale }) {
    return (
        <ButtonChain timescale={true}>
            <Button text="1M" active={activeTimescale === '1M'} onClick={() => setActiveTimescale('1M')} />
            <Button text="3M" active={activeTimescale === '3M'} onClick={() => setActiveTimescale('3M')} />
            <Button text="6M" active={activeTimescale === '6M'} onClick={() => setActiveTimescale('6M')} />
            <Button text="1Y" active={activeTimescale === '1Y'} onClick={() => setActiveTimescale('1Y')} />
            <Button text="5Y" active={activeTimescale === '5Y'} onClick={() => setActiveTimescale('5Y')} />
        </ButtonChain>
    );
}

TimescaleButtons.propTypes = {
    activeTimescale: PropTypes.string.isRequired,
    setActiveTimescale: PropTypes.func.isRequired,
};
