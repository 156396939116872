import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TypeaheadBox } from '../../../TypeAhead/typeahead';
import './style.scss';
import { cleanClasses } from '../../../../utils/utils';

const TextInput = forwardRef(function TextInput(
    {
        register,
        name,
        label,
        hideLabel,
        defaultValue,
        validate,
        typeahead,
        typeaheadOptions,
        typeaheadOnChange,
        typeaheadOnSelect,
        typeaheadLoading,
        typeaheadMinLength,
        resetValue,
        onBlur,
        multiline,
        rows,
        className,
        ...rest
    },
    ref
) {
    delete rest.componentType;
    const [typeaheadOpen, setTypeaheadOpen] = useState(false);
    const [typeaheadOffscreen, setTypeaheadOffscreen] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [internalLoading, setInternalLoading] = useState(typeaheadLoading);
    const inputRef = useRef(null);
    const [error, setError] = useState(false);

    let submitFormTimeout = useRef(null);

    useEffect(() => {
        setInternalLoading(typeaheadLoading);
    }, [typeaheadLoading]);

    const handleOnChange = (e, registeredOnChange) => {
        if (registeredOnChange) {
            registeredOnChange(e);
        }
        if (e.target.value === '') {
            setTypeaheadOpen(false);
            setInternalLoading(false);
        } else {
            if (e.target.value.length >= (typeaheadMinLength || 3)) {
                setInternalLoading(true);
            }
        }
        setInputValue(e.target.value);
        clearTimeout(submitFormTimeout.current);
        submitFormTimeout.current = setTimeout(() => {
            clearTimeout(submitFormTimeout.current);
            if (
                typeaheadOnChange &&
                typeof typeaheadOnChange === 'function' &&
                e.target.value.length >= (typeaheadMinLength || 3)
            ) {
                typeaheadOnChange(e.target.value);
            }
        }, 800);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            const index = typeaheadOptions.findIndex((option) => option.label === e.target.value);
            if (e.key === 'ArrowUp') {
                if (index > 0 && !typeaheadOffscreen) {
                    setInputValue(typeaheadOptions[index - 1].label);
                } else {
                    setInputValue(typeaheadOptions[index + 1].label);
                }
            } else if (e.key === 'ArrowDown') {
                if (index < typeaheadOptions.length - 1 && !typeaheadOffscreen) {
                    setInputValue(typeaheadOptions[index + 1].label);
                } else {
                    setInputValue(typeaheadOptions[index - 1].label);
                }
            }
        } else if (e.key === 'Enter') {
            if (typeaheadOnSelect && typeof typeaheadOnSelect === 'function') {
                const index = typeaheadOptions.findIndex((option) => option.label === e.target.value);
                typeaheadOnSelect(typeaheadOptions[index].value);
                setTypeaheadOpen(false);
            }
        }
    };

    const handleOnBlur = (e, registeredOnBlur) => {
        if (registeredOnBlur) {
            registeredOnBlur(e);
        }
        if (!e.relatedTarget || (inputRef.current && !inputRef.current.contains(e.relatedTarget))) {
            if (onBlur && typeof onBlur === 'function') {
                onBlur(e);
            }
        }
    };

    useEffect(() => {
        setTypeaheadOpen(typeaheadOptions && typeaheadOptions.length > 0 && inputValue !== '');
    }, [typeaheadOptions, inputValue]);

    useEffect(() => {
        if (resetValue && resetValue !== 0) {
            setInputValue('');
        }
    }, [resetValue]);

    let registered = null;
    if (register) {
        registered = register(name, {
            onChange: handleOnChange,
            value: rest.value,
            validate: (value) => {
                if (!validate || typeof validate !== 'function') {
                    return true;
                }

                const validation = validate(value);

                if (validation) {
                    setError(validation);
                    return false;
                } else {
                    setError(false);
                    return true;
                }
            },
        });
    }
    if (multiline) {
        delete rest.value;
        return (
            <div className="text-input-wrapper">
                <div className="flank-form-group" ref={inputRef}>
                    {!hideLabel && (
                        <label className="flank-form-label" htmlFor={name}>
                            {label}
                        </label>
                    )}
                    <textarea
                        ref={(e) => {
                            if (ref) ref.current = e;
                            registered?.ref(e);
                        }}
                        name={registered?.name}
                        id={name}
                        rows={rows || 3}
                        className={cleanClasses(['flank-form-control', rest.className])}
                        {...(registered || {})}
                        {...rest}
                    >
                        {inputValue}
                    </textarea>
                    {error && <p className="error">{error}</p>}
                </div>
            </div>
        );
    }
    if (label) {
        delete rest.value;
        return (
            <div className="flank-form-group" ref={inputRef}>
                {!hideLabel && (
                    <label className="flank-form-label" htmlFor={name}>
                        {label}
                    </label>
                )}
                <input
                    ref={(e) => {
                        if (ref) ref.current = e;
                        registered?.ref(e);
                    }}
                    name={registered?.name}
                    id={name}
                    className={cleanClasses(['flank-form-control', className])}
                    onChange={(e) => handleOnChange(e, registered.onChange)}
                    onKeyUp={handleKeyPress}
                    onBlur={(e) => handleOnBlur(e, registered.onBlur)}
                    {...rest}
                />
                {typeahead && (typeaheadOpen || internalLoading) && (
                    <TypeaheadBox
                        typeaheadOnSelect={typeaheadOnSelect}
                        setTypeaheadOpen={setTypeaheadOpen}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        typeaheadOptions={typeaheadOptions}
                        typeaheadLoading={internalLoading}
                        offScreen={typeaheadOffscreen}
                    />
                )}
            </div>
        );
    } else {
        delete rest.value;
        return (
            <>
                {/* TODO: refactor this, duplicate code */}
                <input
                    ref={(e) => {
                        if (ref) ref.current = e;
                        registered.ref(e);
                    }}
                    name={registered?.name}
                    id={name}
                    className={cleanClasses(['flank-form-control', className])}
                    onChange={(e) => handleOnChange(e, registered?.onChange)}
                    onKeyUp={handleKeyPress}
                    onBlur={(e) => handleOnBlur(e, registered?.onBlur)}
                    {...rest}
                />
                {typeahead && (typeaheadOpen || internalLoading) && (
                    <TypeaheadBox
                        typeaheadOnSelect={typeaheadOnSelect}
                        setTypeaheadOpen={setTypeaheadOpen}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        typeaheadOptions={typeaheadOptions}
                        typeaheadLoading={internalLoading}
                        offScreen={typeaheadOffscreen}
                        setTypeaheadOffscreen={setTypeaheadOffscreen}
                    />
                )}
            </>
        );
    }
});

export default TextInput;

TextInput.propTypes = {
    componentType: PropTypes.string,
    register: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    hideLabel: PropTypes.bool,
    defaultValue: PropTypes.string,
    validate: PropTypes.func,
    typeahead: PropTypes.bool,
    typeaheadOptions: PropTypes.array,
    typeaheadOnChange: PropTypes.func,
    typeaheadOnSelect: PropTypes.func,
    typeaheadLoading: PropTypes.bool,
    typeaheadMinLength: PropTypes.number,
    resetValue: PropTypes.any,
    onBlur: PropTypes.func,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    ref: PropTypes.object,
    className: PropTypes.string,
};

TextInput.defaultProps = {
    componentType: 'TextInput',
};
