import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Form, FormGroup, SelectInput } from '..';
import { IoArrowForwardOutline } from 'react-icons/io5';

export default function CallbackList({ options }) {
    const runCallback = (data) => {
        const option = options.find((option) => option.value === data.callback);
        if (option && option.callback && typeof option.callback === 'function') {
            option.callback();
        }
    };

    return (
        <div className="flank-callback-list">
            <div className="flank-callback-list-header">Actions</div>

            <Form onSubmit={runCallback}>
                <FormGroup>
                    <SelectInput
                        name="callback"
                        defaultValue=""
                        options={options.map((option) => ({
                            value: option.value,
                            label: option.label,
                        }))}
                    />
                </FormGroup>
                <button className="flank-button" type="submit">
                    <IoArrowForwardOutline />
                </button>
            </Form>
        </div>
    );
}

CallbackList.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            callback: PropTypes.func,
        })
    ).isRequired,
};
