import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function Spacer({ header, skinny }) {
    const classes = ['flank-spacer'];
    const wrapperClasses = ['wrapper'];

    if (header) {
        wrapperClasses.push('with-header');
    }
    if (skinny) {
        classes.push('skinny');
    }

    return (
        <div>
            <div className="wrapper">
                <div className="spacer-header">{header}</div>
            </div>
            <hr className={classes.join(' ')} />
        </div>
    );
}

Spacer.propTypes = {
    header: PropTypes.string,
    skinny: PropTypes.bool,
};
