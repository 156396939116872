import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, LoadingModal, Alert, Footer } from '../../components';
import './style.scss';
import { IoCloseOutline } from 'react-icons/io5';
import { useSidebar } from '../../providers/sidebar';
import { useSidebarMobileToggle, useTemplate } from '../../providers/template';

export default function ContentTemplate({
    children,
    full,
    thinner,
    pageHeaderProps,
    sidebar,
    loading,
    notFound,
    ...rest
}) {
    const {
        state: { isOpen: sideBarOpen },
        dispatch,
    } = useSidebar();
    useSidebarMobileToggle(!sidebar);
    const {
        state: { showFooter },
    } = useTemplate();
    const [sideBarClasses, setSideBarClasses] = useState(['flank-page-side-content']);

    const [pageClasses, setPageClasses] = useState(['flank-page', ...(rest.pageClasses || [])]);
    const contentClasses = ['flank-page-content'];
    if (full) {
        contentClasses.push('full');
    }
    if (thinner) {
        contentClasses.push('thinner');
    }
    if (rest['no-pad']) {
        contentClasses.push('no-pad');
    }

    if (sidebar) {
        contentClasses.push('with-sidebar');
    }

    useEffect(() => {
        if (sideBarOpen) {
            setSideBarClasses((s) => [...s, 'open']);
            setPageClasses((s) => [...s, 'sidebar-open']);
        } else {
            setSideBarClasses((s) => s.filter((c) => c !== 'open'));
            setPageClasses((s) => s.filter((c) => c !== 'sidebar-open'));
        }
    }, [sideBarOpen]);

    return (
        <div className={pageClasses.filter(Boolean).join(' ')}>
            <div className="flank-page-main-content">
                <div className="content-wrapper">
                    {loading && <LoadingModal block={true} />}
                    <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
                        {notFound ? (
                            <Alert type="danger" message="Not Found" />
                        ) : (
                            <>
                                {pageHeaderProps && (
                                    <>
                                        <PageHeader {...pageHeaderProps} />
                                    </>
                                )}
                                {children}
                            </>
                        )}
                    </div>
                </div>
                {showFooter && (
                    <div className="footer full-content-width">
                        <Footer />
                    </div>
                )}
            </div>

            {sidebar && (
                <>
                    <div className={sideBarClasses.join(' ')}>
                        <div className="sidebar-actions">
                            <button
                                type="button"
                                onClick={() => dispatch({ type: 'setIsOpen', isOpen: !sideBarOpen })}
                                className="close-side-panel"
                            >
                                <IoCloseOutline />
                            </button>
                        </div>
                        <div className="sidebar-content">{sidebar}</div>
                    </div>
                </>
            )}
        </div>
    );
}

ContentTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    full: PropTypes.bool,
    thinner: PropTypes.bool,
    pageHeaderProps: PropTypes.object,
    sidebar: PropTypes.node,
    loading: PropTypes.bool,
    notFound: PropTypes.bool,
};
