import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './providers/user';
import { SidebarProvider } from './providers/sidebar';
import { TemplateProvider } from './providers/template';
import { ApolloClient, InMemoryCache, ApolloProvider, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';
import { createUploadLink } from 'apollo-upload-client';

const TEST_CACHE = false;

function Base({ children }) {
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
            },
        };
    });

    const uploadLink = createUploadLink({
        uri: process.env.REACT_APP_API_ENDPOINT,
        credentials: 'include',
    });

    const getNewToken = async () => {
        try {
            await axios.post(
                process.env.REACT_APP_API_ENDPOINT,
                {
                    query: `
                    mutation RefreshToken {
                        refreshToken {
                            accessToken
                            refreshToken
                            expires
                        }
                    }
                    `,
                    variables: {},
                },
                {
                    withCredentials: true,
                    credentials: 'include',
                }
            );
        } catch (error) {
            console.error(error.message);
        }
    };

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    case 'UNAUTHENTICATED':
                        if (err.message === 'refresh') {
                            return new Observable((observer) => {
                                getNewToken()
                                    .then(() => {
                                        const oldHeaders = operation.getContext().headers;
                                        operation.setContext({
                                            headers: {
                                                ...oldHeaders,
                                            },
                                        });
                                    })
                                    .then(() => {
                                        const subscriber = {
                                            next: observer.next.bind(observer),
                                            error: observer.error.bind(observer),
                                            complete: observer.complete.bind(observer),
                                        };

                                        // Retry last failed request
                                        forward(operation).subscribe(subscriber);
                                    })
                                    .catch((err) => {
                                        observer.error(err);
                                    });
                            });
                        } else if (err.message === 'Not authenticated') {
                            // logout?
                        }
                        break;
                    default:
                        break; // do nothing
                }
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(errorLink).concat(uploadLink),
        cache: new InMemoryCache({
            dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: process.env.NODE_ENV === 'production' || TEST_CACHE ? 'cache-and-network' : 'no-cache',
            },
            query: {
                fetchPolicy: process.env.NODE_ENV === 'production' || TEST_CACHE ? 'cache-and-network' : 'no-cache',
            },
            mutate: {
                fetchPolicy: 'no-cache',
            },
        },
    });

    return (
        <TemplateProvider>
            <ApolloProvider client={client}>
                <UserProvider>
                    <SidebarProvider>{children}</SidebarProvider>
                </UserProvider>
            </ApolloProvider>
        </TemplateProvider>
    );
}

Base.propTypes = {
    children: PropTypes.node.isRequired,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Base>
        <App />
    </Base>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
