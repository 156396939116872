import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { PageHeader, Button } from '../../components';

export const GuideHeadline = ({ step, title, onClick, children }) => (
    <div className="research-guide-headline">
        <div className="research-header">
            <div className="step-number">
                {step !== 'Step 0' && (
                    <Button
                        className={'step-back button-icon-only'}
                        onClick={onClick}
                        icon={<ReactSVG src="../../../assets/images/icons/page-icons/left-arrow.svg" />}
                        plain={true}
                        skinny={true}
                        text={step}
                    />
                )}
            </div>
            <div className="step-title">
                <PageHeader titleOnly={true} text={title} />
            </div>
        </div>
        <div className="headline-text">{children}</div>
    </div>
);

GuideHeadline.propTypes = {
    step: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};
