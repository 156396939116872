import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { PercentageChange } from '../../../../../components';
import { abbreviateDollars } from '../../../../../utils/numbers';

export default function QuickLook({ quickdata, scale, updatedAt }) {
    const diff = quickdata.currentMarketCap * 1000000 - quickdata.timescaleMarketCap * 1000000;

    const scaleTime = parseInt(scale[0]);
    const scaleUnit = scale[1];
    let scaleUnitText = 'month';
    if (scaleUnit === 'Y') {
        scaleUnitText = 'year';
    }
    if (scaleTime !== 1) {
        scaleUnitText += 's';
    }
    const updateTime = new Date(parseInt(updatedAt));
    const updateTimeFormatted = updateTime.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
    });

    return (
        <div className="company-quick-look">
            <div className="company-quick-look-data">
                <div className="market-cap">${abbreviateDollars(quickdata.currentMarketCap * 1000000 || 0, 2)}</div>
                <PercentageChange border={true} value={quickdata.marketCapChange} />
                <div className={['change', quickdata.marketCapChange < 0 ? 'down' : 'up'].join(' ')}>
                    <div className="change-value">${abbreviateDollars(diff || 0, 2)}</div>
                    <div className="change-time">
                        (over {scaleTime}-{scaleUnitText})
                    </div>
                </div>
            </div>
            <div className="company-quick-look-update">Last updated: {updateTimeFormatted}</div>
        </div>
    );
}

QuickLook.propTypes = {
    quickdata: PropTypes.object.isRequired,
    scale: PropTypes.string.isRequired,
    updatedAt: PropTypes.number.isRequired,
};
