import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTitle } from '../../hooks/use-title';
import { Link, FormGroup, Form, TextInput, Button, BottomWaves, Alert } from '../../components';
import { ReactSVG } from 'react-svg';
import { useMutation } from '@apollo/client';
import { ALPHA_SIGNUP_MUTATION, CONFIRM_ALPHA_SIGNUP_MUTATION } from '../../gql-data';
import { useSearchParams } from 'react-router-dom';

import './style.scss';

export default function AlphaSignupPage() {
    useTitle('Welcome');
    const [searchParams] = useSearchParams();
    const [confirmed, setConfirmed] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [error, setError] = useState(null);

    const [confirmAlphaSignup] = useMutation(CONFIRM_ALPHA_SIGNUP_MUTATION, {
        onCompleted: ({ confirmAlphaUserRegistration: data }) => {
            if (data) {
                setConfirmed(true);
                setError(null);
            }
        },
        onError: (error) => {
            setConfirmed(false);
            setError(error.message);
            console.log(error);
        },
    });

    const [alphaSignup, { loading: signupLoading }] = useMutation(ALPHA_SIGNUP_MUTATION, {
        onCompleted: ({ registerAlphaUser: data }) => {
            if (data) {
                setRegistered(true);
            } else {
                setRegistered(false);
                setError('Something went wrong. Please try again.');
            }
        },
        onError: (error) => {
            setRegistered(false);
            setError(error.message);
        },
    });

    const handleAlphaSignup = (data) => {
        setError(null);
        alphaSignup({
            variables: {
                email: data.emailAddress,
            },
        });
    };

    useEffect(() => {
        const token = searchParams.get('t');
        const queryEmail = searchParams.get('e');
        if (token && queryEmail) {
            confirmAlphaSignup({ variables: { token, email: queryEmail } });
        }
    }, [searchParams, confirmAlphaSignup]);

    return (
        <div className="alpha-wrapper">
            {confirmed && (
                <Alert
                    header="Thank you for your interest!"
                    text="We will be in touch shortly."
                    type="success"
                    data-testid="confirmAlert"
                />
            )}

            <div className="logo-wrapper">
                <Link to="/">
                    <ReactSVG className="logo" src="../../assets/images/logo.svg" />
                </Link>
            </div>
            <div className="content-body">
                <div className="left">
                    <div className="content">
                        <TextBlock
                            mainText="Investing is about to change."
                            subText="...but were not quite ready to show it to you."
                        />
                        <TextBlock mainText="Don't Miss Out." subText="We'll let you know when its ready." />
                        <TextBlock mainText="Join the movement." subText="Apply to be an alpha tester" />
                        <Form onSubmit={handleAlphaSignup} className="alpha-form">
                            {error && <Alert text={error} type="danger" data-testid="registerError" />}
                            {registered && (
                                <Alert
                                    text="Registration successful - please check your email!"
                                    type="success"
                                    data-testid="registerSuccess"
                                />
                            )}
                            <FormGroup>
                                <TextInput
                                    type="email"
                                    name="emailAddress"
                                    placeholder="Your Email"
                                    data-testid="emailAddress"
                                />
                            </FormGroup>
                            <Button
                                type="submit"
                                block={true}
                                action={true}
                                loading={signupLoading ? 'Signing up...' : false}
                                text="Signup!"
                                data-testid="submit"
                            />
                        </Form>
                        <div className="login">
                            Already in the alpha? <Link to="/login">Login here</Link>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <ReactSVG className="rushmore" src="../../assets/images/rushmore-alpha.svg" />
                </div>
            </div>
            <BottomWaves />
        </div>
    );
}

const TextBlock = ({ mainText, subText }) => {
    return (
        <>
            <div className="content">
                <div className="left">
                    <div className="main-text">{mainText}</div>
                    <div className="sub-text">{subText}</div>
                </div>
            </div>
        </>
    );
};

TextBlock.propTypes = {
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
};
