import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Link, Button } from '../../../../components';
import { IoCreateOutline } from 'react-icons/io5';
import SectionHeader from '../../../../components/SectionHeader';
import { useCompany } from '../../../../providers/company';

import './style.scss';
import CompanyPageNavigationButtons from '../page-navigation-button/page-navigation-buttons';

export default function CompanyStrategy({ data }) {
    const { state } = useCompany();
    const [itemsToShow, setItemsToShow] = useState(10);

    data.future.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

    return (
        <>
            <div className="company-strategy-wrapper">
                <div className="company-page-header">
                    <PageHeader text={'Strategy'} titleOnly={true} />{' '}
                </div>
                <div className="press-release-wrapper">
                    {data.future.slice(0, itemsToShow).map((item, index) => (
                        <Link naked={true} to={item.url} key={index} className="press-release-link">
                            <div className="press-release">
                                <div>
                                    <span className="top-line company-name">
                                        ${state.company.displaySymbol.toLowerCase()}
                                    </span>
                                    <span className="top-line press-word"> press release · </span>
                                    <span className="press-release-date">
                                        {new Date(item.datetime).toLocaleDateString()}
                                    </span>
                                    <div className="press-release-title">{item.headline}</div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>{' '}
                {itemsToShow < data.future.length && (
                    <div className="see-more-wrapper">
                        <Button
                            text={'See more'}
                            className="see-more"
                            onClick={() => setItemsToShow(itemsToShow + 10)}
                        />
                    </div>
                )}
            </div>
            <SectionHeader
                text="The Societe"
                Icon={IoCreateOutline}
                iconLink={`/company/${state.company.displaySymbol}/create-post`}
                linkState={{ origin: 'SectionHeader', topLevelResource: 'overview' }}
            />
            <CompanyPageNavigationButtons />
        </>
    );
}

CompanyStrategy.propTypes = {
    data: PropTypes.array,
    financialTimescale: PropTypes.string,
};
