import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useFormContext } from 'react-hook-form';

export default function CheckboxInput({ register, name, label, ...rest }) {
    const { setValue } = useFormContext();

    delete rest.componentType;
    if (!label) {
        console.warn('No label provided for checkbox...');
        return null;
    }

    let registration = !register
        ? {}
        : register(name, {
              setValueAsBoolean: true,
              onChange: (e) => {
                  setValue(name, e.target.checked);
              },
          });

    delete rest.checked;

    return (
        <div className="flank-form-group">
            {label && (
                <label className="flank-form-checkbox-label" htmlFor={name}>
                    <input
                        {...registration}
                        id={name}
                        className="flank-form-control-checkbox"
                        type="checkbox"
                        {...rest}
                    />
                    <span className="label-text">{label}</span>
                </label>
            )}
        </div>
    );
}

CheckboxInput.propTypes = {
    componentType: PropTypes.string,
    register: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

CheckboxInput.defaultProps = {
    componentType: 'CheckboxInput',
};
