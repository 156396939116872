import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCompany } from '../../../providers/company';
import { useSidebar } from '../../../providers/sidebar';
import { loadCompanyQuickData, loadCompanyPriceHistory } from '../services/company-data-svc';
import { Alert, LoadingModal, Chart, TimescaleButtons, ComparisonTable } from '../../../components';
import QuickLook from './overview/quicklook';
import { processPriceHistoryForChart } from './overview/utils';
import CompanyDataTable from './overview/data-table';
import ExternalCompanyLinks from './overview/external-links';
import ContentSection from './content-section/content-section';
import CompanyPageNavigationButtons from './page-navigation-button/page-navigation-buttons';
import SectionHeader from '../../../components/SectionHeader';
import { IoCreateOutline } from 'react-icons/io5';

export default function CompanyOverview({ data }) {
    const { state } = useCompany();
    const { state: sidebarState, dispatch: sidebarDispatch } = useSidebar();
    const [loadQuickData, { loading: loadingQuickData, error: quickDataError }] = loadCompanyQuickData();
    const [loadPriceHistory, { loading: loadingPriceHistory, error: priceHistoryError }] = loadCompanyPriceHistory();
    const [priceChart, setPriceChart] = useState(null);
    const [comparisonData, setComparisonData] = useState([]);
    const [change, setChange] = useState(0);

    const setActiveTimescale = (newTimescale) => {
        sidebarDispatch({ type: 'setTimescale', timescale: newTimescale });
    };

    useEffect(() => {
        if (!state.company) {
            return;
        }
        loadQuickData({
            variables: {
                getCompanyQuickDataId: state.company.id,
                range: sidebarState.timescale,
            },
        });

        loadPriceHistory({
            variables: {
                getPriceHistoryForCompanyId: state.company.id,
                range: sidebarState.timescale,
            },
        });
    }, [state.company, sidebarState.timescale]);

    useEffect(() => {
        if (state.priceHistory.length > 0) {
            const initialMarketCap = state.priceHistory[0].marketCap;
            const finalMarketCap = state.priceHistory[state.priceHistory.length - 1].marketCap;

            let newChange = initialMarketCap !== 0 ? (finalMarketCap - initialMarketCap) / initialMarketCap : 0;

            setChange(newChange);

            setComparisonData([
                {
                    symbol: state.company.displaySymbol,
                    companyName: state.company.name,
                    icon: state.company.logoPath,
                    change: newChange,
                    deleteable: false,
                    color: '#4285F4',
                },
            ]);
        }
    }, [state.priceHistory, sidebarState.timescale, state.company]);

    useEffect(() => {
        if (state.priceHistory.length > 0) {
            setPriceChart(processPriceHistoryForChart(state.priceHistory));
        } else {
            setPriceChart(null);
        }
    }, [state.priceHistory, sidebarState.timescale]);

    if (loadingQuickData) {
        return <LoadingModal block={true} text={'Loading...'} />;
    } else if (quickDataError) {
        return <Alert type="danger" text={quickDataError.message} />;
    } else if (Object.keys(state.quickData).length === 0) {
        return <Alert type="danger" text={'No data found.'} />;
    }

    let priceHistoryChart = null;
    if (loadingPriceHistory) {
        priceHistoryChart = <LoadingModal block={true} text={'Loading...'} />;
    } else if (priceHistoryError) {
        priceHistoryChart = <Alert type="danger" text={priceHistoryError.message} />;
    } else if (!priceChart) {
        priceHistoryChart = <Alert type="danger" text={'No price history data found.'} />;
    } else {
        priceHistoryChart = (
            <div className="full-content-width ">
                <Chart type="line" data={priceChart.data} options={priceChart.options} />
            </div>
        );
    }
    console.log(state.company);
    return (
        <>
            <QuickLook quickdata={state.quickData} scale={sidebarState.timescale} updatedAt={state.company.updatedAt} />
            <TimescaleButtons activeTimescale={sidebarState.timescale} setActiveTimescale={setActiveTimescale} />
            {priceHistoryChart}
            <ComparisonTable type="bubble" change={change} data={comparisonData} />
            <ContentSection title="Who are they?" text={state.company.description.replace(/â€™/g, "'")} />
            <ContentSection title="Nutrition Label">
                <CompanyDataTable
                    symbol={state.symbol}
                    data={state.quickData}
                    timescale={sidebarState.timescale}
                    priceHistory={state.priceHistory}
                />
            </ContentSection>
            {/* todo write migration file to expand description character length */}
            {/* <ComparisonChart
                type="bubble"
                range={sidebarState.timescale}
                unremovableIds={[state.company.id]}
                filterIds={state.filterIds}
                setFilterIds={(ids) => dispatch({ type: 'setFilterIds', ids })}
                companyColors={state.companyColors}
                setCompanyColors={(colors) => dispatch({ type: 'setCompanyColors', colors })}
            /> */}
            {/* todo: add to Industry page */}
            <ContentSection title="External Helpers">
                <ExternalCompanyLinks company={data} />
            </ContentSection>
            <SectionHeader
                text="The Societe"
                Icon={IoCreateOutline}
                iconLink={`/company/${state.company.displaySymbol}/create-post`}
                linkState={{ origin: 'SectionHeader', topLevelResource: 'overview' }}
            />
            <CompanyPageNavigationButtons />
        </>
    );
}

CompanyOverview.propTypes = {
    data: PropTypes.object.isRequired,
};
