import createProvider from '../utils/context';

const Timescale = {
    ONE_MONTH: '1M',
    THREE_MONTH: '3M',
    SIX_MONTH: '6M',
    ONE_YEAR: '1Y',
};

const [useSidebar, SidebarProvider] = createProvider({
    initialState: {
        collections: [],
        cache: {},
        loading: false,
        timescale: Timescale.ONE_MONTH,
        sparkCache: {},
        sparkParents: {},
        openIds: [],
        currentId: null,
        isOpen: false,
    },
    reducer: {
        setCollections: (state, action) => {
            return {
                ...state,
                collections: action.collections,
            };
        },
        setCache: (state, action) => {
            return {
                ...state,
                cache: {
                    ...state.cache,
                    [action.id]: action.data,
                },
            };
        },
        setLoading: (state, action) => {
            return {
                ...state,
                loading: action.loading,
            };
        },
        setTimescale: (state, action) => {
            return {
                ...state,
                timescale: action.timescale,
            };
        },
        addSparkCache: (state, action) => {
            return {
                ...state,
                sparkCache: {
                    [state.timescale]: {
                        ...state.sparkCache[state.timescale],
                        [action.id]: action.data,
                    },
                },
            };
        },
        addSparkParentLoaded: (state, action) => {
            return {
                ...state,
                sparkParents: {
                    [state.timescale]: [...(state.sparkParents?.[state.timescale] || []), action.id],
                },
            };
        },
        setOpenIds: (state, action) => {
            return {
                ...state,
                openIds: action.openIds,
            };
        },
        setCurrentId: (state, action) => {
            return {
                ...state,
                currentId: action.currentId,
            };
        },
        setIsOpen: (state, action) => {
            return {
                ...state,
                isOpen: action.isOpen,
            };
        },
    },
});

export { useSidebar, SidebarProvider };
