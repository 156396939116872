import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, LoadingModal, SearchResultRow, Spacer } from '../../components';
import { SearchResultShape } from './search-types';
import './style.scss';
import { useSidebar } from '../../providers/sidebar';
import { percentChange } from '../../utils/numbers';
import Link from '../../components/Link';

export const SearchResults = ({ searchResults, dirty, loading }) => {
    const { state: sidebarState } = useSidebar();

    if (loading) {
        return <LoadingModal block={true} text={'Searching...'} />;
    }

    if (searchResults.length === 0) {
        if (dirty) {
            return <Alert type="info" text="No results found." />;
        }
    }

    const constructDataPoints = useCallback(
        (company) => {
            const defaultDataPoints = [
                {
                    title: 'Market Cap',
                    value: company.marketCap,
                    modifier: ['dollar', 'multiply', 1000000],
                    inline: false,
                    color: 'green',
                },
                {
                    title: 'P/E Ratio',
                    value: company.peRatio,
                    modifier: ['ratio'],
                    inline: false,
                    color: 'green',
                },
                {
                    title: 'Dividend Yield',
                    value: company.dividendYield,
                    modifier: ['ratio'],
                    inline: false,
                    color: 'green',
                },
            ];

            // For searched terms
            const extraDataPoints = [];

            // selectedFilters.ratiosAndMetrics.forEach((filter) => {
            //     if (['P/E'].includes(filter.label)) {
            //         return;
            //     }

            //     extraDataPoints.push({
            //         title: filter.label,
            //         value: company[filter.field],
            //         modifier: filter.type === 'metric' ? ['dollar', 'multiply', 1000000] : ['ratio'],
            //         inline: false,
            //         color: company[filter.field] > 0 ? 'green' : 'red',
            //     });
            // });

            return [...defaultDataPoints, ...extraDataPoints];
        },
        [searchResults]
    );

    const getCompanyPercentageChange = (company) => {
        if (!company) {
            return;
        }

        const current = company.currentPrice;
        switch (sidebarState.timescale) {
            case '1M':
                return percentChange(company.price1M, current);
            case '3M':
                return percentChange(company.price3M, current);
            case '6M':
                return percentChange(company.price6M, current);
            case '1Y':
                return percentChange(company.price1Y, current);
            case '5Y':
                return percentChange(company.price5Y, current);
        }
    };

    return (
        <div className="search-results-container">
            {searchResults.map((company) => (
                <div className="search-row" key={company.id}>
                    <Link to={`/$${company.displaySymbol}`} plain={true} target="_blank">
                        <div className="search-result-wrapper">
                            <SearchResultRow
                                company={company}
                                previouslyClosed={{
                                    value: company.currentPrice,
                                    change: getCompanyPercentageChange(company),
                                    period: sidebarState.timescale,
                                }}
                                dataPoints={constructDataPoints(company)}
                            />
                        </div>
                    </Link>
                    <Spacer />
                </div>
            ))}
        </div>
    );
};

SearchResults.propTypes = {
    searchResults: PropTypes.arrayOf(SearchResultShape),
    selectedFilters: PropTypes.object,
    dirty: PropTypes.bool,
    loading: PropTypes.bool,
};
