export const processPriceHistoryForChart = (data) => {
    if (!data || data.length === 0) {
        return null;
    }

    const direction = data[data.length - 1].price > data[0].price ? 'up' : 'down';

    const dataset = [];
    const labels = [];

    data.forEach((price) => {
        dataset.push(price.price);
        labels.push(price.date);
    });

    let borderColor = '#4285F4';

    if (direction === 'up') {
        borderColor = '#1F793C';
    }

    if (direction === 'down') {
        borderColor = '#A50F0D';
    }

    return {
        data: {
            labels: labels,
            datasets: [
                {
                    label: 'Price',
                    fill: false,
                    lineTension: 0.1,
                    borderColor,
                    data: dataset,
                },
            ],
        },
        options: {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const date = new Date(context.label).toLocaleString('default', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });
                            return `$${context.formattedValue}<br>${date}`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    display: false,
                },
                y: {
                    display: false,
                },
            },
        },
    };
};
