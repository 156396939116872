import { gql } from '@apollo/client';

export const ME = gql`
    query Me {
        me {
            id
            email
            createdAt
            updatedAt
            group {
                name
                permissions {
                    name
                }
            }
            active
            firstName
            lastName
            username
        }
    }
`;

export const GET_SIDEBAR = gql`
    query GetAllCollections($filters: [WhereClause!], $orderBy: [OrderBy!], $limit: Float) {
        getAllCollections(filters: $filters, orderBy: $orderBy, limit: $limit) {
            rows {
                id
                name
                marketCap
                children {
                    id
                    name
                    marketCap
                    companies {
                        id
                        name
                        displaySymbol
                    }
                }
                companies {
                    id
                    name
                    displaySymbol
                }
            }
        }
    }
`;

export const GET_BASE_SIDEBAR = gql`
    query GetSidebar {
        getSidebar {
            id
            name
            marketCap
            children {
                id
                name
                marketCap
                companies {
                    id
                    name
                    displaySymbol
                    logoPath
                }
            }
            companies {
                id
                name
                displaySymbol
                logoPath
            }
        }
    }
`;

export const GET_SIDEBAR_SPARK_DATA = gql`
    query GetSidebarDataForCollection($timescale: String!, $getSidebarDataForCollectionId: String) {
        getSidebarDataForCollection(timescale: $timescale, id: $getSidebarDataForCollectionId) {
            date
            id
            value
        }
    }
`;

export const GET_SIDEBAR_COMPANY_MARKET_CAPS = gql`
    query GetCurrentMarketCapsForCollection($getCurrentMarketCapsForCollectionId: String!) {
        getCurrentMarketCapsForCollection(id: $getCurrentMarketCapsForCollectionId) {
            id
            marketCap
        }
    }
`;

export const LOGIN = gql`
    mutation Login($password: String!, $email: String!) {
        login(password: $password, email: $email) {
            accessToken
            refreshToken
            expires
        }
    }
`;

export const LOGOUT = gql`
    mutation Logout {
        logout
    }
`;

export const SEARCH = gql`
    query GetAllCompanies(
        $filters: [WhereClause!]
        $orderBy: [OrderBy!]
        $groupBy: [String!]
        $limit: Float
        $page: Float
    ) {
        getAllCompanies(filters: $filters, orderBy: $orderBy, groupBy: $groupBy, limit: $limit, page: $page) {
            rows {
                id
                displaySymbol
                name
                logoPath
                industry
                peRatio
                psRatio
                eps
                currentRatio
                pbRatio
                pcfRatio
                roe
                roa
                beta
                debtToEquity
                marketCap
                operatingMargin
                dividendYield
                bookValue
                revenue
                netIncome
                currentPrice
                price1M
                price3M
                price6M
                price1Y
                price5Y
                collections {
                    name
                    marketCap
                }
            }
            currentPage
            totalPages
        }
    }
`;

export const GET_SYMBOL_TYPEAHEAD = gql`
    query GetAllCompanies($filters: [WhereClause!], $orderBy: [OrderBy!], $groupBy: [String!], $limit: Float) {
        getAllCompanies(filters: $filters, orderBy: $orderBy, groupBy: $groupBy, limit: $limit) {
            rows {
                id
                displaySymbol
                name
                logoPath
            }
        }
    }
`;

export const GET_COMPANY_QUICK_DATA = gql`
    query GetCompanyQuickData($range: String!, $id: String!) {
        getCompanyQuickData(range: $range, id: $id) {
            previousClose
            yearRange
            currentMarketCap
            marketCapChange
            industry
            dividendYield
            dividendYieldIndustry
            dividendYieldSP500Percentage
            pe
            peIndustry
            peSP500Percentage
            company {
                id
                displaySymbol
                name
                logoPath
            }
        }
    }
`;

export const GET_COMPANIES_QUICK_DATA = gql`
    query GetCompaniesQuickData($range: String!, $ids: [String!]!) {
        getCompaniesQuickData(range: $range, ids: $ids) {
            previousClose
            yearRange
            currentMarketCap
            marketCapChange
            industry
            dividendYield
            dividendYieldIndustry
            dividendYieldSP500Percentage
            pe
            peIndustry
            peSP500Percentage
            company {
                id
                displaySymbol
                name
                logoPath
            }
            financials {
                revenue
                date
                grossProfit
                operatingMargin
            }
        }
    }
`;

export const ALPHA_SIGNUP_MUTATION = gql`
    mutation RegisterAlphaUser($email: String!) {
        registerAlphaUser(email: $email)
    }
`;

export const CONFIRM_ALPHA_SIGNUP_MUTATION = gql`
    mutation ConfirmAlphaUserRegistration($email: String!, $token: String!) {
        confirmAlphaUserRegistration(email: $email, token: $token)
    }
`;

export const REGISTRATION_MUTATION = gql`
    mutation Register(
        $lastName: String!
        $firstName: String!
        $password: String!
        $email: String!
        $username: String!
    ) {
        register(lastName: $lastName, firstName: $firstName, password: $password, email: $email, username: $username) {
            id
        }
    }
`;

export const ACTIVATION_MUTATION = gql`
    mutation ActivateUser($email: String!, $token: String!) {
        activateUser(email: $email, token: $token) {
            id
        }
    }
`;

export const FORGOT_PW_MUTATION = gql`
    mutation ResetPassword($email: String!) {
        forgotPassword(email: $email) {
            id
        }
    }
`;

export const RESET_PW_MUTATION = gql`
    mutation ResetPassword($password: String!, $email: String!, $token: String!) {
        resetPassword(password: $password, email: $email, token: $token) {
            id
        }
    }
`;

export const UPDATE_CURRENT_USER_MUTATION = gql`
    mutation UpdateCurrentUser($data: UpdateCurrentUser!) {
        updateCurrentUser(data: $data)
    }
`;

export const CHANGE_PW_MUTATION = gql`
    mutation ChangePassword($confirmNewPassword: String!, $newPassword: String!, $oldPassword: String!) {
        changePassword(confirmNewPassword: $confirmNewPassword, newPassword: $newPassword, oldPassword: $oldPassword)
    }
`;

export const GET_SEARCH_FILTERS = gql`
    query GetSearchFilters {
        getAllSearchFiltersForSelf {
            rows {
                filters
                id
                name
                global
                active
            }
        }
    }
`;

export const SAVE_SEARCH_FILTERS = gql`
    mutation CreateSearchFilterForSelf($data: CreateSearchFilter!) {
        createSearchFilterForSelf(data: $data) {
            id
            name
        }
    }
`;

export const DELETE_SEARCH_FILTERS = gql`
    mutation DeleteSearchFilterForSelf($deleteSearchFilterForSelfId: String!) {
        deleteSearchFilterForSelf(id: $deleteSearchFilterForSelfId)
    }
`;
