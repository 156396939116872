import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentSection from '../../content-section/content-section';
import { PageHeader } from '../../../../../components';
import { processLeadershipData } from './process-leadership-data';
import { Chart } from '../../../../../components';
import { formatNumber } from '../../../../../utils/numbers';
import { ReactSVG } from 'react-svg';
import { Link } from '../../../../../components';
import { useCompany } from '../../../../../providers/company';

import '../style.scss';
import CompanyPageNavigationButtons from '../../page-navigation-button/page-navigation-buttons';

function SubtitleData({ leader }) {
    const compensationNum = parseFloat(leader.compensation);
    const compensationDollar = isNaN(compensationNum)
        ? 'N/A'
        : compensationNum.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
          });
    return (
        <div className="leader-card">
            <div className="leader-name">
                {leader.firstName} {leader.lastName}
            </div>
            <div className="leader-text">{leader.title}</div>
            <div className="leader-text">
                <span className="leader-text">
                    Age {leader.age || 'N/A'} | Since: {leader.since || 'N/A'}
                </span>
                <br />
                <span className="leader-text">Compensation: {compensationDollar}</span>
            </div>
        </div>
    );
}

SubtitleData.propTypes = {
    leader: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        compensation: PropTypes.string,
        title: PropTypes.string,
        since: PropTypes.string,
        age: PropTypes.string,
    }).isRequired,
    company: PropTypes.shape({}).isRequired,
};

export default function GovernanceLeaders({ data, type }) {
    const { state } = useCompany();

    const textConfig = {
        executives: {
            whatIsThis: {
                tldr: {
                    importance: 2,
                    text: 'Ideally, executives understand their business the best. Monitoring their activity can provide valuable insights into the company&#39;s prospects. Remember: insiders can sell for any number of reasons, but they buy for only one reason: confidence in the stock.',
                },
                text: 'Executives are the most senior employees of a company. They are responsible for the day-to-day operations of the business. They are also responsible for setting the company&#39;s strategy and vision.',
            },
            whyIsThisImportant: {
                text: 'If an executive is buying more stock in their company, that means they are using their own money to purchase shares. Our system does not count stock grants/options an insider buy because it is a form of compensation.<br /><br /> Executives using their own money to buy more shares is a positive signal, as it indicates that the executive has confidence in the company&#39;s future and believes that the current stock price is undervalued.',
            },
        },
        directors: {
            whatIsThis: {
                tldr: {
                    importance: 2,
                    text: 'Directors are less involved in the day to day of a company, but still have access to non-public material information about the company.',
                },
                text: 'A company&#39;s directors are individuals elected by the company&#39;s shareholders to oversee the management of the corporation. They form the board of directors, which is responsible for making key decisions that shape the company&#39;s strategic direction, financial health, and overall growth. Directors can include both internal members (like the CEO or other high-ranking executives) and external members, often referred to as independent directors, who have no ties to the company beyond their directorship.',
            },
            whyIsThisImportant: {
                text: `Understanding the backgrounds, ages, tenures, and compensation of directors can provide crucial insights into the functioning, decision-making, and potential future of the company. Here's why they are important:
                    <ul>
                    <li><p><strong>Backgrounds:</strong> A director's professional history can hint at the company's strategic direction. More tech experience? The company might be eyeing tech innovations.</p></li>
                    <li><p><strong>Age:</strong> Directors' age influences their perspective. Older ones bring seasoned wisdom; younger ones bring fresh, possibly disruptive ideas.</p></li>
                    <li><p><strong>Tenure:</strong> Long-serving directors understand the company deeply, but can risk becoming complacent. Fresh faces might shake things up positively.</p></li>
                    <li><p><strong>Compensation:</strong> If directors earn significantly in stocks, they're likely rowing the boat in the same direction as the shareholders.</p></li>
                    <li><p><strong>Diversity:</strong> Different backgrounds breed different ideas. A diverse board can see around corners better, leading to more balanced decisions.</p></li>
                    </ul>`,
            },
        },
    };

    const whatIsThis = textConfig[type].whatIsThis;
    const whyIsThisImportant = textConfig[type].whyIsThisImportant;

    const [governanceChartData, setGovernanceChartData] = useState([]);
    const [executivesData, setExecutivesData] = useState([]);
    const [directorsData, setDirectorsData] = useState([]);

    const processChartData = (data, type) => {
        let executivesData = [];
        let directorsData = [];
        let aggregateChartJsData = {};

        if (type === 'executives') {
            const processedData = processLeadershipData(data, type);
            executivesData = processedData.executivesData;
            aggregateChartJsData = processedData.aggregateChartJsData;
        } else if (type === 'directors') {
            const processedData = processLeadershipData(data, type);
            directorsData = processedData.directorsData;
            aggregateChartJsData = processedData.aggregateChartJsData;
        }

        setGovernanceChartData(aggregateChartJsData);
        setExecutivesData(executivesData);
        setDirectorsData(directorsData);
    };

    useMemo(() => {
        processChartData(data, type);
    }, [data]);

    return (
        <div className="company-governance">
            <div className="company-page-header">
                <PageHeader text={type.toUpperCase()} titleOnly={true} />
            </div>
            <div className="barchart-table-wrapper">
                <Chart
                    type="bar"
                    data={governanceChartData}
                    options={{
                        scales: {
                            x: {
                                ticks: {
                                    display: false,
                                },
                            },
                            y: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    drawBorder: false,
                                },
                            },
                        },
                    }}
                />
                <div className="flank-comparison">
                    <table className="flank-comparison-table bar-table">
                        <thead>
                            <tr className="bar-row">
                                <th className="icon"></th>
                                {governanceChartData.labels &&
                                    governanceChartData.labels.map((label, index) => (
                                        <th key={index}>
                                            <div className="header">{label}</div>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {governanceChartData.datasets &&
                                governanceChartData.datasets.map((dataset, idx) => (
                                    <tr key={idx} className="bar-row">
                                        <td className="icon">
                                            <img src={dataset.iconPath} />
                                        </td>
                                        {dataset.data.map((value, index) => (
                                            <td key={index}>
                                                <div className="cell-value">
                                                    {formatNumber(value, ['dollar', 'multiply', 1])}
                                                </div>

                                                {/* add percent change here */}
                                            </td>
                                        ))}
                                        <td className="remove"></td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {type === 'executives' &&
                executivesData.map((leader, idx) => (
                    <div key={idx} className="leader-row">
                        {' '}
                        <td className="leader-wrapper">
                            <SubtitleData
                                className="leader-data"
                                leader={{
                                    firstName: leader.firstName,
                                    lastName: leader.lastName,
                                    compensation: leader.compensation,
                                    title: leader.title,
                                    since: leader.since,
                                    age: leader.age,
                                }}
                            />
                            <Link
                                className="leader-link"
                                to={`https://www.google.com/search?q=${leader.firstName}+${leader.lastName}+%2B+${state.company.name}`}
                                external
                                icon={<ReactSVG src="../../../assets/images/google.svg" />}
                                target="_blank"
                                rel="noopener noreferrer"
                            />
                        </td>
                    </div>
                ))}
            {type === 'directors' &&
                directorsData.map((leader, idx) => (
                    <div key={idx} className="leader-row">
                        {' '}
                        {/* Replace <tr> with <div> */}
                        <td className="leader-wrapper">
                            <SubtitleData
                                leader={{
                                    firstName: leader.firstName,
                                    lastName: leader.lastName,
                                    compensation: leader.compensation,
                                    title: leader.title,
                                    since: leader.since,
                                    age: leader.age,
                                }}
                            />
                            <Link
                                to={`https://www.google.com/search?q=${leader.firstName}+${leader.lastName}+%2B+${state.company.name}`}
                                external
                                icon={<ReactSVG src="../../../assets/images/google.svg" />}
                                target="_blank"
                                rel="noopener noreferrer"
                            />
                        </td>
                    </div>
                ))}

            <div className="content-section">
                <ContentSection title="What is this?" {...whatIsThis} />
                <ContentSection title="Why is this important?" {...whyIsThisImportant} />
            </div>
            <CompanyPageNavigationButtons />
        </div>
    );
}

GovernanceLeaders.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            transactions: PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string,
                    price: PropTypes.number,
                    change: PropTypes.number,
                    shares: PropTypes.number,
                })
            ),
            title: PropTypes.string,
        })
    ),
    type: PropTypes.string,
};
