import React from 'react';
import PropTypes from 'prop-types';
import { Chart, ComparisonTable } from '../../../components';

export default function ComparisonChart({ type, data, filterIds, setFilterIds }) {
    return (
        <>
            <div className="barchart-table-wrapper">
                <Chart
                    type={type}
                    data={data.chart}
                    options={{
                        animation: {
                            duration: 200,
                            easing: 'easeOutQuart',
                        },
                        scales: {
                            x: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    display: false,
                                },
                            },
                            y: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                            },
                        },
                    }}
                />

                <ComparisonTable
                    type="bar"
                    headers={data.headers}
                    data={data.table}
                    filters={{
                        onAdd: (value) => {
                            setFilterIds([...filterIds, value]);
                            return true;
                        },
                        onChange: () => {
                            return true;
                        },
                        onDelete: (value) => {
                            setFilterIds(filterIds.filter((id) => id !== value));
                            return true;
                        },
                        current: null,
                    }}
                />
            </div>
        </>
    );
}

ComparisonChart.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
        chart: PropTypes.shape({
            labels: PropTypes.arrayOf(PropTypes.string),
            datasets: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    data: PropTypes.arrayOf(PropTypes.number),
                })
            ),
        }),
        table: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                value: PropTypes.number,
            })
        ),
        headers: PropTypes.oneOfType([
            PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string,
                })
            ),
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
    }),
    filterIds: PropTypes.arrayOf(PropTypes.string),
    setFilterIds: PropTypes.func,
};
