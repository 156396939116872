import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Link } from '..';

const Block = ({ block }) => {
    return (
        <Link to={block.to} className="flank-admin-block">
            <div className="flank-admin-block-icon">{block.icon}</div>
            <div className="flank-admin-block-title">{block.title}</div>
        </Link>
    );
};

export default function AdminBlocks({ blocks }) {
    return (
        <div className="flank-admin-blocks">
            {blocks.map((block) => (
                <Block key={block.title} block={block} />
            ))}
        </div>
    );
}

AdminBlocks.propTypes = {
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            icon: PropTypes.element.isRequired,
            title: PropTypes.string.isRequired,
        })
    ).isRequired,
};

Block.propTypes = {
    block: PropTypes.shape({
        to: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
};
