import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { PercentageChange } from '../../../../components';
import { formatNumber } from '../../../../utils/numbers';

function getMetricData(metric, data, latestYear) {
    return data[0][metric]
        .filter((item) => {
            const year = item.period.split(' ')[1];
            return year === latestYear || year === String(parseInt(latestYear, 10) - 1);
        })
        .sort((a, b) => {
            const yearA = a.period.split(' ')[1];
            const yearB = b.period.split(' ')[1];
            return yearA - yearB;
        });
}

function getTableRow(item, metric, metricDisplayName, metricData, index, metricFormatting) {
    let currentYearData = item.value;
    let previousYearData = metricData[metricData.length - 2]?.value || 0;
    let percentageChange = (currentYearData - previousYearData) / previousYearData;

    return (
        <tr key={`${metric}-${index}`}>
            <td className="metrics">{metricDisplayName}</td>
            <td className="table-values">
                <div>{formatNumber(item.value, metricFormatting)}</div>
            </td>
            <td className="percentage-change">
                <PercentageChange value={percentageChange} border={true} />
            </td>
        </tr>
    );
}

export default function CashFlowTable({ data, cashFlowTimescale }) {
    const cashFlowMetrics = [
        { key: 'netIncome', displayName: 'Net Income', formatting: ['dollar', 'multiply', 1000000] },
        {
            key: 'adjustmentToNetCashOperating',
            displayName: 'Operating Activities Adjustment',
            formatting: ['dollar', 'multiply', 1000000],
        },
        {
            key: 'cashFlowFromInvestingActivities',
            displayName: 'Cash Flows From Investing Activities',
            formatting: ['dollar', 'multiply', 1000000],
        },
        {
            key: 'cashFlowFromFinancingActivities',
            displayName: 'Cash Flows from Financing Activities',
            formatting: ['dollar', 'multiply', 1000000],
        },
        { key: 'changeInCash', displayName: 'Change in Cash (Cash Flow)', formatting: ['dollar', 'multiply', 1000000] },
    ];

    let latestYear = data[0].netIncome[data[0].netIncome.length - 1].period.split(' ')[1];

    return (
        <div className="financials-container full-content-width">
            <table className="financials">
                <thead>
                    <tr>
                        <th className="metrics"></th>
                        <th className="table-values time">
                            <div>{latestYear}</div>
                        </th>
                        <th className="percentage-change"></th>
                    </tr>
                </thead>
                <tbody>
                    {cashFlowMetrics.map((metricObject) => {
                        const metric = metricObject.key;
                        const metricDisplayName = metricObject.displayName;
                        const metricFormatting = metricObject.formatting;

                        const metricData = getMetricData(metric, data, latestYear);

                        if (cashFlowTimescale === 'Y') {
                            const item = metricData[metricData.length - 1];
                            return getTableRow(item, metric, metricDisplayName, metricData, 0, metricFormatting);
                        } else if (cashFlowTimescale === 'Q') {
                            return data.map((quarterData, index) => {
                                const item = quarterData[metric][0];
                                return getTableRow(
                                    item,
                                    metric,
                                    metricDisplayName,
                                    metricData,
                                    index,
                                    metricFormatting
                                );
                            });
                        }
                    })}
                </tbody>
            </table>
        </div>
    );
}

CashFlowTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            netIncome: PropTypes.array.isRequired,
            adjustmentToNetCashOperating: PropTypes.array.isRequired,
            cashFlowFromInvestingActivities: PropTypes.array.isRequired,
            cashFlowFromFinancingActivities: PropTypes.array.isRequired,
            changeInCash: PropTypes.array.isRequired,
        })
    ).isRequired,
    cashFlowTimescale: PropTypes.string.isRequired,
};
