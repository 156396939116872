import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function ResearchButton({ showResearchButton, onClick, progress = 0, onCompleted, ...rest }) {
    return (
        <div className="btn-container">
            <button
                className={`research-btn ${!showResearchButton ? 'research-status' : ''}`}
                onClick={onClick}
                {...rest}
            >
                {onCompleted ? (
                    <img src="../../../assets/images/research/research-complete.svg" alt="Research Completed" />
                ) : (
                    <span
                        className={`progress-bar ${!showResearchButton ? 'active' : ''}`}
                        style={{ '--progress-value': Math.floor(progress * 100) }}
                    ></span>
                )}
            </button>
        </div>
    );
}

ResearchButton.propTypes = {
    showResearchButton: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    progress: PropTypes.number,
    onCompleted: PropTypes.bool,
};
