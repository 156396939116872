import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import './style.scss';

const registerTypes = [
    'TextInput',
    'CheckboxInput',
    'SelectInput',
    'ComboBox',
    'input',
    'select',
    'CurrencyInput',
    'DateInput',
    'ThesisBullet',
    'FileInput',
];

const recursiveMap = (children, methods, values) => {
    return React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                ...child.props,
                ref: child.ref,
                children: recursiveMap(child.props.children, methods, values),
            });
        }

        if (child.props.name) {
            if (child.props.componentType && registerTypes.indexOf(child.props.componentType) > -1) {
                child = React.createElement(child.type, {
                    ...{
                        ref: child.ref,
                        ...child.props,
                        register: methods.register,
                        defaultValue: values[child.props.name],
                        key: child.props.name,
                    },
                });
            } else {
                child = React.cloneElement(child, {
                    ref: child.ref,
                    ...child.props,
                    ...methods.register(child.props.name, child.props.validation || {}),
                    defaultValue: values[child.props.name],
                    key: child.props.name,
                });
            }
        }

        return child;
    });
};

const Form = forwardRef(function Form({ defaultValues, children, onSubmit, onError, className, ...rest }, ref) {
    const methods = useForm({ defaultValues });
    const { handleSubmit } = methods;

    if (ref) ref.current = methods;

    return (
        <FormProvider {...methods}>
            <form className={`flank-form ${className}`} {...rest} onSubmit={handleSubmit(onSubmit, onError)}>
                {recursiveMap(children, methods, defaultValues || {})}
            </form>
        </FormProvider>
    );
});

export default Form;

const CustomForm = forwardRef(function CustomForm({ children, onSubmit, onError, methods, ...rest }, ref) {
    if (!methods) {
        return null;
    }

    const { handleSubmit } = methods;
    if (ref) ref.current = methods;

    return (
        <FormProvider {...methods}>
            <form className="flank-form" {...rest} onSubmit={handleSubmit(onSubmit, onError)}>
                {recursiveMap(children, methods, methods.getValues())}
            </form>
        </FormProvider>
    );
});

export { CustomForm };

Form.propTypes = {
    defaultValues: PropTypes.object,
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func,
    className: PropTypes.string,
};

CustomForm.propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func,
    methods: PropTypes.object.isRequired,
};
