import { useEffect } from 'react';
import createProvider from '../utils/context';

const [useTemplate, TemplateProvider] = createProvider({
    initialState: {
        showFooter: true,
        sidebarToggleDisabled: false,
    },
    reducer: {
        setShowFooter: (state, action) => {
            return {
                ...state,
                showFooter: action.showFooter,
            };
        },
        setSidebarToggleDisabled: (state, action) => {
            return {
                ...state,
                sidebarToggleDisabled: action.sidebarToggleDisabled,
            };
        },
    },
});

const useFooter = (showFooter) => {
    const { dispatch: templateDispatch } = useTemplate();
    useEffect(() => {
        templateDispatch({
            type: 'setShowFooter',
            showFooter,
        });
    }, []);
};

const useSidebarMobileToggle = (sidebarToggleDisabled) => {
    const { dispatch: templateDispatch } = useTemplate();
    useEffect(() => {
        templateDispatch({
            type: 'setSidebarToggleDisabled',
            sidebarToggleDisabled,
        });
    }, []);
};

export { useTemplate, TemplateProvider, useFooter, useSidebarMobileToggle };
