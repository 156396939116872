import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { merge } from 'lodash';

function getColorByBgColor(bgColor) {
    if (!bgColor) {
        return '';
    }

    return parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff';
}

export default function BubbleChart({ data, options, defaultOptions }) {
    ChartJS.register({
        id: 'permanentLabel',
        afterDatasetsDraw: function (chart) {
            var ctx = chart.ctx;

            chart.data.datasets.forEach(function (dataset, i) {
                var meta = chart.getDatasetMeta(i);
                if (meta.type === 'bubble') {
                    meta.data.forEach(function (element, index) {
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        var position = element.tooltipPosition();
                        let colorType = getColorByBgColor(dataset.backgroundColor);

                        let fontSize = window.getComputedStyle(document.body, null).getPropertyValue('font-size');
                        if (fontSize.endsWith('pt')) {
                            fontSize = Math.round(parseInt(fontSize) * 1.3);
                        } else {
                            fontSize = parseInt(fontSize);
                        }

                        ctx.font =
                            fontSize +
                            'px ' +
                            window.getComputedStyle(document.body, null).getPropertyValue('font-family');

                        // if the font can fit in the bubble, draw it
                        if (ctx.measureText(dataset.data[index].label).width + 20 < dataset.data[index].r * 2) {
                            const shade = colorType === '#fff' ? 200 : -200;
                            const labelColor = shade > 0 ? '#fff' : '#000';

                            if (dataset.data[index].labelSubtext) {
                                ctx.fillStyle = labelColor;
                                ctx.fillText(dataset.data[index].label, position.x, position.y - fontSize * 0.5);
                                ctx.font =
                                    fontSize +
                                    'px ' +
                                    window.getComputedStyle(document.body, null).getPropertyValue('font-family');
                                ctx.fillStyle = labelColor;
                                ctx.fillText(dataset.data[index].labelSubtext, position.x, position.y + fontSize * 1);
                            } else {
                                ctx.fillStyle = labelColor;
                                ctx.fillText(dataset.data[index].label, position.x, position.y);
                            }
                        } else {
                            ctx.fillStyle = '#000';
                            ctx.fillText(
                                dataset.data[index].label.toString(),
                                position.x,
                                position.y + dataset.data[index].r + fontSize
                            );

                            if (dataset.data[index].labelSubtext) {
                                ctx.fillStyle = '#000';
                                ctx.fillText(
                                    dataset.data[index].labelSubtext,
                                    position.x,
                                    position.y + dataset.data[index].r + fontSize * 2
                                );
                            }
                        }
                    });
                }
            });
        },
    });

    return (
        <Bubble
            className="flank-chart"
            options={merge(
                defaultOptions,
                {
                    layout: {
                        autoPadding: true,
                    },
                },
                options || {}
            )}
            data={data}
        />
    );
}

BubbleChart.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object,
    defaultOptions: PropTypes.object.isRequired,
};
