import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Button from '../Button';
import { TextInput, Form } from '../../components';

export default function SaveFilters({ show, onSave, onCancel }) {
    if (!show) {
        return;
    }
    function onSubmit(data) {
        onSave(data.name);
    }
    function handleCancel() {
        onCancel();
    }
    return (
        <div className="save-filters-container">
            <Form onSubmit={onSubmit} className="input-row">
                <TextInput name="name" placeholder="New Filter Name" className="text-input" autoFocus />
                <Button text="Save" gray type="submit" />
                <Button text="Cancel" gray type="reset" onClick={handleCancel} />
            </Form>
        </div>
    );
}

SaveFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
