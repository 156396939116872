import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, CategoryScale, LinearScale, BarController, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

export default function BarChart({ data, options, defaultOptions }) {
    ChartJS.register(CategoryScale, LinearScale, BarController, BarElement);

    const mergedOptions = {
        ...defaultOptions,
        layout: {
            autoPadding: false,
            padding: {
                bottom: -8,
                left: -8,
            },
        },
        ...options,
    };

    return (
        <div className="barchart-container">
            <Bar className="flank-chart" options={mergedOptions} data={data} />
        </div>
    );
}

BarChart.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object,
    defaultOptions: PropTypes.object,
};
