export const SectorMap = {
    Technology: ['Information Technology', 'Communications', 'Technology', 'Semiconductors'],
    Healthcare: ['Pharmaceuticals', 'Life Sciences Tools & Services', 'Biotechnology'],
    Financials: ['Insurance', 'Financial Services', 'Banking'],
    'Real Estate': ['All'],
    Energy: ['All'],
    Materials: ['Chemicals', 'Construction', 'Metals & Mining', 'Packaging'],
    'Consumer Discretionary': [
        'Hotels, Restaurants & Leisure',
        'Distributors',
        'Leisure Products',
        'Automobiles',
        'Auto Components',
        'Textiles, Apparel & Luxury Goods',
        'Retail',
    ],
    'Consumer Staples': ['Tobacco', 'Retail', 'Food Products', 'Beverages'],
    Industrials: [
        'Industrial Conglomerates',
        'Aerospace & Defense',
        'Airlines',
        'Building',
        'Logistics & Transportation',
        'Trading Companies & Distributors',
        'Machinery',
        'Electrical Equipment',
        'Commercial Services & Supplies',
        'Professional Services',
        'Road & Rail',
    ],
    Communications: ['Media', 'Telecommunication'],
    Utilities: ['All'],
};
// todo: dave check for accuracy in mkt cap
// todo: write explnations

export const companySizesMarketCap = [
    {
        label: 'All',
        value: undefined,
        operator: undefined,
    },
    { label: '>$100B', value: 100000, previous: 'All', next: '$50M-$100M', operator: 'gt' },
    { label: '$50B-$100B', value: [50001, 100000], previous: '$10B-$50B', next: '<$50M', operator: 'between' },
    { label: '$10B-$50B', value: [10001, 50000], previous: '$1B-$10B', next: '$50B-$100B', operator: 'between' },
    { label: '$1B-$10B', value: [1001, 10000], previous: '$500M-$1B', next: '$10B-$50B', operator: 'between' },
    { label: '$500M-$1B', value: [501, 1000], previous: '$100M-$500M', next: '$1B-$10B', operator: 'between' },
    { label: '$100M-$500M', value: [101, 500], previous: '$50M-$100M', next: '$500M-$1B', operator: 'between' },
    { label: '$50M-$100M', value: [51, 100], previous: '>$100B', next: '$100M-$500M', operator: 'between' },
    { label: '<$50M', value: 50, previous: '$50B-$100B', operator: 'lt' },
];

export const companySizesBookValue = [
    {
        label: 'All',
        value: undefined,
        operator: undefined,
    },
    {
        label: '>$50B',
        value: 50000000,
        operator: 'gt',
    },
    {
        label: '$10B-$50B',
        value: [10000000, 50000000],
        operator: 'between',
    },
    {
        label: '$1B-$10B',
        value: [1000000, 10000000],
        operator: 'between',
    },
    {
        label: '$500M-$1B',
        value: [500000, 1000000],
        operator: 'between',
    },
    {
        label: '$100M-$500M',
        value: [100000, 500000],
        operator: 'between',
    },
    {
        label: '$50M-$100M',
        value: [50000, 100000],
        operator: 'between',
    },
    {
        label: '<$50M',
        value: 50000,
        operator: 'lt',
    },
];

export const ratios = [
    {
        label: 'P/E',
        explanation: `<strong>P/E</strong>, or price-to-earnings, ratio is a price tag for a company's earnings. A high P/E means the market expects strong growth; a low P/E might mean the stock is a bargain or growth is slow.`,
        field: 'peRatio',
        defaultSort: 'ASC',
    },
    {
        label: 'P/S',
        explanation: `<strong>P/S ratio</strong>, or Price-to-Sales, is your quick snapshot of a company's valuation. Think of it as the price tag investors put on every dollar of a firm's revenues. High P/S means other people are betting big on growth; low P/S suggests caution or a potential bargain. This can be useful analyzing growth companies that don't have earnings.`,
        field: 'psRatio',
        defaultSort: 'ASC',
    },
    {
        label: 'EPS',
        explanation: `<strong>EPS</strong>? That's Earnings Per Share. Imagine a company's profit pie. EPS tells you how much of that tasty profit pie you'd get for each share you own. More EPS, chunkier the slice; less EPS, you're looking at crumbs. It's not great at comparative analysis since the number of shares outstanding is arbitrary.`,
        field: 'eps',
        defaultSort: 'DESC',
    },
    {
        label: 'Current',
        explanation: `<strong>Current ratio</strong> - is like checking if a company can pay its rent next month. You divide a firm's current (short-term) assets by its current (short-term) liabilities. Over 1? They've got more assets than bills. Under 1? Might be time to tighten the belt.`,
        field: 'currentRatio',
        defaultSort: 'DESC',
    },
    {
        label: 'P/B',
        explanation: `<strong>P/B Ratio</strong>, or Price-to-Book, is how much investors are willing to pay for a company's net assets, page by financial page. If P/B is above 1, you're paying more than the book's 'cover price'. Below 1? You might just be grabbing a financial bestseller on discount. Dive in, but read carefully - there's normally a reason why it's on sale.`,
        field: 'pbRatio',
        defaultSort: 'DESC',
    },
    {
        label: 'P/CF',
        explanation: `<strong>P/CF</strong>, that's Price-to-Cash Flow. Imagine gauging how much cash a company's churning, then comparing it to its stock price. A high P/CF? Investors think it's a cash cow. Low? They're suspecting a cash drought. It's like peeking at a company's cash register to measure its financial swagger.`,
        field: 'pcfRatio',
        defaultSort: 'DESC',
    },
    {
        label: 'ROE',
        explanation: `<strong>ROE</strong> - think of it as the profit report card for your equity in a company. It's the company's bottom line divided by what shareholders have put in (net income divided by equity capital provided by shareholders). High ROE? The money investors put in is like rocket fuel for returns. Low? Might be time to recheck the playbook.`,
        field: 'roe',
        defaultSort: 'DESC',
    },
    {
        label: 'ROA',
        explanation: `<strong>ROA</strong>, standing for Return on Assets. Consider it the company's efficiency score when using its assets. It’s like checking how many dollars they squeeze out of every widget, building, or patent they own. High ROA? Their assets aren’t just sitting pretty; they're sweating and earning. Low ROA? Those assets might be lounging more than hustling. It's the magnifying glass on asset hustle.`,
        field: 'roa',
        defaultSort: 'DESC',
    },
    {
        label: 'Beta',
        explanation: `<strong>Beta</strong>? It's Wall Street's own roller coaster measure. Beta tells you how a stock moves compared to the broader market. If Beta is 1, the stock's riding the market's waves, move for move. Above 1? Strap in; it's more volatile than the market. Below 1? It's like the teacup ride—smoother and less dramatic. Want to gauge a stock's thrill factor? Check out its Beta.`,
        field: 'beta',
        defaultSort: 'DESC',
    },
    {
        label: 'Debt-to-Equity',
        explanation: `<strong>Debt to Equity ratio</strong>? It's like peering into a company's financial diet. Are they binging on debt or funding with shareholders' equity? A high ratio means they're leaning on borrowed money, which can be spicy, but risky. Low ratio? They're playing it cool with more equity. It's a metric to weigh a company's financial fitness and appetite for leverage`,
        field: 'debtToEquity',
        defaultSort: 'DESC',
    },
];

export const metrics = [
    {
        label: 'Revenue',
        explanation: `<strong>Change in Revenue</strong> -  is like a company's yearbook photo. It shows if they've grown, stayed the same, or, yikes, maybe even shrunk since last year. Rising revenue? They're making moves and taking names. Declining? Maybe they missed a beat or two. More revenue is rarely a bad thing so long as expenses or debt skyrocketed to get the increased revenue`,
        field: 'revenue',
        defaultSort: 'DESC',
    },
    {
        label: 'Earnings',
        explanation: `<strong>Change in Earnings</strong> represents the variation in a company's net income over a specific period. A positive change indicates enhanced profitability, often stemming from increased operational efficiency, sales growth, or cost controls. Conversely, a negative change might highlight challenges in operations, increased expenses, or external factors impacting profitability`,
        field: 'netIncome',
        defaultSort: 'DESC',
    },
    {
        label: 'Operating Margin',
        explanation: `<strong>Operating Margin</strong>? Think of it as the efficiency score after the company throws its main party but before it settles all its tabs. It's the profit they made from their core business operations, as a percentage of sales. Bigger margin? They're killing it with their main hustle. Slimmer? Costs might be biting or sales slacking. It's the magnifying glass on the core business game`,
        field: 'operatingMargin',
        defaultSort: 'DESC',
    },
    {
        label: 'Dividend Yield',
        explanation: `<strong>Dividend Yield</strong>? That's like the company slipping you a bit of their profits just for holding onto their stock. Higher yield? They're being more generous with their profit-sharing. Lower? They might be reinvesting that cash or keeping it close to the chest. It's the instant gratitude metric for shareholders`,
        field: 'dividendYield',
        defaultSort: 'DESC',
    },
];
