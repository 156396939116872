import createProvider from '../utils/context';

const [useUser, UserProvider] = createProvider({
    initialState: {
        id: null,
    },
    reducer: {
        setUser: (state, action) => {
            return {
                ...state,
                ...action.user,
            };
        },
        userLoaded: (state, action) => {
            return {
                ...state,
                loaded: action.loaded,
            };
        },
    },
});

const hasPermission = (user, permission) => {
    return user.group?.permissions?.some((p) => p.name === permission) || false;
};

/**
 * basic user for storybook to mock user context
 *
 * @type {object}
 * @property {number} id
 * @property {string} email
 * @property {Date} createdAt
 * @property {Date} updatedAt
 * @property {object} group
 * @property {string} group.name
 * @property {object[]} group.permissions
 * @property {string} group.permissions.name
 * @property {boolean} active
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} username
 */
const storybookAdminUser = {
    id: 1,
    email: 'bandit.heeler@example.com',
    createdAt: new Date(),
    updatedAt: new Date(),
    group: {
        name: 'admin',
        permissions: [
            {
                name: 'admin-rights',
            },
        ],
    },
    active: true,
    firstName: 'Bandit',
    lastName: 'Heeler',
    username: 'bandit.heeler',
};

const storybookUser = (...override) => ({
    id: 1,
    email: 'chilli.heeler@example.com',
    createdAt: new Date(),
    updatedAt: new Date(),
    group: {
        name: 'basic-user',
        permissions: [
            {
                name: 'basic-rights',
            },
        ],
    },
    active: true,
    firstName: 'Chilli',
    lastName: 'Heeler',
    username: 'chilli.heeler',
    ...(override || {}),
});

export { useUser, UserProvider, hasPermission, storybookAdminUser, storybookUser };
