import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { Button } from '../../components';

export const ForwardButton = ({ text, onClick }) => (
    <div className="forward-btn">
        <Button
            text={text}
            action={true}
            fwdButton={true}
            iconAfter={<ReactSVG src="../../../assets/images/icons/page-icons/right-arrow.svg" />}
            onClick={onClick}
        />
    </div>
);

ForwardButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
