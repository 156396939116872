// StepOne.js
import React from 'react';
import PropTypes from 'prop-types';
import { GuideHeadline } from '../../../components/Research/guide-headline';
import { ResearchBody } from '../../../components/Research/research-body';
import { ForwardButton } from '../../../components/Research/forward-button';

export function StepOne({ setCurrentPage, currentPage }) {
    return (
        <div className="guided-research">
            <GuideHeadline
                step="Step 1"
                title="Reminder: Slow Down"
                onClick={() => setCurrentPage(currentPage > 0 ? currentPage - 1 : 0)}
            >
                <p>
                    <strong>Investing</strong> requires higher level thinking. It&apos;s not easy like gambling.
                </p>
                <p>
                    Unlike other mainstream apps that want your attention to flood you with ads and sell you risky
                    trading strategies, we strive to help you become an intelligent investor.
                </p>
                <p>
                    Intelligent investing is a <strong>skill</strong> that requires patience, active thought, and a
                    long-term mindset.
                </p>
                <p>Take a deep breath, you got this. </p>
            </GuideHeadline>
            <ResearchBody>
                <p>
                    We are unapologetically Buffett-style long term investors. It&apos;s the only proven investing style
                    that actually makes individual investors money. This is because a{' '}
                    <strong>long-term focus is our only edge over the pros on Wall Street</strong>
                </p>
                <p>
                    Why? The pros need to report their performance quarter-by-quarter, or else they&#39;ll lose the
                    people who are giving them money. <strong>They have a short-term focus.</strong>
                </p>
                <p>
                    The pros need worry about quarterly earnings for their customers. <strong> You do not.</strong>
                </p>
                <p>
                    Investing, by its very nature, involves risk. Never invest more than you are able or willing to
                    lose. If you&apos;re ever in doubt: hire a fiduciary.
                </p>
            </ResearchBody>
            {/* TODO: Camel case for Button text*/}
            <ForwardButton text={'Circle of Competence'} onClick={() => setCurrentPage(currentPage + 1)} />
        </div>
    );
}

StepOne.propTypes = {
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
};
