import React from 'react';
import DialogHeader from './DialogHeader';
import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import './AlertDialog.scss';
import PropTypes from 'prop-types';

const AlertDialog = ({ isOpen, onClose, className = '', children }) => {
    if (!isOpen) return null;

    return (
        <>
            <div className="alert-dialog-overlay" onClick={onClose}></div>
            <div className={`alert-dialog ${className}`}>{children}</div>
        </>
    );
};

AlertDialog.Header = DialogHeader;
AlertDialog.Body = DialogBody;
AlertDialog.Footer = DialogFooter;

export default AlertDialog;

AlertDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
