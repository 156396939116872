export function processLeadershipData(leadership, type) {
    const transactionsPerYear = {};
    const aggregateTransactions = {};

    const filteredLeadership = leadership.filter((leader) => {
        if (type === 'all') {
            return true;
        }
        if (type === 'executives') {
            return (
                leader.title.toLowerCase().indexOf('director') === -1 ||
                leader.title.toLowerCase().indexOf('chief') !== -1
            );
        } else if (type === 'directors') {
            return (
                leader.title.toLowerCase().indexOf('director') !== -1 &&
                leader.title.toLowerCase().indexOf('chief') === -1
            );
        }
    });
    // if executives or directors, use filteredLeadership otherwise use leadership
    const processedLeadership = filteredLeadership.map((leader) => {
        const newLeader = { ...leader };
        newLeader.transactions = leader.transactions.map((transaction) => {
            const year = transaction.date.split('-')[0];

            if (!transactionsPerYear[year]) {
                transactionsPerYear[year] = { min: 0, max: 0 };
            }
            if (!aggregateTransactions[year]) {
                aggregateTransactions[year] = { bought: 0, sold: 0 };
            }
            if (transaction.change > 0) {
                transactionsPerYear[year].max += transaction.change * transaction.price;
                aggregateTransactions[year].bought += transaction.change * transaction.price;
            } else {
                transactionsPerYear[year].min += transaction.change * transaction.price;
                aggregateTransactions[year].sold -= transaction.change * transaction.price;
            }

            const newTransaction = { ...transaction };

            newTransaction.date = new Date(transaction.date).toLocaleDateString();
            newTransaction.price = '$' + transaction.price.toFixed(2);

            if (transaction.change > 0) {
                newTransaction.change = '+' + transaction.change;
            }

            newTransaction.change = newTransaction.change.toLocaleString();
            newTransaction.shares = transaction.shares.toLocaleString();

            newTransaction.percentChange =
                transaction.change === transaction.shares
                    ? '0.00%'
                    : (transaction.change / (transaction.shares - transaction.change)).toFixed(2) + '%';

            return newTransaction;
        });

        let years = Object.keys(transactionsPerYear)
            .sort((a, b) => a - b)
            .slice(-5);

        if (years.length < 5) {
            const pad = 5 - years.length;
            for (let i = 0; i < pad; i++) {
                years.unshift('');
            }
        }

        let baseColors = { green: '#95E4AA', red: '#E37D80' };

        const maxValues = years.map((year) => transactionsPerYear[year]?.max || 0);
        const minValues = years.map((year) => transactionsPerYear[year]?.min || 0);

        const datasets = [
            {
                label: 'Max',
                data: maxValues,
                backgroundColor: baseColors.green,
            },
            {
                label: 'Min',
                data: minValues,
                backgroundColor: baseColors.red,
            },
        ];

        newLeader.chart = {
            labels: years,
            datasets,
        };

        newLeader.transactionLength = 5;

        return newLeader;
    });
    const aggregateYears = Object.keys(aggregateTransactions).sort().slice(-5);
    const aggregateChartJsData = {
        labels: aggregateYears,
        datasets: [
            {
                label: 'Bought',
                data: aggregateYears.map((year) => aggregateTransactions[year].bought),
                backgroundColor: '#95E4AA',
                iconPath: '/assets/images/icons/chart-icons/buy.svg',
            },

            {
                label: 'Sold',
                data: aggregateYears.map((year) => aggregateTransactions[year].sold),
                backgroundColor: '#E37D80',
                iconPath: '/assets/images/icons/chart-icons/sell.svg',
            },
        ],
    };

    const executivesData = processedLeadership
        .filter(
            ({ title }) => title.toLowerCase().indexOf('director') === -1 || title.toLowerCase().indexOf('chief') !== -1
        )
        .sort((a, b) => {
            if (a.compensation > b.compensation) {
                return -1;
            } else if (a.compensation < b.compensation) {
                return 1;
            } else {
                return 0;
            }
        });

    const directorsData = processedLeadership
        .filter(
            ({ title }) => title.toLowerCase().indexOf('director') !== -1 && title.toLowerCase().indexOf('chief') === -1
        )
        .sort((a, b) => {
            if (a.compensation > b.compensation) {
                return -1;
            } else if (a.compensation < b.compensation) {
                return 1;
            } else {
                return 0;
            }
        });

    return { executivesData, directorsData, aggregateChartJsData };
}
