import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DateInput = ({ name, label, register, validate, ...rest }) => {
    delete rest.componentType;

    const today = new Date().toISOString().substring(0, 10);
    const [selectedDate, setSelectedDate] = useState(today);
    const [error, setError] = useState(false);

    const handleOnChange = (event) => {
        setSelectedDate(event.target.value);
    };
    const defaultValidation = (value) => {
        const inputDate = new Date(value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        if (value === '') {
            return 'Please enter a date.';
        } else if (inputDate > currentDate) {
            return 'You cannot select a future date.';
        }
        return null;
    };

    let registered = null;

    if (register) {
        registered = register(name, {
            onChange: handleOnChange,
            validate: (value) => {
                const defaultValid = defaultValidation(value);

                if (defaultValid !== null) {
                    setError(defaultValid);
                    return false;
                }

                if (!validate) {
                    return true;
                }

                const validation = validate(value);

                if (validation === null) {
                    setError(false);
                    return true;
                } else {
                    setError(validation);
                    return false;
                }
            },
        });
    }

    return (
        <div className={`flank-form-group ${error ? 'shake' : ''}`}>
            {label && <label className="flank-form-label">{label}</label>}
            <input
                id={name}
                type="date"
                ref={registered.ref}
                name={registered.name}
                onChange={registered.onChange}
                value={selectedDate}
                className="flank-form-control"
                {...rest}
            />
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default DateInput;

DateInput.propTypes = {
    componentType: PropTypes.string,
    register: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    validate: PropTypes.func,
    onBlur: PropTypes.func,
};

DateInput.defaultProps = {
    componentType: 'DateInput',
};
