import React, { useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import EditorJS from '@editorjs/editorjs';
import PropTypes from 'prop-types';
import './style.scss';

// importing the different tools for the editor
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import Underline from '@editorjs/underline';

const RichTextEditor = forwardRef((props, ref) => {
    const { data, placeholderText, readOnly } = props;
    const editorConfig = {
        holder: 'editorjs',
        data: data,
        placeholder: placeholderText || 'Start typing here...',
        readOnly: readOnly,
        tools: {
            underline: Underline,
            header: {
                class: Header,
                shortcut: 'CMD+SHIFT+H',
                config: {
                    placeholder: 'Header',
                },
            },
            quote: {
                class: Quote,
                inlineToolbar: true,
                shortcut: 'CMD+SHIFT+O',
                config: {
                    quotePlaceholder: 'Enter a quote',
                    captionPlaceholder: "Quote's author",
                },
            },
            list: {
                class: List,
                inlineToolbar: true,
                config: {
                    defaultStyle: 'unordered',
                },
            },
            table: {
                class: Table,
                inlineToolbar: true,
                config: {
                    rows: 2,
                    cols: 3,
                },
            },
        },
    };
    const editorRef = useRef(null);

    useEffect(() => {
        editorRef.current = new EditorJS(editorConfig);
    }, []);

    useImperativeHandle(ref, () => ({
        save: async () => {
            try {
                const outputData = await editorRef.current.save();
                return outputData;
            } catch (error) {
                console.log('Saving failed: ', error);
            }
        },
    }));

    return <div className="editorjs" id={editorConfig.holder}></div>;
});
RichTextEditor.displayName = 'RichTextEditor';
export default RichTextEditor;

RichTextEditor.propTypes = {
    data: PropTypes.object,
    placeholderText: PropTypes.string,
    readOnly: PropTypes.bool,
};
