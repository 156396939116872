import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactSVG } from 'react-svg';

const Button = forwardRef(function Button(
    {
        text,
        onClick,
        block,
        action,
        gray,
        loading,
        icon,
        iconAfter,
        status,
        active,
        greenOutline,
        danger,
        plain,
        xButton,
        back,
        skinny,
        breadCrumb,
        research,
        researchClicked,
        fwdButton,
        className,
        ...rest
    },
    ref
) {
    const classes = ['flank-button', className || ''];
    if (block) {
        classes.push('block');
    }
    if (action) {
        classes.push('action');
    }
    if (gray) {
        classes.push('gray-btn');
    }
    if (loading) {
        classes.push('loading');
    }
    if (active) {
        classes.push('active');
    }
    if (greenOutline) {
        classes.push('greenOutline');
    }
    if (danger) {
        classes.push('danger');
    }
    if (plain) {
        classes.push('plain');
    }
    if (xButton) {
        classes.push('xButton');
    }
    if (back) {
        classes.push('back-btn');
    }
    if (skinny) {
        classes.push('skinny-btn');
    }
    if (breadCrumb) {
        classes.push('breadcrumb-btn');
    }
    if (research) {
        classes.push('research-btn');
    }
    if (researchClicked) {
        classes.push('research-animate-click');
    }
    if (fwdButton) {
        classes.push('fwd-btn');
    }

    const statuses = ['danger'];
    if (status && statuses.indexOf(status) > -1) {
        classes.push(status.toLowerCase());
    }

    return (
        <button ref={ref} className={classes.filter(Boolean).join(' ')} onClick={onClick} {...rest}>
            {loading && <ReactSVG className="spinner" src="../../../assets/images/spinner.svg" />}
            {icon && <span className={`icon ${!text ? 'icon-only' : ''}`}>{icon}</span>}
            <span className="text">{loading ? loading : text}</span>
            {iconAfter && <span className={`icon-after ${!text ? 'icon-only' : ''}`}>{iconAfter}</span>}
        </button>
    );
});

export default Button;

export function ButtonChain({ timescale, children, ...rest }) {
    const classes = ['flank-button-chain', rest.className || ''].filter(Boolean);
    if (timescale) {
        classes.push('timescale');
    }

    return <div className={classes.join(' ')}>{children}</div>;
}

ButtonChain.propTypes = {
    timescale: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};

Button.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    block: PropTypes.bool,
    action: PropTypes.bool,
    gray: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    icon: PropTypes.node,
    iconAfter: PropTypes.node,
    status: PropTypes.string,
    active: PropTypes.bool,
    greenOutline: PropTypes.bool,
    danger: PropTypes.bool,
    plain: PropTypes.bool,
    xButton: PropTypes.bool,
    back: PropTypes.bool,
    skinny: PropTypes.bool,
    breadCrumb: PropTypes.bool,
    className: PropTypes.string,
    research: PropTypes.bool,
    researchClicked: PropTypes.bool,
    fwdButton: PropTypes.bool,
};
