import React from 'react';
import './AlertDialog.scss';
import PropTypes from 'prop-types';

const DialogBody = ({ className = '', children }) => {
    return <div className={`alert-dialog-body ${className}`}>{children}</div>;
};

export default DialogBody;

DialogBody.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
