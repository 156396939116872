import { gql } from '@apollo/client';

export const GET_ONE_BLOG_POST = gql`
    query GetOneBlogPost($filter: [WhereClause!]!) {
        getOneBlogPost(filter: $filter) {
            content
            coverImage
            createdAt
            createdBy
            id
            isPublished
            publishedAt
            slug
            title
            user {
                username
                firstName
            }
        }
    }
`;
