import React from 'react';
import { Link } from '../../../../components';
import { ReactSVG } from 'react-svg';
import { LinkMapper } from '../../utils/link-mapper';
import NavigationLink from '../navigation-link/navigation-link';
import MobileNavigationLink from '../mobile-navigation-link/mobile-navigation-link';
import { useSidebar } from '../../../../providers/sidebar';

import './navigation.scss';
import { useLocation } from 'react-router-dom';

export default function Navigation() {
    const location = useLocation();

    const {
        state: { isOpen: sideBarOpen },
        dispatch,
    } = useSidebar();

    let logoIcon = <ReactSVG className="logo" src="/assets/images/logo.svg" />;

    if (location.pathname.startsWith('/admin')) {
        logoIcon = <img className="logo-img" src="/assets/images/admin-logo.png" alt="real picture of murph" />;
    }
    const isActive = (path, absolute) => {
        if (absolute) {
            return location.pathname === path;
        } else if (location.pathname.startsWith(path)) {
            return true;
        }
        return false;
    };

    const mainNavigationLinks = [
        {
            name: 'Home',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/', true)
                            ? '/assets/images/icons/navigation-icons/home-active.svg'
                            : '/assets/images/icons/navigation-icons/home.svg'
                    }
                />
            ),
            path: '/',
        },
        {
            name: 'Find a Company',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/search', false)
                            ? '/assets/images/icons/navigation-icons/search-active.svg'
                            : '/assets/images/icons/navigation-icons/search.svg'
                    }
                />
            ),
            path: '/search',
        },
        {
            name: 'Profile',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/profile', false)
                            ? '/assets/images/icons/navigation-icons/profile-active.svg'
                            : '/assets/images/icons/navigation-icons/profile.svg'
                    }
                />
            ),
            path: '/profile',
        },
    ];

    const toggleSidebar = () => {
        dispatch({ type: 'setIsOpen', isOpen: !sideBarOpen });
    };

    const mobileNavigationLinks = [
        {
            name: 'Home',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/', true)
                            ? '/assets/images/icons/navigation-icons/home-active.svg'
                            : '/assets/images/icons/navigation-icons/home.svg'
                    }
                />
            ),
            path: '/',
        },
        {
            name: 'Find a Company',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/search', false)
                            ? '/assets/images/icons/navigation-icons/search-active.svg'
                            : '/assets/images/icons/navigation-icons/search.svg'
                    }
                />
            ),
            path: '/search',
        },
        {
            name: 'Profile',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        isActive('/settings', false)
                            ? '/assets/images/icons/navigation-icons/profile-active.svg'
                            : '/assets/images/icons/navigation-icons/profile.svg'
                    }
                />
            ),
            path: '/profile',
        },
        {
            name: 'Sectors',
            icon: (
                <ReactSVG
                    className=""
                    src={
                        sideBarOpen
                            ? '/assets/images/icons/navigation-icons/sector-active.svg'
                            : '/assets/images/icons/navigation-icons/sector.svg'
                    }
                />
            ),
            onClick: toggleSidebar,
        },
    ];

    return (
        <div className="flank-navigation">
            <div className="flank-navigation-logo">
                <Link to="/">{logoIcon}</Link>
            </div>

            <div className="large-screen-nav">
                <div className="flank-navigation-links">
                    <LinkMapper links={mainNavigationLinks} Component={NavigationLink} />
                    <NavigationLink
                        name="Administration"
                        icon={
                            <ReactSVG
                                className=""
                                src={
                                    isActive('/admin', false)
                                        ? '/assets/images/icons/navigation-icons/admin-active.svg'
                                        : '/assets/images/icons/navigation-icons/admin.svg'
                                }
                            />
                        }
                        path="/admin"
                        permission="admin-rights"
                    />
                </div>
                <div className="flank-navigation-spacer"></div>
            </div>

            <div className="small-screen-nav">
                <div className="flank-navigation-links">
                    <LinkMapper links={mobileNavigationLinks} Component={MobileNavigationLink} />
                </div>
            </div>
        </div>
    );
}
