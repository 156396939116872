import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../utils/numbers';
import './style.scss';

export default function SearchResultBrick({ title, value, modifier, color, inline }) {
    const containerClasses = ['search-result-brick-container'];
    if (inline) {
        containerClasses.push('inline');
    }

    if (value === 0) {
        value = '-';
        color = 'gray';
    }

    return (
        <div className={containerClasses.join(' ')}>
            <span className="title">{title}</span>
            <span className={`value ${color}`}>{value === '-' ? '-' : formatNumber(value, modifier)}</span>
        </div>
    );
}

SearchResultBrick.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    modifier: PropTypes.array.isRequired,
    color: PropTypes.oneOf(['red', 'green', 'gray']),
    inline: PropTypes.bool,
};
