import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactSVG } from 'react-svg';
import { IoAlert, IoCheckmark, IoWarningOutline, IoInformation } from 'react-icons/io5';

export const ALERT_VARIANT = {
    block: 'block',
};

const AlertProps = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'danger', 'warning', 'info', 'spinner', 'default']),
    header: PropTypes.string,
    noStyle: PropTypes.bool,
    'data-testid': PropTypes.string,
    variant: PropTypes.string,
    style: PropTypes.object,
};

/**
 * Alert component.
 *
 * @param {object} props
 * @param {string} props.text
 * @param {success|danger|warning|info|spinner|default} [props.type]
 * @param {string} [props.header]
 * @param {boolean} [props.noStyle]
 * @param {string} [props.variant] 'block'
 * @param {object} [props.style]
 * @returns {React.FC}
 */
export default function Alert({ text, type, header, noStyle, variant, style, ...rest }) {
    let classes = ['flank-alert'];
    let alertIcon = null;
    if (type) {
        classes.push(type);

        switch (type) {
            case 'success':
                alertIcon = <IoCheckmark />;
                break;
            case 'danger':
                alertIcon = <IoAlert />;
                break;
            case 'warning':
                alertIcon = <IoWarningOutline />;
                break;
            case 'info':
                alertIcon = <IoInformation />;
                break;
            case 'spinner':
                alertIcon = <ReactSVG className="spinner" src="../../../assets/images/spinner.svg" />;
                break;
            default:
                alertIcon = null;
        }
    }
    if (header) {
        classes.push('with-header');
    }
    if (noStyle) {
        classes.push('no-style');
    }

    if (variant) {
        if (Object.keys(ALERT_VARIANT).includes(variant)) {
            classes.push(variant);
        }
    }

    return (
        <div className={classes.join(' ')} data-testid={rest['data-testid'] || null} style={style}>
            <div className="wrapper">
                {alertIcon && <div className="icon">{alertIcon}</div>}
                <div className="alert-content">
                    {header && <div className="alert-header">{header}</div>}
                    <div className="alert-body">{text}</div>
                </div>
            </div>
        </div>
    );
}

Alert.propTypes = AlertProps;
