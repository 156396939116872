import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import './style.scss';
import { PageHeader } from '../../../../components';

export default function ContentSection({ title, tldr, text, children, titleOnly, ...props }) {
    const classes = ['flank-company-content-section', props.className || ''].filter(Boolean);
    if (titleOnly) {
        classes.push('title-only');
    }
    return (
        <div {...props} className={classes.join(' ')}>
            <div className="content-header">
                <PageHeader text={title} titleOnly={titleOnly} />
            </div>
            {tldr && (
                <div className="tldr">
                    <div className="tldr-header">
                        <div className="tldr-text">tldr;</div>
                        <div className="importance">
                            Importance:{' '}
                            <div className="arrows">
                                {Array(tldr.importance)
                                    .fill()
                                    .map((_, index) => (
                                        <ReactSVG
                                            key={index}
                                            src="../../../assets/images/icons/page-icons/up-arrow.svg"
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: tldr.text }}></div>
                </div>
            )}
            <div className="paragraph-text" dangerouslySetInnerHTML={{ __html: text }} />
            {children && <div className="children">{children}</div>}
        </div>
    );
}

ContentSection.propTypes = {
    title: PropTypes.string.isRequired,
    tldr: PropTypes.shape({
        importance: PropTypes.number,
        text: PropTypes.string,
    }),
    text: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    titleOnly: PropTypes.bool,
};
