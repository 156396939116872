import { useLazyQuery } from '@apollo/client';
import {
    GET_COMPANY,
    GET_PRICE_HISTORY,
    GET_COMPANIES_INCOME_STATEMENT_QUERY,
    GET_COMPANIES_BALANCE_SHEET_QUERY,
    GET_COMPANY_QUICK_DATA,
    GET_COMPANIES_CASH_FLOW_QUERY,
    COMPANY_GOVERNANCE_QUERY,
    COMPANY_STRATEGY_QUERY,
} from '../gql-data';
import { useCompany } from '../../../providers/company';
import { getRandomColor } from '../../../colors';

export const updateCompanyColors = (state, dispatch, companyIds) => {
    const tempColors = { ...state.companyColors };
    for (const id of companyIds) {
        if (!tempColors[id]) {
            tempColors[id] = getRandomColor();
        }
    }

    dispatch({
        type: 'setCompanyColors',
        companyColors: tempColors,
    });
};

export const loadMainCompanyData = (queryProps) => {
    return useLazyQuery(GET_COMPANY, {
        ...(queryProps || {}),
    });
};

export const loadCompanyQuickData = () => {
    const { dispatch } = useCompany();

    return useLazyQuery(GET_COMPANY_QUICK_DATA, {
        onCompleted: ({ getCompanyQuickData: data }) => {
            dispatch({
                type: 'setQuickData',
                quickData: data,
            });
        },
    });
};

export const loadCompanyPriceHistory = () => {
    const { dispatch } = useCompany();

    return useLazyQuery(GET_PRICE_HISTORY, {
        onCompleted: ({ getPriceHistoryForCompany: data }) => {
            dispatch({
                type: 'setPriceHistory',
                priceHistory: data,
            });
        },
    });
};

export const loadCompanyIncomeStatement = () => {
    const { state, dispatch } = useCompany();

    return useLazyQuery(GET_COMPANIES_INCOME_STATEMENT_QUERY, {
        variables: {
            ids: state.filterIds,
            range: state.financialTimescale,
        },
        onCompleted: ({ getCompaniesIncomeStatement: data }) => {
            updateCompanyColors(state, dispatch, state.filterIds);
            dispatch({
                type: 'setIncomeStatements',
                incomeStatements: data,
            });
        },
    });
};

export const loadCompanyBalanceSheet = () => {
    const { state, dispatch } = useCompany();

    return useLazyQuery(GET_COMPANIES_BALANCE_SHEET_QUERY, {
        variables: {
            ids: state.filterIds,
            range: state.financialTimescale,
        },
        onCompleted: ({ getCompaniesBalanceSheet: data }) => {
            updateCompanyColors(state, dispatch, [state.company.id]);
            dispatch({
                type: 'setBalanceSheets',
                balanceSheets: data,
            });
        },
    });
};

export const loadCompanyCashFlow = () => {
    const { state, dispatch } = useCompany();

    return useLazyQuery(GET_COMPANIES_CASH_FLOW_QUERY, {
        variables: {
            ids: state.filterIds,
            range: state.financialTimescale,
        },
        onCompleted: ({ getCompaniesCashFlow: data }) => {
            updateCompanyColors(state, dispatch, [state.company.id]);
            dispatch({
                type: 'setCashFlows',
                cashFlows: data,
            });
        },
    });
};

export const loadCompanyGovernance = () => {
    const { state, dispatch } = useCompany();

    return useLazyQuery(COMPANY_GOVERNANCE_QUERY, {
        variables: {
            getCompanyLeadershipId: state.company.id,
        },
        onCompleted: ({ getCompanyLeadership: data }) => {
            dispatch({
                type: 'setGovernance',
                governance: data,
            });
        },
    });
};

export const loadCompanyStrategy = () => {
    const { state, dispatch } = useCompany();

    return useLazyQuery(COMPANY_STRATEGY_QUERY, {
        variables: {
            filter: [
                {
                    field: 'id',
                    operator: 'eq',
                    value: state.company.id,
                },
            ],
        },
        onCompleted: ({ getOneCompany: data }) => {
            dispatch({
                type: 'setStrategy',
                strategy: data,
            });
        },
    });
};
