import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Link, Button } from '..';
import { IoPencilOutline } from 'react-icons/io5';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';

export default function PageHeader({ text, link, editLink, image, action, ticker, allowBack, titleOnly, pageTop }) {
    const navigate = useNavigate();
    const actionClasses = ['flank-page-header-actions'];
    if (link) {
        actionClasses.push('with-link');
    }

    const headerClasses = ['flank-page-header'];
    if (titleOnly) {
        headerClasses.push('title-only');
    }
    if (pageTop) {
        headerClasses.push('page-top');
    }

    return (
        <div className="flank-page-wrapper">
            <div className={headerClasses.join(' ')}>
                <div className="flank-page-header-content">
                    {allowBack && (
                        <div>
                            <Button
                                type="submit"
                                xButton={true}
                                plain={true}
                                icon={<ReactSVG src="/assets/images/icons/navigation-icons/back-btn.svg" />}
                                onClick={() => navigate(-1)}
                            />
                        </div>
                    )}
                    {image && (
                        <div className="image">
                            <img src={image} alt="page-header-icon" />
                        </div>
                    )}
                    <div className="flank-page-header-text">
                        <div className="text">
                            {ticker && <div className="ticker">{ticker}</div>}
                            {link && <Link plain to={link.href} text={link.text} />}
                            <h1>
                                {text}
                                {editLink && <Link className="edit-icon" to={editLink} icon={<IoPencilOutline />} />}
                            </h1>
                        </div>
                    </div>
                </div>

                {action && <div className={actionClasses.join(' ')}>{action}</div>}
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    text: PropTypes.string,
    link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    }),
    level: PropTypes.string,
    ticker: PropTypes.string,
    image: PropTypes.string,
    editLink: PropTypes.string,
    action: PropTypes.node,
    allowBack: PropTypes.bool,
    titleOnly: PropTypes.bool,
    pageTop: PropTypes.bool,
    research: PropTypes.bool,
};
