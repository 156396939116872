// TODO: THIS FILE IS TOO BIG!

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { PercentageChange, CustomForm, FormGroup, TextInput, Button } from '../';
import { IoAddOutline, IoArrowUp, IoCloseOutline } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { GET_SYMBOL_TYPEAHEAD } from '../../gql-data';
import { useLazyQuery } from '@apollo/client';

export function BubbleComparisonRow({ id, icon, color, companyName, value, symbol, change, deleteable, onDelete }) {
    const handleDelete = () => {
        if (onDelete && typeof onDelete === 'function') {
            onDelete(id);
        }
    };

    return (
        <tr className="bubble">
            <td className="icon">
                <div className="icon-wrapper">
                    <div className="color-bar" style={{ borderColor: color }}></div>
                    {icon && icon.startsWith('local') ? (
                        <img src={icon} alt={`${symbol} company icon`} />
                    ) : (
                        <img src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${icon}`} alt={`${symbol} company icon`} />
                    )}
                </div>
            </td>
            <td>{companyName}</td>
            <td>{value}</td>
            <td className="change">{change && <PercentageChange value={change} />}</td>
            <td className="remove">
                {deleteable && (
                    <button type="button" className="remove-button" onClick={handleDelete}>
                        <IoCloseOutline />
                    </button>
                )}
            </td>
        </tr>
    );
}

export function BarComparisonRow({ id, icon, color, companyName, values, deleteable, onDelete }) {
    const handleDelete = () => {
        if (onDelete && typeof onDelete === 'function') {
            onDelete(id);
        }
    };

    return (
        <>
            <tr className="bar-row">
                <td className="icon">
                    <div className="icon-wrapper">
                        <div className="color-bar" style={{ borderColor: color }}></div>
                        <img
                            className="company-icon"
                            src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${icon}`}
                            alt={`${companyName} company icon`}
                        />
                    </div>
                </td>
                {values.map((value, index) => (
                    <td key={index}>
                        <div className="cell-value">{value.value}</div>
                        <div className={value.change >= 0 ? 'change positive' : 'change negative'}>
                            {!value.change ? '-' : (value.change < 0 ? value.change : `${value.change}`) + '%'}
                        </div>
                    </td>
                ))}
                <td className="remove">
                    {deleteable && (
                        <button type="button" className="remove-button" onClick={handleDelete}>
                            <IoCloseOutline />
                        </button>
                    )}
                </td>
            </tr>
            <tr className="cumulative-change-row">
                <td colSpan={7}>
                    <span className="cumulative-text">Cumulative:</span>
                    <PercentageChange border={true} value={values[0].cumulativeChange} />
                </td>
            </tr>
        </>
    );
}

export function ComparisonTableActions({ filtersOnAdd, filterOnChange, currentFilter }) {
    const [currentAction, setCurrentAction] = React.useState(null);
    const methods = useForm();
    const [typeaheadLoading, setTypeaheadLoading] = useState(false);
    const [typeaheadOptions, setTypeaheadOptions] = useState([]);

    const [lookupSymbols] = useLazyQuery(GET_SYMBOL_TYPEAHEAD, {
        onCompleted: (data) => {
            setTypeaheadLoading(false);
            if (data.getAllCompanies.rows.length > 0) {
                setTypeaheadOptions(
                    data.getAllCompanies.rows.reduce((acc, company) => {
                        acc.push({
                            value: company.id,
                            label: company.displaySymbol,
                            labelName: company.name || null,
                        });
                        return acc;
                    }, [])
                );
            }
        },
        onError: (error) => {
            setTypeaheadLoading(false);
            console.log(error);
        },
    });

    const handleAdd = () => {
        if (currentAction === 'add') {
            setCurrentAction(null);
        } else {
            setCurrentAction('add');
        }
    };

    const handleSubmit = (data) => {
        if (filtersOnAdd && typeof filtersOnAdd === 'function') {
            if (filtersOnAdd(data.companyName)) {
                setCurrentAction(null);
                methods.reset();
            }
        }
    };

    //   const handleFilter = () => {
    //     if (currentAction === "filter") {
    //       setCurrentAction(null);
    //     } else {
    //       setCurrentAction("filter");
    //     }
    //   };

    const typeaheadOnSelect = (value) => {
        setTypeaheadOptions([]);
        handleSubmit({ companyName: value });
    };

    const typeaheadOnChange = (value) => {
        if (value === '') {
            setTypeaheadOptions([]);
        } else {
            setTypeaheadLoading(true);
            const val = value.trim();
            const filterValue = `${val}%`;
            const filters = [
                {
                    join: 'and',
                    values: [
                        {
                            field: 'name',
                            operator: 'ne',
                            value: '',
                        },
                        {
                            join: 'or',
                            values: [
                                {
                                    join: 'or',
                                    field: 'displaySymbol',
                                    values: [
                                        {
                                            operator: 'eq',
                                            value: val,
                                        },
                                        {
                                            operator: 'like',
                                            value: filterValue,
                                        },
                                    ],
                                },
                                {
                                    join: 'or',
                                    field: 'name',
                                    values: [
                                        {
                                            operator: 'eq',
                                            value: val,
                                        },
                                        {
                                            operator: 'like',
                                            value: filterValue,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ];

            lookupSymbols({
                variables: {
                    limit: 5,
                    filters,
                },
            });
        }
    };

    const updateSelection = (e) => {
        if (filterOnChange && typeof filterOnChange === 'function') {
            if (filterOnChange(e.target.id)) {
                // setCurrentAction(null);
            }
        }
    };

    return (
        <>
            <div className="comparison-actions">
                <button
                    type="button"
                    className={`comparison-action-button ${currentAction === 'add' ? 'active' : ''}`}
                    onClick={handleAdd}
                >
                    <IoAddOutline />
                </button>
                {/* <button
          type="button"
          className={`comparison-action-button ${
            currentAction === "filter" ? "active" : ""
          }`}
          onClick={handleFilter}
        >
          <IoFilterOutline />
        </button> */}
            </div>
            <div className="comparison-action-controls">
                {currentAction === 'add' && (
                    <CustomForm methods={methods} onSubmit={handleSubmit}>
                        <FormGroup collected>
                            <TextInput
                                name="companyName"
                                placeholder="Search for companies to add..."
                                typeahead={true}
                                typeaheadOnSelect={typeaheadOnSelect}
                                typeaheadOnChange={typeaheadOnChange}
                                typeaheadOptions={typeaheadOptions}
                                typeaheadLoading={typeaheadLoading}
                                typeaheadMinLength={2}
                                onBlur={() => setTypeaheadOptions([])}
                                autoComplete="off"
                            />
                            <Button type="submit" icon={<IoArrowUp />} />
                        </FormGroup>
                    </CustomForm>
                )}
                {currentAction === 'filter' && (
                    <div className="comparison-filters">
                        <div className="comparison-filter-group">
                            <div className="comparison-filter-group-label">Fundamentals</div>
                            <div className="comparison-filter-group-controls">
                                <label htmlFor="valuation">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="valuation"
                                        checked={currentFilter === 'valuation'}
                                    />
                                    <span>Valuation</span>
                                </label>
                                <label htmlFor="revenue">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="revenue"
                                        checked={currentFilter === 'revenue'}
                                    />
                                    <span>Revenue</span>
                                </label>
                                <label htmlFor="grossProfit">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="grossProfit"
                                        checked={currentFilter === 'grossProfit'}
                                    />
                                    <span>Gross Profit Margin</span>
                                </label>
                                <label htmlFor="operatingMargin">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="operatingMargin"
                                        checked={currentFilter === 'operatingMargin'}
                                    />
                                    <span>Operating Margin</span>
                                </label>
                                <label htmlFor="peRation">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="peRation"
                                        checked={currentFilter === 'peRation'}
                                    />
                                    <span>P/E ratio</span>
                                </label>
                                <label htmlFor="psRatio">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="psRatio"
                                        checked={currentFilter === 'psRatio'}
                                    />
                                    <span>P/S ratio</span>
                                </label>
                                <label htmlFor="epsRatio">
                                    <input type="radio" name="selection" onChange={updateSelection} id="epsRatio" />
                                    <span>EPS</span>
                                </label>
                                <label htmlFor="evEbitda">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="evEbitda"
                                        checked={currentFilter === 'evEbitda'}
                                    />
                                    <span>EV/EBITDA</span>
                                </label>
                                <label htmlFor="ebitEv">
                                    <input
                                        type="radio"
                                        name="selection"
                                        onChange={updateSelection}
                                        id="ebitEv"
                                        checked={currentFilter === 'ebitEv'}
                                    />
                                    <span>EBIT/EV</span>
                                </label>
                            </div>
                        </div>
                        {/* <div className='comparison-filter-group'>
                            <div className='comparison-filter-group-label'>Valuation (Market Cap)</div>
                            <div className='comparison-filter-group-controls'>
                                <label htmlFor='lessThan2B'>
                                    <input type='checkbox' id='lessThan2B' />
                                    <span>
                                        &lt; $2B
                                    </span>
                                </label><label htmlFor='between2And10B'>
                                    <input type='checkbox' id='between2And10B' />
                                    <span>
                                        $2B - $10B
                                    </span>
                                </label><label htmlFor='between10And200B'>
                                    <input type='checkbox' id='between10And200B' />
                                    <span>
                                        $10B - $200B
                                    </span>
                                </label><label htmlFor='greaterThan200B'>
                                    <input type='checkbox' id='greaterThan200B' />
                                    <span>
                                        &gt; $200B
                                    </span>
                                </label>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </>
    );
}

export default function ComparisonTable({ type, data, filters, onAdd, change, headers }) {
    let RowElement = null;
    if (type === 'bubble') {
        RowElement = BubbleComparisonRow;
    } else {
        RowElement = BarComparisonRow;
    }

    if (!RowElement) {
        console.log('no row element');
        return null;
    }
    const formatHeaders = (headerDate) => {
        return headerDate.map((date) => {
            let newDate = date.split(' ');
            newDate[1] = newDate[1].slice(2);
            newDate[1] = `'${newDate[1]}`;
            newDate = newDate.join(' ');
            return newDate;
        });
    };

    const tableClasses = ['flank-comparison-table'];
    if (type === 'bar') {
        tableClasses.push('bar-table');
    }

    return (
        <div className="flank-comparison">
            <table className={tableClasses.join(' ')}>
                {headers && (
                    <thead>
                        <tr className="bar-row">
                            <th className="icon"></th>

                            {formatHeaders(headers).map((header, index) => (
                                <th key={index}>
                                    <div className="header">{header}</div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {data.map((item) => {
                        return (
                            <RowElement
                                key={item.symbol}
                                onDelete={filters?.onDelete}
                                {...item}
                                change={change || item.change}
                            />
                        );
                    })}
                </tbody>
            </table>
            {filters && (
                <ComparisonTableActions
                    filtersOnAdd={filters.onAdd}
                    dataOnAdd={onAdd}
                    filterOnChange={filters.onChange}
                    currentFilter={filters.current}
                />
            )}
        </div>
    );
}

BubbleComparisonRow.propTypes = {
    symbol: PropTypes.string,
    name: PropTypes.string,
    change: PropTypes.number,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    data: PropTypes.array,
    id: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    deleteable: PropTypes.bool,
    companyName: PropTypes.string,
    value: PropTypes.string,
};

BarComparisonRow.propTypes = {
    symbol: PropTypes.string,
    name: PropTypes.string,
    change: PropTypes.number,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    data: PropTypes.array,
    id: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    deleteable: PropTypes.bool,
    companyName: PropTypes.string,
    values: PropTypes.array,
};

ComparisonTableActions.propTypes = {
    filtersOnAdd: PropTypes.func,
    dataOnAdd: PropTypes.func,
    filterOnChange: PropTypes.func,
    currentFilter: PropTypes.string,
};

ComparisonTable.propTypes = {
    type: PropTypes.string,
    data: PropTypes.array,
    filters: PropTypes.object,
    onAdd: PropTypes.func,
    value: PropTypes.string,
    change: PropTypes.number,
    headers: PropTypes.array,
};
