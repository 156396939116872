import React, { useRef, useState } from 'react';
import { FormGroup, Form, TextInput, Button } from '../../../components';
import CoverImageInput from '../../../components/Forms/FormControls/CoverImageInput';
import RichTextEditor from '../../../components/RichTextEditor';
import PropTypes from 'prop-types';
import './post-form-style.scss';
import ReferenceSelector from '../components/reference-selector';

const PostForm = ({
    onSubmit: propOnSubmit,
    onError,
    clearError,
    onInputChange,
    currentCompanySymbol,
    editMode = false,
    formData,
    onCancel,
}) => {
    const editorRef = useRef(null);
    const [imageFile, setImageFile] = useState(undefined);
    const [currentTopLevelResource, setCurrentTopLevelResource] = useState(formData?.topLevelResource || 'overview');

    const selectOptions = [
        { value: 'overview', label: 'Company Overview' },
        { value: 'income statement', label: 'Income Statement' },
        { value: 'balance sheet', label: 'Balance Sheet' },
        { value: 'cash flow', label: 'Cash Flow' },
        { value: 'governance', label: 'Governance' },
        { value: 'strategy', label: 'Strategy' },
    ];

    const onSubmit = async (data) => {
        // Get data from RichTextEditor
        const editorData = await editorRef.current.save();

        // Combine editor and cover image data with form data
        let combinedData = {
            ...data,
            topLevelResource: currentTopLevelResource,
            editorData,
            imageFile,
        };

        // Call prop onSubmit function
        propOnSubmit(combinedData);
    };

    const onFileChange = (file) => {
        clearError();
        setImageFile(file);
    };

    return (
        <Form
            onSubmit={onSubmit}
            onError={onError}
            defaultValues={{ title: formData?.title || '', subtitle: formData?.subtitle || '' }}
        >
            <CoverImageInput
                onFileChange={onFileChange}
                mbSizeLimit={5}
                mainText="UPLOAD BANNER IMAGE"
                subText="5mb MAX - 1500px BY 500px OR 3:1 ASPECT RATIO"
                imageFilename={formData?.imageFilename || null}
            />
            <FormGroup>
                <TextInput
                    typeaheadOnChange={onInputChange}
                    type="text"
                    label="Title"
                    hideLabel={true}
                    name="title"
                    placeholder={'TITLE'}
                    className="post-title-input"
                    readOnly={editMode}
                />
            </FormGroup>
            <FormGroup>
                <TextInput
                    typeaheadOnChange={onInputChange}
                    type="text"
                    label="Subtitle"
                    hideLabel={true}
                    name="subtitle"
                    placeholder={'SUBTITLE'}
                    className="post-subtitle-input"
                />
            </FormGroup>
            <ReferenceSelector
                options={selectOptions}
                onChange={(value) => setCurrentTopLevelResource(value)}
                companySymbol={currentCompanySymbol}
                inputName="topLevelResource"
                currentTopLevelResource={currentTopLevelResource}
                className="reference-selector"
            />
            <div className="rich-text-editor-container">
                {' '}
                <RichTextEditor
                    ref={editorRef}
                    placeholderText={'Type your post here...'}
                    data={formData ? JSON.parse(formData.content) : null}
                ></RichTextEditor>
            </div>

            <div className="post-button-container">
                {editMode ? (
                    <>
                        <Button type="button" plain={true} onClick={onCancel} text="CANCEL" />
                        <Button action={true} text="Save" />
                    </>
                ) : (
                    <Button action={true} text="Post" />
                )}
            </div>
        </Form>
    );
};

export default PostForm;

PostForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func,
    clearError: PropTypes.func,
    onInputChange: PropTypes.func,
    currentCompanySymbol: PropTypes.string.isRequired,
    editMode: PropTypes.bool,
    formData: PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        content: PropTypes.string,
        topLevelResource: PropTypes.string,
        imageFilename: PropTypes.string,
    }),
    onCancel: PropTypes.func,
};
