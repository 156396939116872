import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalScrollable, Badge } from '../../../components';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';

export function BadgeFilters({ title, type, data }) {
    const BadgeFilterComponent = ({ onSelected, onUnselected, selectedFilters }) => {
        const [selected, setSelected] = useState(selectedFilters);

        useEffect(() => {
            setSelected(selectedFilters);
        }, [selectedFilters]);

        function onClick(item) {
            // find if item is already selected
            const foundSelected = selected.find((r) => r.label === item.label);

            // if not selected, add it to selected
            if (!foundSelected) {
                const newItem = {
                    label: item.label,
                    explanation: item.explanation,
                    sort: item.defaultSort,
                    field: item.field,
                    type,
                };

                setSelected((items) => [...items, newItem]);

                onSelected(newItem);
            } else {
                const changeSortDirection = item.defaultSort === foundSelected.sort;

                // if we need to change sort direction, change it
                if (changeSortDirection) {
                    // changing sort direction
                    const index = selected.findIndex((i) => i.label === item.label);

                    const newSelected = [...selected];
                    newSelected[index].sort = newSelected[index].sort === 'DESC' ? 'ASC' : 'DESC';
                    setSelected(newSelected);
                    onSelected(newSelected[index]);

                    // if we don't need to change sort direction, remove it
                } else {
                    setSelected((items) => items.filter((i) => i.label !== item.label));
                    onUnselected(item);
                }
            }
        }

        const isSelected = useCallback(
            (label) => {
                return !!selected.some((item) => item.label === label);
            },
            [selected]
        );

        const direction = useCallback(
            (label) => {
                return selected.find((item) => item.label === label).sort;
            },
            [selected]
        );

        return (
            <>
                <h3>{title}</h3>
                <HorizontalScrollable>
                    {data.map((item) => (
                        <Badge
                            key={item.label}
                            label={item.label}
                            selected={isSelected(item.label)}
                            onClick={() => onClick(item)}
                            icon={
                                !isSelected(item.label) ? undefined : direction(item.label) === 'DESC' ? (
                                    <TiArrowSortedDown size={14} />
                                ) : (
                                    <TiArrowSortedUp size={14} />
                                )
                            }
                            iconLast
                        />
                    ))}
                </HorizontalScrollable>
            </>
        );
    };

    BadgeFilterComponent.propTypes = {
        multiSelect: PropTypes.bool,
        onSelected: PropTypes.func,
        onUnselected: PropTypes.func,
        selectedFilters: PropTypes.arrayOf(PropTypes.string),
    };

    return BadgeFilterComponent;
}
