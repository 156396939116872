import React, { useRef } from 'react';
import { Sidebar } from '../../components';
import { BaseTemplate } from '../../templates';
import { GET_ONE_BLOG_POST } from './post-gql';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import moment from 'moment';

export default function Post() {
    const { slug } = useParams();
    const navigate = useNavigate();
    const sidebar = useRef(<Sidebar />);

    const { data, loading } = useQuery(GET_ONE_BLOG_POST, {
        variables: {
            filter: [
                {
                    field: 'slug',
                    operator: 'eq',
                    value: slug,
                },
            ],
        },
    });

    if (!data?.getOneBlogPost && !loading) {
        navigate('/404');
    }

    return (
        <BaseTemplate
            pageHeaderProps={{
                text: data?.getOneBlogPost.title || 'Post',
                allowBack: true,
            }}
            sidebar={sidebar.current}
        >
            {loading ? null : (
                <div className="postpage-container">
                    <div className="author-info">
                        <span className="bold">{data?.getOneBlogPost.user.firstName}</span>
                        <span>@{data.getOneBlogPost.user.username}</span>
                        <div className="dot" />
                        <span>{moment(data.getOneBlogPost.publishedAt).format('MMMM D, YYYY')}</span>
                        <div className="dot" />
                        <span className="bold">Field Manual</span>
                    </div>
                    <img
                        src={`${process.env.REACT_APP_ASSETS_ENDPOINT}/${data.getOneBlogPost.coverImage}`}
                        alt="post-image"
                        className="post-image"
                    />
                    <ReactMarkdown className="post-content">{data.getOneBlogPost.content}</ReactMarkdown>
                </div>
            )}
        </BaseTemplate>
    );
}
