/**
 * makes classes easier to read by removing empty, null, undefined, and false values
 * leaving only valid class strings
 *
 * @param {string[]} classes
 */
export function cleanClasses(classes) {
    if (!classes) {
        return '';
    }
    return classes.filter(Boolean).join(' ');
}

/**
 * util function to turn snake-case into camelCase
 */
export function camelCase(str) {
    return str.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
    });
}

/**
 * util function to turn camelCase into Proper case
 */
export function properCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
}
