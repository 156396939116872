import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../style.scss';

export default function ThesisBullet({ question, options, name, register, value, ...rest }) {
    delete rest.componentType;

    const [selectedOption, setSelectedOption] = useState(value || null);
    const [error, setError] = useState(false);

    const handleOnChange = (e) => {
        setSelectedOption(e.target.value);
        setError(false);
    };

    return (
        <div className="thesis-bullet">
            <div className="bullet-header">{question}</div>
            <div className="bullet-body">
                {options.map((option) => {
                    let registered = null;

                    if (register) {
                        registered = register(name, {
                            onChange: handleOnChange,
                            validate: (value) => {
                                if (value !== null) {
                                    return true;
                                } else {
                                    setError(true);
                                    return false;
                                }
                            },
                        });
                    }

                    delete rest.validate;

                    return (
                        <div key={option.display} className="option-container">
                            <label htmlFor={`option-${option.display}`}>
                                <input
                                    type="radio"
                                    id={`option-${option.display}`}
                                    value={option.value}
                                    name={registered?.name}
                                    ref={registered?.ref}
                                    onChange={registered?.onChange}
                                    checked={selectedOption === option.value}
                                    {...rest}
                                />
                                <span></span>
                                {option.display}
                            </label>
                            {selectedOption === option.value && <div className="bullet-subtext">{option.subtext}</div>}
                        </div>
                    );
                })}
                {error && <p className="error neg-margin">Please select an option.</p>}
            </div>
        </div>
    );
}

ThesisBullet.propTypes = {
    question: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            display: PropTypes.string.isRequired,
            subtext: PropTypes.string.isRequired,
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func,
    value: PropTypes.string,
};

ThesisBullet.defaultProps = {
    componentType: 'TextInput',
};
