import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { IoArrowUpOutline } from 'react-icons/io5';

export default function PercentageChange({ value, range, border, ...props }) {
    value = isFinite(value) ? value : 0;
    const classes = ['movement-pill', props.className || ''].filter(Boolean);
    if (border) {
        classes.push('border');
    }

    const sign = value < 0 ? '' : '';
    let percentage = Math.abs(value * 100);
    let tooltip = null;

    if (!value || value === 0) {
        classes.push('no-change');
    } else if (value < 0) {
        classes.push('down');
    } else {
        classes.push('up');
    }

    if (percentage >= 1000) {
        tooltip = <span className="tooltip">{percentage.toFixed(2) + '%'}</span>;
        percentage = 'a lot';
    } else if (percentage < 1) {
        percentage = percentage.toFixed(1) + '%';
    } else if (percentage < 10) {
        percentage = percentage.toFixed(1) + '%';
    } else {
        percentage = Math.round(percentage) + '%';
    }

    return (
        <div {...props} className={classes.join(' ')}>
            {value && value !== 0 ? <IoArrowUpOutline className="direction-arrow" /> : null}
            {sign}
            {!value || value === 0 ? '-' : percentage}
            {range ? ` (${range}) ` : null}
            {tooltip}
        </div>
    );
}

PercentageChange.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    range: PropTypes.string,
    border: PropTypes.bool,
    className: PropTypes.string,
};
