import React from 'react';
import PropTypes from 'prop-types';
import { VscCalendar } from 'react-icons/vsc';
import { ReactSVG } from 'react-svg';
import './style.scss';

export default function ProfileHeader({ firstName, lastName, username, joined }) {
    function getInitials() {
        return `${firstName[0]}${lastName[0]}`;
    }

    return (
        <div className="profile-header-container">
            <div className="profile-pic-container">
                <span className="initials">{getInitials()}</span>
            </div>
            <div className="profile-data">
                <div className="name-container">
                    <span className="name">{firstName}</span>
                    <span className="username">@{username}</span>
                </div>
                <span className="bio">Hi! I&apos;m {firstName} and I&apos;m an early adopter of Flank! </span>
                <div className="flex-block">
                    <VscCalendar />
                    <span className="date">Joined {joined}</span>
                </div>
                <div className="flex-block">
                    <ReactSVG src="../../../assets/images/alpha.svg" />
                    <span className="badge">Alpha Tester</span>
                </div>
            </div>
            <div></div>
        </div>
    );
}

ProfileHeader.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    joined: PropTypes.string,
};
