import React from 'react';
import PropTypes from 'prop-types';
import { PageHeader } from '../../components';

export const ResearchBody = ({ title, classStyle, children }) => (
    <div className={`research-body ${classStyle}`}>
        <PageHeader titleOnly={true} text={title} />

        <div>{children}</div>
    </div>
);

ResearchBody.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    classStyle: PropTypes.string,
};
