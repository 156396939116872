import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GuideHeadline } from '../../../../components/Research/guide-headline';
import ThesisBullet from './components/thesis-bullet';
import Form from '../../../../components/Form/';
import CurrencyInput from '../../../../components/Forms/FormControls/CurrencyInput/currency-input';
import DateInput from '../../../../components/Forms/FormControls/DateInput/date-input';
import TextInput from '../../../../components/Forms/FormControls/TextInput/index';
import Button from '../../../../components/Button';
import Alert from '../../../../components/Alert';
import { useCompany } from '../../../../providers/company';
import { SearchResultBrick } from '../../../../components';
import ContentSection from '../content-section/content-section';
import { abbreviateDollars } from '../../../../utils/numbers';
import Link from '../../../../components/Link';
import '../../../../components/Research/style.scss';
import './style.scss';
import { useMutation, useLazyQuery } from '@apollo/client';
import { COMPLETE_JOURNEY, CREATE_THESIS, GET_ONE_JOURNEY } from '../../gql-data';
import { GET_ONE_COMPANY_THESIS } from './company-thesis-gql';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

const SentimentLabel = {
    bullish: 'Bullish',
    neutral: 'Neutral',
    bearish: 'Bearish',
};

export default function CompanyThesis() {
    const { state } = useCompany();
    const companyName = state.company.name;
    const [editing, setEditing] = useState(false);

    const [createThesis] = useMutation(CREATE_THESIS);
    const [completeJourney] = useMutation(COMPLETE_JOURNEY);
    const [companyThesis, setCompanyThesis] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    const [getCompanyThesis, { loading: companyThesisLoading }] = useLazyQuery(GET_ONE_COMPANY_THESIS, {
        skip: !state.company.id,
        variables: {
            filter: [
                {
                    field: 'companyId',
                    operator: 'eq',
                    value: state.company.id,
                },
            ],
        },
        onCompleted: (data) => {
            setCompanyThesis(data);
        },
    });

    useEffect(() => {
        getCompanyThesis();
    }, [state.company.id]);

    if (!companyThesis && companyThesisLoading) return null;

    const questions = [
        {
            question: `What is your overall sentiment on ${companyName}?`,
            options: [
                { display: 'Bullish', subtext: 'You believe the stock price will rise over time', value: 'bullish' },
                {
                    display: 'Neutral',
                    value: 'neutral',
                    subtext: 'You believe the stock price will remain the same, are uncertain, or conflicted',
                },
                { display: 'Bearish', value: 'bearish', subtext: 'You believe the stock price will fall over time' },
            ],
            name: 'sentiment',
        },
        {
            question: `How would you like to track ${companyName}?`,
            options: [
                {
                    display: `I${String.fromCharCode(39)}ll be investing, add to My Companies`,
                    value: 'investing',
                    subtext: 'You are confident and ready to invest in this company through your broker',
                },
                {
                    display: 'I am not sure yet, add to My Watchlist',
                    value: 'watchlist',
                    subtext: 'You are still unsure and want to keep an eye on this company',
                },
                {
                    display: `I don${String.fromCharCode(39)}t want to track this company,  add to nothing`,
                    value: 'not-tracking',
                    subtext: 'You have decided not to track this company for now',
                },
            ],
            name: 'tracking',
        },
    ];

    const handleSubmit = (values) => {
        completeJourney({
            variables: {
                journeyId: state.currentJourney.id,
            },
        }).then(() => {
            createThesis({
                variables: {
                    data: {
                        companyId: state.company.id,
                        positionOpened: values.datePositionOpened,
                        sentiment: values.sentiment,
                        thesis: values.thesisText,
                        tracking: values.tracking,
                        valuation: values.thesisValuation,
                    },
                },
                refetchQueries: [GET_ONE_JOURNEY],
                onCompleted: () => {
                    setEditing(false);
                    getCompanyThesis();
                    setAlertMessage({
                        type: 'success',
                        text: (
                            <>
                                Thesis successfully submitted! <Link to="/profile">View Profile</Link>
                            </>
                        ),
                    });
                },
            }).catch((error) => {
                setAlertMessage({
                    type: 'danger',
                    text:
                        error.message ||
                        'Something went wrong. Please try again later. If this persists, please contact us.',
                });
            });
        });
    };

    if (companyThesis?.getOneUserCompanyThesisForSelf && !editing) {
        const valuationDifference =
            companyThesis?.getOneUserCompanyThesisForSelf?.company.marketCap * 1000000 -
            companyThesis?.getOneUserCompanyThesisForSelf?.valuation;
        return (
            <div>
                <ContentSection title="Your thesis">
                    {alertMessage && <Alert type={alertMessage.type} text={alertMessage.text} />}
                    <div className="thesis-stats">
                        <SearchResultBrick
                            title="Your sentiment"
                            value={SentimentLabel[companyThesis?.getOneUserCompanyThesisForSelf?.sentiment]}
                            modifier={null}
                            color={'green'}
                        />
                        <SearchResultBrick
                            title="Your fair value"
                            value={abbreviateDollars(companyThesis?.getOneUserCompanyThesisForSelf?.valuation, 2)}
                            modifier={null}
                            color={'green'}
                        />
                        <SearchResultBrick
                            title="Current value"
                            value={abbreviateDollars(
                                companyThesis?.getOneUserCompanyThesisForSelf?.company.marketCap * 1000000,
                                2
                            )}
                            modifier={null}
                            color={'green'}
                        />
                        <SearchResultBrick
                            title="Difference"
                            value={abbreviateDollars(valuationDifference, 2)}
                            modifier={null}
                            color={valuationDifference > 0 ? 'green' : 'red'}
                        />
                        <SearchResultBrick
                            title="Thesis completed"
                            value={moment(companyThesis?.getOneUserCompanyThesisForSelf?.createdAt).format(
                                'MM/DD/YYYY'
                            )}
                            modifier={null}
                            color={'green'}
                        />
                    </div>
                    <ReactMarkdown>{companyThesis?.getOneUserCompanyThesisForSelf?.thesis}</ReactMarkdown>
                </ContentSection>

                <div className="submit-btn">
                    <Button action={true} text="Edit" type="button" onClick={() => setEditing(true)} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="guided-research">
                <GuideHeadline step="Step 1" title="Write your thesis">
                    <p>
                        As you go through researching the company, the last thing you will want to have done is one of
                        the most important parts: the thesis!
                    </p>
                    <p>
                        Your investing thesis is why you are investing in the company. This Thesis will stay with you
                        and can remind you why you own a certain company when things get rough at that company (bad
                        quarter, CEO in the news, etc.)
                    </p>
                    <p>It can help you keep your cool when everyone else is freaking out.</p>
                    <p>
                        Sometimes its harder to figure out when <strong>to sell</strong> than it is to figure out when
                        to buy. Your thesis helps you with that. We believe the only time you should sell is when when:
                    </p>
                    <ul>
                        <li>Your company reaches (or exceeds) your assessed fair-value</li>
                        <li>Your investing thesis changes</li>
                    </ul>
                    <p>
                        Have fun writing this! One day you may be able to share your analysis with other Flankers and
                        maybe get paid for it!
                    </p>
                </GuideHeadline>
            </div>
            <div className="thesis-body">
                <Form onSubmit={handleSubmit} className="thesis-form">
                    {questions.map((item) => (
                        <ThesisBullet
                            key={item.question}
                            question={item.question}
                            options={item.options}
                            name={item.name}
                            value={companyThesis?.getOneUserCompanyThesisForSelf?.[item.name]}
                            validate={(value) => {
                                if (value === '') {
                                    return 'Please select an option.';
                                }
                                return null;
                            }}
                        />
                    ))}
                    <div className="valuation-input">
                        <CurrencyInput
                            label={`Enter your estimated fair value of ${companyName}`}
                            companyName={companyName}
                            name={'thesisValuation'}
                            value={companyThesis?.getOneUserCompanyThesisForSelf?.valuation}
                            validate={(value) => {
                                if (value === '' || isNaN(Number(value))) {
                                    return 'Please enter a number (decimals are allowed, although that is weird.)';
                                }
                                return null;
                            }}
                        />
                    </div>
                    <div className="thesis-text-input">
                        <TextInput
                            name="thesisText"
                            label="Investing Thesis"
                            value={companyThesis?.getOneUserCompanyThesisForSelf?.thesis}
                            typeahead={false}
                            row={3}
                            multiline={true}
                            validate={(value) => {
                                if (value === '') {
                                    return 'Please complete your thesis!';
                                }
                                return null;
                            }}
                        />
                    </div>
                    <div className="thesis-date-input">
                        <DateInput
                            name={'datePositionOpened'}
                            label={`What date did you open your position on ${companyName}`}
                            value={companyThesis?.getOneUserCompanyThesisForSelf?.positionOpened}
                            validate={(value) => {
                                if (value === '') {
                                    return 'Please enter a date.';
                                }
                                return null;
                            }}
                        />
                    </div>
                    <div className="submit-btn">
                        {companyThesis?.getOneUserCompanyThesisForSelf && editing && (
                            <Button text="Cancel" type="button" onClick={() => setEditing(false)} />
                        )}
                        <Button action={true} text="Submit" type="submit" />
                    </div>
                    {alertMessage && <Alert type={alertMessage.type} text={alertMessage.text} />}
                </Form>
            </div>
        </>
    );
}

CompanyThesis.propTypes = {
    data: PropTypes.object,
    financialTimescale: PropTypes.string,
};
