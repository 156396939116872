import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useUser } from './providers/user';
import { useSidebar } from './providers/sidebar';
import './App.scss';
import React, { useEffect, lazy, Suspense } from 'react';
import { RequireAuth, RequireAnon, RequireAdmin, AlphaHome } from './utils/require-auth';
import Login from './pages/Login';
import { ME, GET_BASE_SIDEBAR } from './gql-data';
import { LoadingModal } from './components';
import { RedirectHandler } from './utils/redirect';
import Post from './pages/Posts/post';
import ReactGA from 'react-ga4';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

if (trackingId) {
    ReactGA.initialize(trackingId);
}

const Home = lazy(() => import('./pages/Home'));
const AdminHome = lazy(() => import('./pages/AdminHome'));
const About = lazy(() => import('./pages/About'));
const Privacy = lazy(() => import('./pages/Privacy'));
const UserListingPage = lazy(() => import('./pages/Users/Listing'));
const UserEditPage = lazy(() => import('./pages/Users/Edit'));
const UserCreatePage = lazy(() => import('./pages/Users/Create'));
const UserGroupListingPage = lazy(() => import('./pages/UserGroups/Listing'));
const UserGroupCreatePage = lazy(() => import('./pages/UserGroups/Create'));
const UserGroupEditPage = lazy(() => import('./pages/UserGroups/Edit'));
const PermissionListingPage = lazy(() => import('./pages/Permissions/Listing'));
const PermissionCreatePage = lazy(() => import('./pages/Permissions/Create'));
const PermissionEditPage = lazy(() => import('./pages/Permissions/Edit'));
const CompanyListingPage = lazy(() => import('./pages/Company/admin/Listing'));
const CompanyEditPage = lazy(() => import('./pages/Company/admin/Edit'));
const CompanyEditPost = lazy(() => import('./pages/Company/company-edit-post'));
const CollectionListingPage = lazy(() => import('./pages/Collections/Listing'));
const CollectionEditPage = lazy(() => import('./pages/Collections/Edit'));
const SignupPage = lazy(() => import('./pages/SignUp'));
const PasswordResetPage = lazy(() => import('./pages/PasswordReset'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPassword'));
const UserSettings = lazy(() => import('./pages/UserSettings'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const Search = lazy(() => import('./pages/Search'));
const GuidedResearch = lazy(() => import('./pages/GuidedResearch'));
const PlaylistListingPage = lazy(() => import('./pages/Admin/Playlists/playlist-listing'));
const PlaylistManage = lazy(() => import('./pages/Admin/Playlists/playlist-manage'));
const BlogListingPage = lazy(() => import('./pages/Admin/Blog/blog-listing'));
const ReportListingPage = lazy(() => import('./pages/Admin/Reports/report-listing'));
const BlogManage = lazy(() => import('./pages/Admin/Blog/blog-manage'));
const Bookmarks = lazy(() => import('./pages/Activity/Bookmarks'));
const Comments = lazy(() => import('./pages/Activity/Comments'));
const Notifications = lazy(() => import('./pages/Activity/Notifications'));
const Posts = lazy(() => import('./pages/Activity/Posts'));
const Upvotes = lazy(() => import('./pages/Activity/Upvotes'));
const Societe = lazy(() => import('./pages/Societe/Societe'));
const CompanySocial = lazy(() => import('./pages/Company/Company-Social/company-social'));
const PostsTemplate = lazy(() => import('./pages/Company/Company-Social/company-individual-posts'));

function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
}

const usePageTracking = () => {
    useEffect(() => {
        ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search);
    }, [window.location.pathname]);
};

export default function App() {
    usePageTracking();
    const { state: user, dispatch: userDispatch } = useUser();
    const { dispatch: sidebarDispatch } = useSidebar();
    const [getMe, { error: meError }] = useLazyQuery(ME, {
        onCompleted: (data) => {
            userDispatch({
                type: 'setUser',
                user: data.me,
            });
            userDispatch({
                type: 'userLoaded',
                loaded: true,
            });
        },
    });

    const [getSidebar, { loading: sidebarLoading }] = useLazyQuery(GET_BASE_SIDEBAR, {
        onCompleted: (data) => {
            sidebarDispatch({
                type: 'setCollections',
                collections: data.getSidebar,
            });
        },
        onError: (error) => {
            console.log(error);
        },
    });

    useEffect(() => {
        if (sidebarDispatch) {
            sidebarDispatch({
                type: 'setLoading',
                loading: sidebarLoading,
            });
        }
    }, [sidebarLoading, sidebarDispatch]);

    useEffect(() => {
        if (getMe) {
            getMe();
        }
    }, [getMe]);

    useEffect(() => {
        if (meError && userDispatch) {
            userDispatch({
                type: 'userLoaded',
                loaded: true,
            });
        }
    }, [meError, userDispatch]);

    useEffect(() => {
        if (user.loaded && user.id !== null) {
            getSidebar({
                variables: {
                    filters: [
                        {
                            field: 'parentId',
                            operator: 'isNull',
                        },
                    ],
                    orderBy: [
                        {
                            field: 'name',
                            direction: 'ASC',
                        },
                    ],
                    limit: 100,
                },
            });
        }
    }, [user.loaded, user.id, getSidebar]);

    if (!user.loaded) {
        return <LoadingModal />;
    }

    return (
        <Suspense fallback={<LoadingModal />}>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route exact path="/" element={<AlphaHome />} />
                    <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/privacy" element={<Privacy />} />
                    <Route exact path="/reset-password" element={<PasswordResetPage />} />

                    <Route path="/" element={<RequireAnon />}>
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/signup" element={<SignupPage />} />
                    </Route>

                    <Route path="/" element={<RequireAuth />}>
                        <Route path="/admin" element={<RequireAdmin />}>
                            <Route exact path="/admin" element={<AdminHome />} />
                            <Route exact path="/admin/users" element={<UserListingPage />} />
                            <Route exact path="/admin/user/new" element={<UserCreatePage />} />
                            <Route exact path="/admin/user/:id" element={<UserEditPage />} />
                            <Route exact path="/admin/user-groups" element={<UserGroupListingPage />} />
                            <Route exact path="/admin/user-group/new" element={<UserGroupCreatePage />} />
                            <Route exact path="/admin/user-group/:id" element={<UserGroupEditPage />} />
                            <Route exact path="/admin/permissions" element={<PermissionListingPage />} />
                            <Route exact path="/admin/permission/new" element={<PermissionCreatePage />} />
                            <Route exact path="/admin/permission/:id" element={<PermissionEditPage />} />
                            <Route exact path="/admin/companies" element={<CompanyListingPage />} />
                            <Route exact path="/admin/company/:id" element={<CompanyEditPage />} />
                            <Route exact path="/admin/collections" element={<CollectionListingPage />} />
                            <Route exact path="/admin/collection/:id" element={<CollectionEditPage />} />
                            <Route exact path="/admin/playlists" element={<PlaylistListingPage />} />
                            <Route exact path="/admin/playlist/:id" element={<PlaylistManage />} />
                            <Route exact path="/admin/blog-posts" element={<BlogListingPage />} />
                            <Route exact path="/admin/reports" element={<ReportListingPage />} />
                            <Route exact path="/admin/blog-post/new" element={<BlogManage />} />
                            <Route exact path="/admin/blog-post/:id" element={<BlogManage />} />
                        </Route>
                        <Route exact path="/company/:symbol" element={<Navigate to="overview" />} />
                        <Route exact path="/company/:symbol/societe" element={<CompanySocial />} />
                        <Route exact path="/company/:symbol/societe/:slug" element={<PostsTemplate />} />
                        <Route exact path="/company/:symbol/societe/:slug/edit" element={<CompanyEditPost />} />
                        <Route exact path="/company/:symbol/*" element={<Home />} />
                        <Route exact path="/collection/:collection" element={<Home />} />
                        <Route exact path="/settings" element={<UserSettings />} />
                        <Route exact path="/profile" element={<UserProfile />} />
                        <Route exact path="/profile/bookmarks" element={<Bookmarks />} />
                        <Route exact path="/profile/comments" element={<Comments />} />
                        <Route exact path="/profile/notifications" element={<Notifications />} />
                        <Route exact path="/profile/posts" element={<Posts />} />
                        <Route exact path="/societe" element={<Societe />} />
                        <Route exact path="/profile/upvotedposts" element={<Upvotes />} />
                        <Route exact path="/search" element={<Search />} />
                        <Route exact path="/search/:filterId" element={<Search />} />
                        <Route exact path="/guided-research" element={<GuidedResearch />} />
                        <Route exact path="/posts/:slug" element={<Post />} />
                    </Route>
                    <Route path="*" element={<RedirectHandler />} />
                </Routes>
            </Router>
        </Suspense>
    );
}
