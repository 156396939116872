import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import CompanyPages from '../../company-pages';
import { Link } from '../../../../components';
import { ReactSVG } from 'react-svg';
import './style.scss';
import { IoArrowBackOutline } from 'react-icons/io5';
import { pagesForJourneyStates } from '../company-page-handler';

export default function CompanySidebarLinks({ currentPage, symbol, researchData }) {
    // making this a variable because in the future it will be variable (uncomplete/complete)
    console.log('researchData', researchData);

    const getIcon = useCallback(
        (page) => {
            const status = researchData ? researchData[pagesForJourneyStates[page]] : undefined;
            if (status === 'completed') {
                return '../../../assets/images/icons/navigation-icons/sidebar-navigation-icons/done.svg';
            } else if (currentPage[0] === page) {
                return '../../../assets/images/icons/navigation-icons/sidebar-navigation-icons/current.svg';
            } else {
                return '../../../assets/images/icons/navigation-icons/sidebar-navigation-icons/inactive.svg';
            }
        },
        [researchData, currentPage]
    );

    return (
        <div className="flank-company-sidebar-links">
            {Object.keys(CompanyPages).map((page) => {
                if (page === 'create-post') {
                    return null;
                }
                const companySection = CompanyPages[page];
                const companySectionChildren = companySection.children || {};
                const hasCompanySectionChildren = Object.keys(companySectionChildren).length > 0;
                let disabled = false;
                if (!researchData && page === 'thesis') {
                    disabled = true;
                }
                return (
                    <div
                        className={['flank-company-page-links', currentPage[0] === page ? 'open' : '']
                            .filter(Boolean)
                            .join(' ')}
                        key={page}
                    >
                        {!disabled ? (
                            <Link
                                icon={<ReactSVG src={getIcon(page)} />}
                                pageTab={true}
                                className={['sidebar-company-nav-links', currentPage[0] === page ? 'active' : '']
                                    .filter(Boolean)
                                    .join(' ')}
                                to={`/${['company', symbol, page].filter(Boolean).join('/')}`}
                            >
                                {companySection.text}
                            </Link>
                        ) : (
                            <div
                                className={[
                                    'flank-link page-tab bold sidebar-company-nav-links',
                                    currentPage[0] === page ? 'active' : '',
                                ]
                                    .filter(Boolean)
                                    .join(' ')}
                            >
                                <span className="icon">{<ReactSVG src={getIcon(page)} />}</span>
                                {companySection.text}
                            </div>
                        )}

                        {hasCompanySectionChildren && (
                            <div className="flank-company-children-links">
                                {Object.keys(companySectionChildren).map((childPage) => {
                                    const childSection = companySection.children[childPage];

                                    return (
                                        <Link
                                            pageTabChild={true}
                                            key={childPage}
                                            className={[
                                                'sidebar-company-nav-links',
                                                currentPage[1] === childPage ? 'active' : '',
                                            ]
                                                .filter(Boolean)
                                                .join(' ')}
                                            to={`/${['company', symbol, page, childPage].filter(Boolean).join('/')}`}
                                        >
                                            {childSection.text}
                                            {currentPage[1] === childPage && <IoArrowBackOutline />}
                                        </Link>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

CompanySidebarLinks.propTypes = {
    currentPage: PropTypes.array.isRequired,
    symbol: PropTypes.string.isRequired,
    researchData: PropTypes.object,
};
