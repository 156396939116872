import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactSVG } from 'react-svg';

export default function LoadingModal({ block, text }) {
    const classes = ['flank-loading-modal'];
    if (block) {
        classes.push('block');
    }

    return (
        <div className={classes.join(' ')}>
            <ReactSVG className="spinner" src="../../../assets/images/spinner.svg" />
            <div className="snark">loading...</div>
            {text && <div className="message">{text}</div>}
        </div>
    );
}

LoadingModal.propTypes = {
    block: PropTypes.bool,
    text: PropTypes.string,
};
