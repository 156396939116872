import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCompany } from '../../../providers/company';
import {
    loadCompanyIncomeStatement,
    loadCompanyBalanceSheet,
    loadCompanyCashFlow,
    loadCompanyGovernance,
    loadCompanyStrategy,
} from '../services/company-data-svc';
import { Alert, LoadingModal, PageHeader, Popover } from '../../../components';
import ResearchButton from '../../../components/Button/special-buttons/researchButton';
import ComparisonChart from './comparison-chart';
import CompanySidebarLinks from './company-sidebar-links/company-sidebar-links';
import CompanyPages, { CompanyPage } from '../company-pages';
import mapCompanyDataValues from '../utils/data-mapper';
import ContentSection from './content-section/content-section';
import { camelCase, properCase } from '../../../utils/utils';
import { updateTitle, useTitle } from '../../../hooks/use-title';
import CompanyPageNavigationButtons from './page-navigation-button/page-navigation-buttons';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ONE_JOURNEY, START_JOURNEY, UPDATE_JOURNEY } from '../gql-data';
import invert from 'lodash/invert';
import './style.scss';

const MAX_BARS = 2;

export const journeyStatesForPages = {
    balanceSheetStatus: 'balance-sheet',
    cashFlowStatus: 'cash-flow',
    governanceStatus: 'governance',
    incomeStatementStatus: 'income-statement',
    overviewStatus: 'overview',
    strategyStatus: 'strategy',
    thesisStatus: 'thesis',
};

export const pagesForJourneyStates = invert(journeyStatesForPages);

export default function CompanyPageHandler({ setSidebarLinks }) {
    const navigate = useNavigate();
    const { '*': page } = useParams();
    const { state, dispatch } = useCompany();
    useTitle(`${state.company.name} (${state.company.displaySymbol})`);
    const [loadIncomeStatement, { loading: loadingIncomeStatement, error: incomeStatementError }] =
        loadCompanyIncomeStatement();
    const [loadBalanceSheet, { loading: loadingBalanceSheet, error: balanceSheetError }] = loadCompanyBalanceSheet();
    const [loadCashFlow, { loading: loadingCashFlow, error: cashFlowError }] = loadCompanyCashFlow();
    const [loadGovernance, { loading: loadingGovernance, error: governanceError }] = loadCompanyGovernance();
    const [loadStrategy, { loading: loadingStrategy, error: strategyError }] = loadCompanyStrategy();
    const [loadThesis, { loading: loadingThesis, error: thesisError }] = loadCompanyStrategy();
    const [researchData, setResearchData] = useState();

    const [startJourney] = useMutation(START_JOURNEY);
    useQuery(GET_ONE_JOURNEY, {
        variables: {
            filter: [
                {
                    field: 'companyId',
                    operator: 'eq',
                    value: state.company.id,
                },
            ],
        },
        onCompleted(data) {
            if (data.getOneUserJourneyForSelf) {
                setResearchData(data.getOneUserJourneyForSelf);
                dispatch({ type: 'setCurrentJourney', currentJourney: data.getOneUserJourneyForSelf });
            }
        },
    });

    const [updateJourney] = useMutation(UPDATE_JOURNEY);

    const updateCurrentPageFromUrl = () => {
        const routePage = page.split('/');
        const parent = CompanyPages[routePage[0]];
        const sub = parent?.children?.[routePage[1]];

        const currentPage = [];

        if (!parent) {
            navigate('/not-found');
            return;
        } else {
            currentPage.push(routePage[0]);
            if (routePage.length > 1) {
                if (!sub) {
                    navigate('/not-found');
                    return;
                } else {
                    currentPage.push(routePage[1]);
                }
            }
        }

        const pageTitle = currentPage.map((i) => properCase(i.replace(/-/g, ' '))).join(' - ');
        updateTitle(`${state.company.name} (${state.company.displaySymbol}) - ${pageTitle}`);
        setSidebarLinks(
            <CompanySidebarLinks currentPage={currentPage} symbol={state.symbol} researchData={researchData} />
        );
        dispatch({ type: 'setCurrentPage', currentPage });
    };

    function onStartResearch() {
        startJourney({
            variables: {
                companyId: state.company.id,
            },
        }).then(({ data }) => {
            setResearchData(data.startJourney);
            onUpdateJourney(data.startJourney);
            dispatch({ type: 'setCurrentJourney', currentJourney: data.startJourney });
        });
    }

    function onUpdateJourney(startJourneyData) {
        if (!researchData && !startJourneyData) {
            return;
        }
        const routePage = page.split('/');
        const parent = CompanyPages[routePage[0]];
        const journeyData = researchData || startJourneyData;
        updateJourney({
            variables: {
                data: {
                    balanceSheetStatus:
                        parent.key === journeyStatesForPages['balanceSheetStatus']
                            ? 'COMPLETED'
                            : journeyData.balanceSheetStatus.toUpperCase(),
                    cashFlowStatus:
                        parent.key === journeyStatesForPages['cashFlowStatus']
                            ? 'COMPLETED'
                            : journeyData.cashFlowStatus.toUpperCase(),
                    governanceStatus:
                        parent.key === journeyStatesForPages['governanceStatus']
                            ? 'COMPLETED'
                            : journeyData.governanceStatus.toUpperCase(),
                    incomeStatementStatus:
                        parent.key === journeyStatesForPages['incomeStatementStatus']
                            ? 'COMPLETED'
                            : journeyData.incomeStatementStatus.toUpperCase(),
                    overviewStatus:
                        parent.key === journeyStatesForPages['overviewStatus']
                            ? 'COMPLETED'
                            : journeyData.overviewStatus.toUpperCase(),
                    strategyStatus:
                        parent.key === journeyStatesForPages['strategyStatus']
                            ? 'COMPLETED'
                            : journeyData.strategyStatus.toUpperCase(),
                    thesisStatus: 'TODO',
                },
                journeyId: journeyData.id,
            },
        }).then(({ data }) => {
            setResearchData(data.updateJourneyProgress);
            dispatch({ type: 'setCurrentJourney', currentJourney: data.updateJourneyProgress });
        });
    }

    useEffect(() => {
        updateCurrentPageFromUrl();
        onUpdateJourney();
    }, [page]);

    useEffect(() => {
        updateCurrentPageFromUrl();
    }, [researchData]);

    useEffect(() => {
        if (!state.currentPage) {
            return;
        }

        if (state.currentPage[0] === CompanyPage.IncomeStatement) {
            loadIncomeStatement();
        } else if (state.currentPage[0] === CompanyPage.BalanceSheet) {
            loadBalanceSheet();
        } else if (state.currentPage[0] === CompanyPage.CashFlow) {
            loadCashFlow();
        } else if (state.currentPage[0] === CompanyPage.Governance) {
            loadGovernance();
        } else if (state.currentPage[0] === CompanyPage.Strategy) {
            loadStrategy();
        } else if (state.currentPage[0] === CompanyPage.Thesis) {
            loadThesis();
        }
    }, [state.currentPage[0]]);

    // shorthand function to pass to the comparison chart so it doesn't have to know about the dispatch
    const setFilterIds = (filterIds) => {
        dispatch({ type: 'setFilterIds', filterIds });
    };

    // define the content component here because within the page we do not want the header to update, only the content
    // having this as a variable here allows for that.
    // there may be *some* instances where we should truly wait until the data is loaded (like loading the initial company
    // data) but for now this is fine
    let contentComponent = null;

    // whenever we are loading data
    if (
        loadingIncomeStatement ||
        loadingBalanceSheet ||
        loadingCashFlow ||
        loadingGovernance ||
        loadingStrategy ||
        loadingThesis ||
        !state.currentPage
    ) {
        contentComponent = <LoadingModal block={true} text={'Loading...'} />;

        // whenever we have an error
    } else if (
        incomeStatementError ||
        balanceSheetError ||
        cashFlowError ||
        governanceError ||
        strategyError ||
        thesisError
    ) {
        contentComponent = (
            <Alert
                type="danger"
                text={
                    (
                        incomeStatementError ||
                        balanceSheetError ||
                        cashFlowError ||
                        governanceError ||
                        strategyError ||
                        thesisError
                    ).message
                }
            />
        );

        // otherwise we have at least the company data and no errors
    } else {
        const page = CompanyPages[state.currentPage[0]]; // current **parent** page that we are on

        if (!page) {
            navigate('/not-found');
        }
        if (!page.dataKeyFromState) {
            contentComponent = <page.component data={{}} />;
        } else {
            // initially load the data from the state that the **parent** page is looking for
            let data = state[page.dataKeyFromState];

            // define other variables from the page here that can possibly be overridden by the subpage
            let whatIsThis = page.whatIsThis;
            let whyIsThisImportant = page.whyIsThisImportant;

            // at this point we have the company data, but not necessarily the data that the current page
            // is asking for. so we will wait again until that comes in
            if (!data || Object.keys(data).length === 0) {
                contentComponent = <LoadingModal block={true} text={'Loading...'} />;

                // whenever we have alllllll the data that we need
            } else {
                let subPage = null;

                // whenever we are on a sub page (remember state.currentPage = [parentPage, subPage])
                if (state.currentPage.length > 1) {
                    subPage = page.children[state.currentPage[1]];

                    if (!subPage) {
                        navigate('/not-found');
                    }

                    // override all the variables that we defined above with the subpage's values
                    whatIsThis = subPage.whatIsThis;
                    whyIsThisImportant = subPage.whyIsThisImportant;

                    // if we need to do some data manipulation based on information coming from the subpage
                    // then we can get all that here
                    if (subPage.dataMapParameters) {
                        data = mapCompanyDataValues(
                            camelCase(state.currentPage[1]),
                            data,
                            [state.company.id],
                            state.companyColors,
                            subPage.dataMapParameters.baseColor,
                            subPage.dataMapParameters.valueModifier
                        );

                        // whenever we do not have to do data manipulation before continuing
                    } else if (data[state.company.id]) {
                        data = data[state.company.id][state.currentPage[1]];
                    }
                }

                // generate the content component stuff here because there is more than
                // one place where we need to do call this so DRY
                const generateContentComponent = () => (
                    <div className="flank-company-page">
                        {(subPage?.text || page?.text) && (
                            <PageHeader
                                text={subPage?.text || page?.text}
                                action={
                                    <Popover
                                        label={state.financialTimescale === 'Y' ? 'Yearly' : 'Quarterly'}
                                        openLeft={true}
                                        actions={[
                                            {
                                                type: 'button',
                                                label: 'Yearly',
                                                onClick: () => {
                                                    dispatch({
                                                        type: 'setFinancialTimescale',
                                                        financialTimescale: 'Y',
                                                    });
                                                },
                                            },
                                            {
                                                type: 'button',
                                                label: 'Quarterly',
                                                onClick: () => {
                                                    dispatch({
                                                        type: 'setFinancialTimescale',
                                                        financialTimescale: 'Q',
                                                    });
                                                },
                                            },
                                        ]}
                                    />
                                }
                                titleOnly={true}
                            />
                        )}
                        {data.chart && (
                            <ComparisonChart
                                type={state.filterIds.length <= MAX_BARS ? 'bar' : 'line'}
                                data={data}
                                filterIds={state.filterIds}
                                setFilterIds={setFilterIds}
                            />
                        )}
                        {whatIsThis && <ContentSection title="What is this?" {...whatIsThis} />}
                        {whyIsThisImportant && (
                            <ContentSection title="Why is this important?" {...whyIsThisImportant} />
                        )}
                        <CompanyPageNavigationButtons />
                    </div>
                );

                // whenever the subpage (e.g. income statement -> revenue) has an override component,
                // then let's render that instead of the generic cookie cutter stuff
                if (subPage && subPage.component) {
                    contentComponent = <subPage.component data={data} />;

                    // whenever there is no sub-page (e.g. income statement -> revenue), or it does not
                    // have an override component, but the parent page (e.g. income statement) does have one
                    // then let's render that instead of the generic cookie cutter stuff
                } else if (page.component) {
                    // however, sometimes we might actually want to render the generic cookie cutter stuff
                    // when a sub page is hit, so here we can specify that we will not allow an override
                    // component to be rendered based on the sub page
                    if (subPage) {
                        contentComponent = generateContentComponent();

                        // otherwise we will render the override component
                    } else {
                        contentComponent = <page.component data={data} />;
                    }
                } else {
                    // now we have all of our data, all of our variables, we can set the content
                    // by stitching it all together
                    contentComponent = generateContentComponent();
                }
            }
        }
    }

    return (
        <>
            <PageHeader
                text={state.company?.name}
                image={`${process.env.REACT_APP_LOGO_ENDPOINT}/${state.company.logoPath}`}
                allowBack={true}
                ticker={`$${state.company?.displaySymbol}`}
                action={
                    <>
                        <div className="btns">
                            <Link to={`/company/${state.company.displaySymbol}/societe`}>
                                <button className="view-posts-button">
                                    <img
                                        src="../../../assets/images/icons/navigation-icons/Union.svg "
                                        alt="view-posts-button"
                                    />
                                </button>
                            </Link>

                            <ResearchButton
                                type="submit"
                                text="Research"
                                onClick={onStartResearch}
                                showResearchButton={!researchData}
                                progress={researchData?.percentageComplete}
                                onCompleted={researchData?.status === 'completed'}
                            />
                        </div>
                    </>
                }
                pageTop={true}
            />

            {contentComponent}
        </>
    );
}

CompanyPageHandler.propTypes = {
    setSidebarLinks: PropTypes.func.isRequired,
};
