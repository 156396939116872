import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { LoadingModal, Alert, CustomForm, Button, ButtonChain } from '..';
import './style.scss';

export default function FunctionalCrudForm({
    loadingSubfields,
    defaultValues,
    children,
    mutation,
    deleteable,
    deleteMutation,
    mapDataBeforeDelete,
    onDeleted,
    onCreated,
    mapDataBeforeSubmit,
    buttonStates,
}) {
    const methods = useForm({ defaultValues });
    const deleteButtonRef = useRef();
    const [done, setDone] = useState('');
    const [error, setError] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [mutateItem] = useMutation(mutation, {
        onCompleted: (data) => {
            setButtonLoading('');
            if (onCreated(data)) {
                setDone('Saved');
            }
        },
        onError: (error) => {
            setDone('');
            setButtonLoading('');
            setError(error.message);
        },
    });

    const [deleteItemMutation] = useMutation(deleteMutation, {
        onCompleted: (data) => {
            setButtonLoading('');
            if (onDeleted(data)) {
                setDone('Deleted');
            }
        },
    });

    const unConfirmDelete = (e) => {
        if (deleteButtonRef.current) {
            if (e.target !== deleteButtonRef.current && !deleteButtonRef.current.contains(e.target)) {
                setConfirmDelete(false);
            }
        }
    };

    useEffect(() => {
        setDone('');
        setError(null);

        document.addEventListener('click', unConfirmDelete);
        return () => {
            document.removeEventListener('click', unConfirmDelete);
        };
    }, []);

    const handleSubmit = (data) => {
        setError(null);
        setDone('');
        setButtonLoading(buttonStates.loading);
        mutateItem({
            variables: mapDataBeforeSubmit(data),
        }).catch((err) => {
            setButtonLoading('');
            setError(err.message);
        });
    };

    const handleError = (error) => {
        setDone('');
        setButtonLoading('');
        setError(error[Object.keys(error)[0]].message);
    };

    const handleReset = () => {
        setDone('');
        setButtonLoading('');
        setError(null);
        methods.reset(defaultValues);
    };

    const handleDelete = (confirmed) => {
        if (!confirmed && deleteButtonRef.current) {
            setConfirmDelete(true);
        } else {
            setDone('');
            setButtonLoading('');
            setError(null);
            deleteItemMutation({
                variables: mapDataBeforeDelete(),
            }).catch((err) => {
                setButtonLoading('');
                setError(err.message);
            });
        }
    };

    if (loadingSubfields) {
        return <LoadingModal block />;
    }

    return (
        <>
            {error && <Alert text={error} type="danger" />}
            {done && <Alert text={done} type="success" />}
            {
                <CustomForm methods={methods} onSubmit={handleSubmit} onError={handleError}>
                    {children}

                    <ButtonChain>
                        <Button
                            type="submit"
                            text={buttonStates.normal}
                            loading={buttonLoading}
                            disabled={!!buttonLoading}
                            action
                        />
                        <Button type="button" text="Reset Values" onClick={handleReset} />
                        {deleteable && (
                            <Button
                                ref={deleteButtonRef}
                                type="button"
                                text={confirmDelete ? 'Click again to confirm delete' : 'Delete'}
                                status={confirmDelete ? 'danger' : ''}
                                onClick={confirmDelete ? () => handleDelete(true) : () => handleDelete(false)}
                            />
                        )}
                    </ButtonChain>
                </CustomForm>
            }
        </>
    );
}

FunctionalCrudForm.propTypes = {
    loadingSubfields: PropTypes.bool,
    defaultValues: PropTypes.object,
    children: PropTypes.node,
    mutation: PropTypes.object,
    deleteable: PropTypes.bool,
    deleteMutation: PropTypes.object,
    mapDataBeforeDelete: PropTypes.func,
    onDeleted: PropTypes.func,
    onCreated: PropTypes.func,
    mapDataBeforeSubmit: PropTypes.func,
    buttonStates: PropTypes.object,
};
