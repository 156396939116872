import { toFixed, formatNumber, percentChange } from '../../../utils/numbers';

function getLabels(obj, labels) {
    if (labels.length === 0) {
        obj.forEach(({ period }) => {
            labels.push(period);
        });
    }

    return labels;
}

export default function mapCompanyDataValues(key, data, unremovableIds, companyColors, baseColor, valueModifier) {
    let baseColors = { green: '#95E4AA', red: '#E37D80' };
    let labels = [];
    const datasets = [];
    const tabledata = [];

    data.forEach(({ company, ...rest }) => {
        const color = data.length === 1 ? baseColors[baseColor] : companyColors[company.id];
        labels = getLabels(rest[key], labels);

        datasets.push({
            label: company.name,
            data: rest[key].map(({ value }) => value),
            backgroundColor: rest[key].map(({ value }) =>
                data.length === 1 ? (value < 0 ? baseColors.red : baseColors.green) : color
            ),
            borderColor: color,
            barPercentage: 1,
            categoryPercentage: 0.8,
        });
        tabledata.push({
            id: company.id,
            color,
            symbol: company.displaySymbol,
            icon: company.logoPath,
            companyName: company.name,
            values: rest[key].map(({ value }, index) => {
                const currentValue = value;
                const previousValue = rest[key][index - 1]?.value;
                const firstValue = rest[key][0]?.value;
                const lastValue = rest[key][rest[key].length - 1]?.value;

                return {
                    value: currentValue ? formatNumber(currentValue, valueModifier) : '-',
                    change:
                        currentValue && previousValue
                            ? toFixed(percentChange(previousValue, currentValue, true), 1)
                            : null,
                    cumulativeChange: firstValue && lastValue ? toFixed(percentChange(firstValue, lastValue), 2) : null,
                };
            }),
            deleteable: unremovableIds.indexOf(company.id) === -1,
        });
    });

    return {
        chart: {
            labels,
            datasets,
        },
        table: tabledata,
        headers: labels,
    };
}

export function nullOnlyValues(data) {
    return data.every(({ value }) => value === null);
}
