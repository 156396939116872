import React, { useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SEARCH } from '../../gql-data';
import './style.scss';
import { Form, FormGroup, TextInput } from '../../components';
import { search, searchQueryCompleted } from '../Search/search-svc';
import { SearchResults } from '../Search/search-results';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { GET_SYMBOL_TYPEAHEAD } from '../../gql-data';
import PropTypes from 'prop-types';

export const SEARCH_METHOD = {
    FILTERS: 'filters',
    SEARCH_BOX: 'search-box',
};

export default function searchBar() {
    const navigate = useNavigate();
    const searchFormRef = useRef(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchDirty, setSearchDirty] = useState(false);
    const [typeaheadLoading, setTypeaheadLoading] = useState(false);
    const [typeaheadOptions, setTypeaheadOptions] = useState([]);

    const [lookupSymbols] = useLazyQuery(GET_SYMBOL_TYPEAHEAD, {
        onCompleted: (data) => {
            setTypeaheadLoading(false);
            if (data.getAllCompanies.rows.length > 0) {
                setTypeaheadOptions(
                    data.getAllCompanies.rows.reduce((acc, company) => {
                        acc.push({
                            value: company.displaySymbol,
                            label: company.displaySymbol,
                            labelName: company.name || null,
                        });
                        return acc;
                    }, [])
                );
            }
        },
        onError: (error) => {
            setTypeaheadLoading(false);
            console.log(error);
        },
    });

    const handleSearch = (data, typeaheadSelected) => {
        if (typeaheadSelected === true) {
            navigate(`/company/${data.companyName}/overview`);
        } else if (data.search.length <= 4) {
            navigate(`/company/${data.search}/overview`);
        }
    };

    const typeaheadOnSelect = (value) => {
        setTypeaheadOptions([]);
        handleSearch({ companyName: value }, true);
    };

    const typeaheadOnChange = (value) => {
        if (value === '') {
            setTypeaheadOptions([]);
        } else {
            setTypeaheadLoading(true);
            const val = value.trim();
            const filterValue = `${val}%`;
            const filters = [
                {
                    join: 'and',
                    values: [
                        {
                            field: 'name',
                            operator: 'ne',
                            value: '',
                        },
                        {
                            join: 'or',
                            values: [
                                {
                                    join: 'or',
                                    field: 'displaySymbol',
                                    values: [
                                        {
                                            operator: 'eq',
                                            value: val,
                                        },
                                        {
                                            operator: 'like',
                                            value: filterValue,
                                        },
                                    ],
                                },
                                {
                                    join: 'or',
                                    field: 'name',
                                    values: [
                                        {
                                            operator: 'eq',
                                            value: val,
                                        },
                                        {
                                            operator: 'like',
                                            value: filterValue,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ];

            lookupSymbols({
                variables: {
                    limit: 5,
                    filters,
                },
            });
        }
    };

    const setSearchResultsAndDirty = (results) => {
        setSearchResults(results);
        setSearchDirty(true);
    };

    const [runSearchQuery, { loading }] = useLazyQuery(SEARCH, {
        fetchPolicy: 'network-only',
        onCompleted: searchQueryCompleted(setSearchResultsAndDirty),
    });

    const handleSearchBoxSubmit = (data) => {
        setSearchResults([]);
        search(
            {
                filters: [
                    {
                        join: 'or',
                        values: [
                            {
                                field: 'name',
                                operator: 'like',
                                value: `${data.searchbox}%`,
                            },
                            {
                                field: 'displaySymbol',
                                operator: 'like',
                                value: `${data.searchbox}%`,
                            },
                        ],
                    },
                    {
                        field: 'name',
                        operator: 'isNotNull',
                    },
                    {
                        field: 'parentId',
                        operator: 'isNull',
                    },
                ],
                orderBy: [{ field: 'id', direction: 'DESC' }],
            },
            runSearchQuery
        );
    };

    console.log(handleSearchBoxSubmit);

    const handleSearchError = (error) => {
        console.log(error);
    };

    return (
        <div>
            <div className="search-home">
                <Form
                    ref={searchFormRef}
                    onSubmit={handleSearchBoxSubmit}
                    onError={handleSearchError}
                    className="search-form"
                >
                    <FormGroup className="input-icon-wrapper">
                        <ReactSVG
                            className="search-icon"
                            src="/assets/images/icons/navigation-icons/search.svg"
                        ></ReactSVG>
                        <TextInput
                            className="search-bar"
                            typeahead={true}
                            typeaheadOnSelect={typeaheadOnSelect}
                            typeaheadOnChange={typeaheadOnChange}
                            typeaheadOptions={typeaheadOptions}
                            typeaheadLoading={typeaheadLoading}
                            typeaheadMinLength={2}
                            onBlur={() => setTypeaheadOptions([])}
                            type="text"
                            name="searchbox"
                            id="searchbox"
                            placeholder="
                        Company Search"
                            autocomplete="off"
                        />
                    </FormGroup>
                </Form>
            </div>

            <SearchResults searchResults={searchResults} dirty={searchDirty} loading={!searchDirty && loading} />
        </div>
    );
}

searchBar.propTypes = {
    filtersOnAdd: PropTypes.func,
};
