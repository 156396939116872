import React from 'react';
import { useTitle } from '../../hooks/use-title';
import { useSidebar } from '../../providers/sidebar';
import { BaseTemplate } from '../../templates';
import { Sidebar } from '../../components';
import { memo, useEffect, useRef, useState } from 'react';
import CompanyPage from '../Company';
import { useLocation } from 'react-router-dom';
import { useFooter } from '../../providers/template';
import './style.scss';
import GuidedResearch from '../GuidedResearch';
import { useQuery } from '@apollo/client';
import { GET_ALL_JOURNEYS, GET_ALL_THESISES } from '../Company/gql-data';

function Home() {
    useFooter(true);
    const { dispatch: sidebarDispatch } = useSidebar();
    useTitle(null);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sidebarLinks, setSidebarLinks] = useState(null);
    const location = useLocation();
    const [type, setType] = useState('collection');
    const [inProgress, setInProgress] = useState([]);

    const sidebar = useRef(<Sidebar />);

    let pageHeaderProps = null;

    useEffect(() => {
        sidebarDispatch({
            type: 'setOpenIds',
            openIds: company?.collections.map((i) => i.id),
        });

        sidebarDispatch({
            type: 'setCurrentId',
            currentId: company?.id,
        });
    }, [company, sidebarDispatch]);

    useEffect(() => {
        const locationParts = location.pathname.split('/');
        if (locationParts[1] === 'company') {
            setType('company');
        } else {
            setType('collection');
        }
    }, [location.pathname]);

    const { data: watchlist } = useQuery(GET_ALL_THESISES, {
        variables: {
            filters: [
                {
                    field: 'tracking',
                    operator: 'eq',
                    value: 'watchlist',
                },
            ],
            groupBy: ['company.id'],
        },
    });

    useQuery(GET_ALL_JOURNEYS, {
        onCompleted: (res) => {
            const rows = res.getAllUserJourneiesForSelf.rows;
            setInProgress(rows.filter((row) => row.status === 'in-progress'));
        },
    });

    const hasWatchlist = watchlist?.getAllUserCompanyThesisesForSelf?.rows?.length > 0;
    const hasInProgress = inProgress.length > 0;
    const hasStartedJourneys = hasWatchlist || hasInProgress;

    if (!hasStartedJourneys) {
        return (
            <BaseTemplate
                loading={loading}
                pageHeaderProps={pageHeaderProps}
                sidebar={
                    <>
                        {sidebarLinks ? <div>{sidebarLinks}</div> : null}
                        {sidebar.current}
                    </>
                }
            >
                {type === 'collection' ? (
                    <>
                        <GuidedResearch />
                    </>
                ) : (
                    <CompanyPage setCompany={setCompany} setLoading={setLoading} setSidebarLinks={setSidebarLinks} />
                )}
            </BaseTemplate>
        );
    } else {
        return (
            <>
                {type === 'collection' ? (
                    <>
                        <BaseTemplate
                            sidebar={
                                <>
                                    {sidebarLinks ? <div>{sidebarLinks}</div> : null}
                                    {sidebar.current}
                                </>
                            }
                        >
                            <GuidedResearch />
                        </BaseTemplate>
                    </>
                ) : (
                    <BaseTemplate
                        loading={loading}
                        pageHeaderProps={pageHeaderProps}
                        sidebar={
                            <>
                                {sidebarLinks ? <div>{sidebarLinks}</div> : null}
                                {sidebar.current}
                            </>
                        }
                    >
                        <CompanyPage
                            setCompany={setCompany}
                            setLoading={setLoading}
                            setSidebarLinks={setSidebarLinks}
                        />
                    </BaseTemplate>
                )}
            </>
        );
    }
}

export default memo(Home);
