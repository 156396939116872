import React from 'react';
import PropTypes from 'prop-types';
import { useCompany } from '../../../../providers/company';
import ContentSection from '../content-section/content-section';
import FinancialTable from '../financial-table/financial-table';
import { Popover, PageHeader } from '../../../../components';
import './style.scss';
import CompanyPageNavigationButtons from '../page-navigation-button/page-navigation-buttons';
import { IoCreateOutline } from 'react-icons/io5';
import SectionHeader from '../../../../components/SectionHeader';

export default function CompanyIncomeStatement({ data }) {
    const { state, dispatch } = useCompany();

    const whatIsThis = {
        tldr: {
            importance: 3,
            text: 'Company&#39;s rise and fall based on their earnings. The Income Statement tracks earnings starting from revenue and subtracting out expenses to get to earnings. It gives you insight into a company&#39;s <strong>growth, income, margins, expenses, and financial health.</strong>',
        },
        text: `<p>The income statement, also known as the profit and loss statement or statement of operations, is one of the key financial statements that companies produce. It provides a summary of a company&#39;s financial performance over a specific period, such as a quarter or a year.</p>
            <p>The income statement is designed to give you an understanding of how well a company is generating revenue, controlling expenses, and ultimately turning a profit. It&#39;s essential for investors to analyze the income statement, as it helps in making informed decisions about the company's potential for future growth and profitability.

            The income statement flows from top to bottom: revenue down to net income. Familiarize yourself with each line item, compare historical performance and look for trends in revenue growth, expense management, healthy margins, and overall profitability`,
    };

    const whyIsThisImportant = {
        text: `Analyzing the income statement is like peering into the engine of a company, revealing how well it operates and how sustainably it generates profits.
        <p>The income statement offers a view of a company's ability to grow its top-line revenues while prudently managing expenses, which directly affects the bottom line.</p>
        <p>By studying this crucial financial document, investors can identify durable competitive advantages, or what I like to call "economic moats," that can protect a company from competitors and ensure long-term profitability.</p>`,
    };

    return (
        <div className="company-income-statement">
            <div className="company-page-header">
                <PageHeader
                    text={'Income Statement'}
                    action={
                        <Popover
                            label={state.financialTimescale === 'Y' ? 'Yearly' : 'Quarterly'}
                            openLeft={true}
                            actions={[
                                {
                                    type: 'button',
                                    label: 'Yearly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Y' });
                                    },
                                },
                                {
                                    type: 'button',
                                    label: 'Quarterly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Q' });
                                    },
                                },
                            ]}
                        />
                    }
                    titleOnly={true}
                />{' '}
            </div>
            <FinancialTable data={data} financialTimescale={state.financialTimescale} />
            {/* {state.company.name} income statement */}
            <div className="content-section">
                <ContentSection title="What is this?" {...whatIsThis} />
                <ContentSection title="Why is this important?" {...whyIsThisImportant} />
            </div>
            <SectionHeader
                text="The Societe"
                Icon={IoCreateOutline}
                iconLink={`/company/${state.company.displaySymbol}/create-post`}
                linkState={{ origin: 'SectionHeader', topLevelResource: 'overview' }}
            />
            <CompanyPageNavigationButtons />
        </div>
    );
}

CompanyIncomeStatement.propTypes = {
    data: PropTypes.array,
    financialTimescale: PropTypes.string,
};
