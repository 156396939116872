import React from 'react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import './file-input.scss';

const FileInput = forwardRef(function FileInput({ register, name, label, value, actualValue }, ref) {
    let registered = null;

    if (register) {
        registered = register(name, {
            value: value,
        });
    }

    return (
        <div className="flank-form-group file-input">
            <img
                src={`${process.env.REACT_APP_ASSETS_ENDPOINT}/${actualValue}`}
                alt={label}
                style={{ width: '100px' }}
            />
            <div>
                <label className="flank-form-label" htmlFor={name}>
                    {label}
                </label>
                <input
                    {...registered}
                    ref={(e) => {
                        if (ref) ref.current = e;
                        registered?.ref(e);
                    }}
                    type="file"
                    name={name}
                />
            </div>
        </div>
    );
});

FileInput.propTypes = {
    componentType: PropTypes.string,
    register: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    actualValue: PropTypes.string,
};

FileInput.defaultProps = {
    componentType: 'FileInput',
};

export default FileInput;
