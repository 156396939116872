import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { merge } from 'lodash';
import PropTypes from 'prop-types';

export default function LineChart({ data, options, setChartDateSelected, setToolTipActive }) {
    ChartJS.register({
        id: 'tooltipLine',
        afterDatasetsDraw: function (chart) {
            var ctx = chart.ctx;
            var meta = chart.getDatasetMeta(0);
            if (meta.type === 'line') {
                if (chart.tooltip && chart.tooltip._active && chart.tooltip._active.length) {
                    var activePoint = chart.tooltip._active[0];
                    var x = activePoint.element.x;
                    var topY = chart.scales.y._startPixel;
                    var bottomY = chart.scales.y._endPixel;
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 1;
                    ctx.strokeStyle = '#ddd';
                    ctx.stroke();
                }
            }
        },
        afterEvent: function (chart, event) {
            if (event.event.type === 'mouseout' || event.event.type === 'mouseup') {
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (tooltipEl) {
                    tooltipEl.style.opacity = 0;
                    chart.update();
                }
            }
        },
    });

    return (
        <Line
            className="flank-line-chart"
            options={merge(options, {
                plugins: {
                    tooltip: {
                        // custom tooltip
                        external: (context) => {
                            let dateSelected = context.tooltip.dataPoints[0].label;
                            let tooltipEl = document.getElementById('chartjs-tooltip');
                            if (setChartDateSelected) {
                                setChartDateSelected(dateSelected);
                            }

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            const tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                if (setToolTipActive) {
                                    setToolTipActive(false);
                                }
                                tooltipEl.style.opacity = 0;
                                return;
                            } else {
                                if (setToolTipActive) {
                                    setToolTipActive(true);
                                }
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            if (tooltipModel.body) {
                                const bodyLines = tooltipModel.body.map((body) => {
                                    return body.lines;
                                });

                                let innerHtml = '<tbody>';

                                bodyLines.forEach(function (body) {
                                    innerHtml += '<tr><td>' + body + '</td></tr>';
                                });
                                innerHtml += '</tbody>';

                                let tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            const position = context.chart.canvas.getBoundingClientRect();
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left =
                                position.left +
                                window.pageXOffset +
                                tooltipModel.caretX -
                                tooltipEl.offsetWidth / 2 +
                                'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + 'px';
                            tooltipEl.style.padding = '.5em 1em';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.border = '1px solid #ccc';
                            tooltipEl.style.borderRadius = '3px';
                            tooltipEl.style.backgroundColor = '#fff';
                            tooltipEl.style.fontSize = '0.8em';
                            tooltipEl.style.fontFamily = window
                                .getComputedStyle(document.body, null)
                                .getPropertyValue('font-family');
                            tooltipEl.style.textAlign = 'center';
                        },
                    },
                },
            })}
            data={data}
        />
    );
}

LineChart.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object,
    setChartDateSelected: PropTypes.func,
    setToolTipActive: PropTypes.func,
};
