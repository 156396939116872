import React from 'react';
import PropTypes from 'prop-types';
import { BiExport, BiTrash } from 'react-icons/bi';
import './style.scss';
import Button from '../Button';

export default function MyFilters({ show, data, onLoad, onDelete }) {
    if (!show) {
        return;
    }
    return (
        <div className="my-saved-filters-container">
            {data.map((filter) => (
                <div className="filter-container" key={filter.id}>
                    <span>{filter.name}</span>
                    <div className="buttons-container">
                        <Button icon={<BiExport />} skinny plain onClick={() => onLoad(filter.id)} />
                        <Button
                            icon={<BiTrash />}
                            skinny
                            plain
                            onClick={() => onDelete(filter.id)}
                            className="delete-btn"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

MyFilters.propTypes = {
    show: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    onLoad: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};
