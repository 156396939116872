import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Table, LoadingModal, Alert } from '..';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { IoPencilOutline, IoTrashOutline } from 'react-icons/io5';

export default function FunctionalListing({
    tableActions,
    getQuery,
    getDataKey,
    mapDataToTable,
    filters,
    deleteMutation,
    confirmDeleteMessage,
    deletedMessage,
    loadingMessage,
    onEdit,
    tableHeaderMap,
}) {
    const [data, setData] = useState([]);
    const [itemDeleted, setItemDeleted] = useState(false);
    const [error, setError] = useState('');
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        limit: 10,
        totalPages: 0,
        orderBy: [],
    });

    const [getData, { error: dataError, loading: loadingData }] = useLazyQuery(getQuery, {
        variables: {
            filters,
            limit: pagination.limit,
            page: pagination.current,
            orderBy: pagination.orderBy,
        },
        onCompleted: (data) => {
            setData(mapDataToTable(data));
            setPagination({
                ...pagination,
                total: data[getDataKey].total,
                totalPages: data[getDataKey].totalPages,
                limit: data[getDataKey].limit,
            });
        },
    });

    const [deleteItemMutation] = useMutation(
        deleteMutation ||
            gql`
                mutation {
                    a
                }
            `,
        {
            onCompleted: () => {
                setItemDeleted(true);
                setTimeout(() => {
                    setItemDeleted(false);
                }, 2000);
            },
            onError: (error) => {
                setItemDeleted(false);
                setError(error.message);
            },
        }
    );

    useEffect(() => {
        getData({
            variables: {
                filters,
                limit: pagination.limit,
                page: 1,
                orderBy: pagination.orderBy,
            },
        });
    }, [filters, getData, pagination.limit, pagination.orderBy]);

    const deleteItem = (item) => {
        setError('');
        const confirm = window.confirm(confirmDeleteMessage(item) || 'Are you sure?');
        if (confirm) {
            deleteItemMutation({
                variables: {
                    id: item.id,
                },
            }).then(() => {
                getData({
                    variables: {
                        filters,
                        limit: pagination.limit,
                        page: pagination.current,
                        orderBy: pagination.orderBy,
                    },
                });
            });
        }
    };

    const onPageChange = (page) => {
        setPagination({
            ...pagination,
            current: page,
        });
        getData({
            variables: {
                filters,
                limit: pagination.limit,
                page: page,
                orderBy: pagination.orderBy,
            },
        });
    };

    const onLimitChange = (limit) => {
        setPagination({
            ...pagination,
            limit: limit,
        });
        getData({
            variables: {
                filters,
                limit: limit,
                page: 1,
                orderBy: pagination.orderBy,
            },
        });
    };

    const onOrderChange = (order) => {
        setPagination({
            ...pagination,
            orderBy: order,
        });
        getData({
            variables: {
                filters,
                limit: pagination.limit,
                page: 1,
                orderBy: order,
            },
        });
    };

    useEffect(() => {
        if (dataError && setData) {
            setData([]);
        }
    }, [dataError, setData]);

    return (
        <>
            {error && <Alert type="error" text={error} />}
            {itemDeleted && <Alert type="success" text={deletedMessage || 'Item deleted'} />}
            <Table
                tableActions={tableActions}
                pagination={pagination}
                onPageChange={onPageChange}
                onLimitChange={onLimitChange}
                onOrderChange={onOrderChange}
                isLoading={loadingData}
                loadingModal={<LoadingModal text={loadingMessage || 'Loading'} block />}
                details={{
                    headerMap: tableHeaderMap,
                    key: (i) => i.id,
                }}
                actions={[
                    {
                        type: 'link',
                        icon: <IoPencilOutline />,
                        label: 'Edit',
                        to: onEdit ? onEdit : () => '',
                    },
                    {
                        icon: <IoTrashOutline />,
                        label: 'Delete',
                        onClick: (item) => {
                            deleteItem(item);
                        },
                    },
                ].filter(
                    (i) => (i.label === 'Edit' && onEdit !== null) || (i.label === 'Delete' && deleteMutation !== null)
                )}
                data={data}
            />
        </>
    );
}

FunctionalListing.propTypes = {
    tableActions: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['link', 'button']),
            icon: PropTypes.element,
            label: PropTypes.string,
            to: PropTypes.func,
            onClick: PropTypes.func,
        })
    ),
    getQuery: PropTypes.object.isRequired,
    getDataKey: PropTypes.string.isRequired,
    mapDataToTable: PropTypes.func.isRequired,
    filters: PropTypes.object,
    deleteMutation: PropTypes.object,
    confirmDeleteMessage: PropTypes.func,
    deletedMessage: PropTypes.string,
    loadingMessage: PropTypes.string,
    onEdit: PropTypes.func,
    tableHeaderMap: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            sortable: PropTypes.bool,
        })
    ),
};
