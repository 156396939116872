export function abbreviateDollars(number, decPlaces) {
    let isNegative = number < 0;
    number = Math.abs(number);
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ['K', 'M', 'B', 'T'];

    for (var i = abbrev.length - 1; i >= 0; i--) {
        var size = Math.pow(10, (i + 1) * 3);
        if (size <= number) {
            number = Math.round((number * decPlaces) / size) / decPlaces;
            if (number === 1000 && i < abbrev.length - 1) {
                number = 1;
                i++;
            }

            if (isNegative) {
                number = '-' + number;
            }

            number += abbrev[i];
            break;
        }
    }

    return number;
}

export function toFixed(number, decPlaces, removeZeros = false) {
    if (!number) {
        return 0;
    }
    if (removeZeros) {
        return parseFloat(number.toFixed(decPlaces)).toString();
    } else {
        return number.toFixed(decPlaces);
    }
}

export function formatNumber(value, valueModifier) {
    let formattedValue = 0;
    if (!valueModifier || valueModifier.length === 0) {
        formattedValue = value;
    } else if (valueModifier[0] === 'default') {
        formattedValue = value;
    } else if (valueModifier[0] === 'ratio') {
        formattedValue = toFixed(value, 2);
    } else if (valueModifier[0] === 'dollar') {
        if (valueModifier[1] === 'default') {
            formattedValue = `$${abbreviateDollars(value, 2)}`;
        } else if (valueModifier[1] === 'multiply') {
            formattedValue = `$${abbreviateDollars(value * valueModifier[2], 2)}`;
        }
    } else if (valueModifier[0] === 'percentage') {
        if (valueModifier[1] === 'default') {
            formattedValue = toFixed(value) + '%';
        } else if (valueModifier[1] === 'multiply') {
            formattedValue = toFixed(value * valueModifier[2], 1) + '%';
        }
    }

    return formattedValue;
}

export function percentChange(oldValue, newValue, wholeNumber) {
    if (oldValue === 0) {
        return 0;
    }
    if (newValue === 0) {
        return 0;
    }

    const change = (newValue - oldValue) / Math.abs(oldValue);

    if (wholeNumber) {
        return change * 100;
    }
    return change;
}
