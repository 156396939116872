import React from 'react';
import PropTypes from 'prop-types';
import { useCompany } from '../../../../providers/company';
import ContentSection from '../content-section/content-section';
import BalanceSheetTable from '../balance-sheet-table';
import { Popover, PageHeader } from '../../../../components';
import { IoCreateOutline } from 'react-icons/io5';
import SectionHeader from '../../../../components/SectionHeader';

import './style.scss';
import CompanyPageNavigationButtons from '../page-navigation-button/page-navigation-buttons';

export default function CompanyBalanceSheet({ data }) {
    const { state, dispatch } = useCompany();

    const whatIsThis = {
        tldr: {
            importance: 3,
            text: 'A balance sheet is a snapshot of a company&#39;s financial situation at a specific point in time, showing what it owns (assets), owes (liabilities), and the net worth attributable to the shareholders (equity). It&#39;s crucial for understanding a company&#39;s financial health, including its liquidity, solvency, and operational efficiency.',
        },
        text: 'A balance sheet is a financial statement that provides a snapshot of what a company owns (assets) and owes (liabilities), as well as the amount invested by shareholders (equity), at a specific point in time. The balance sheet adheres to the following equation: Assets = Liabilities + Shareholders&#39; Equity This equation illustrates that the resources of a company (assets) are financed by either creditors (liabilities) or shareholders (equity).',
    };
    const whyIsThisImportant = {
        text: 'The balance sheet is crucial because it provides information to stakeholders about the company&#39;s financial health at a specific moment in time. Assets: The assets show what resources the company currently has at its disposal that can be used to generate future revenue. Liabilities: Liabilities indicate what the company owes, both in the short term and the long term. This includes obligations like loans, accounts payable, and long-term debt. Shareholders&#39; Equity: Also known as "net assets" or "owner&#39;s equity," this represents the portion of assets that the shareholders officially own, calculated by subtracting total liabilities from total assets. Analyzing a balance sheet can provide valuable insights about a company&#39;s liquidity, solvency, and the efficiency with which it uses its resources. Comparing balance sheets over time can help identify trends and signal potential issues or opportunities.',
    };

    return (
        <div className="company-balance-sheet">
            <div className="company-page-header">
                <PageHeader
                    text={'Balance Sheet'}
                    action={
                        <Popover
                            label={state.financialTimescale === 'Y' ? 'Yearly' : 'Quarterly'}
                            openLeft={true}
                            actions={[
                                {
                                    type: 'button',
                                    label: 'Yearly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Y' });
                                    },
                                },
                                {
                                    type: 'button',
                                    label: 'Quarterly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Q' });
                                    },
                                },
                            ]}
                        />
                    }
                    titleOnly={true}
                />
            </div>
            <BalanceSheetTable data={data} />
            {/* {state.company.name} income statement */}
            <div className="content-section">
                <ContentSection title="What is this?" {...whatIsThis} />
                <ContentSection title="Why is this important?" {...whyIsThisImportant} />
            </div>
            <SectionHeader
                text="The Societe"
                Icon={IoCreateOutline}
                iconLink={`/company/${state.company.displaySymbol}/create-post`}
                linkState={{ origin: 'SectionHeader', topLevelResource: 'overview' }}
            />
            <CompanyPageNavigationButtons />
        </div>
    );
}

CompanyBalanceSheet.propTypes = {
    data: PropTypes.array.isRequired,
};
