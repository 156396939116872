import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Link from '../Link';

export default function SectionHeader({ text, iconLink, iconSize, linkState, Icon }) {
    return (
        <div className="section-header-wrapper">
            <span className="section-header-text">{text}</span>
            {Icon && (
                <span className="section-header-icon">
                    <Link to={{ pathname: iconLink, state: linkState }}>
                        <Icon size={iconSize || 32} />
                    </Link>
                </span>
            )}
        </div>
    );
}

SectionHeader.propTypes = {
    text: PropTypes.string.isRequired,
    Icon: PropTypes.elementType,
    iconSize: PropTypes.number,
    iconLink: PropTypes.string,
    linkState: PropTypes.object,
};
