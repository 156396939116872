import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '../';
import './style.scss';

export function TypeaheadOption({ value, label, labelName, onSelect, setTypeaheadOpen, active, setInputValue }) {
    const classes = [];
    if (active) {
        classes.push('active');
    }

    const handleSelect = () => {
        if (onSelect && typeof onSelect === 'function') {
            onSelect(value, label);
            setInputValue(label);
            setTypeaheadOpen(false);
        }
    };

    return (
        <li className={`search-results ${classes.join(' ')}`}>
            <button type="button" onClick={handleSelect} onTouchEnd={handleSelect}>
                <div className="button-text">
                    <div className="company-symbol">{label}</div>
                    <div className="company-name">{labelName}</div>
                </div>
            </button>
        </li>
    );
}

const TypeaheadBox = ({
    typeaheadLoading,
    typeaheadOnSelect,
    setTypeaheadOpen,
    inputValue,
    setInputValue,
    typeaheadOptions,
    offScreen,
    setTypeaheadOffscreen,
}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            const typeaheadBox = ref.current;
            const typeaheadBoxRect = typeaheadBox.getBoundingClientRect();
            setTypeaheadOffscreen(Math.round(typeaheadBoxRect.bottom) >= window.innerHeight);
        }
    }, [setTypeaheadOffscreen]);

    if (typeaheadLoading) {
        return (
            <div className="flank-typeahead">
                <Alert type="spinner" text="Loading..." noStyle />
            </div>
        );
    }

    const classes = ['flank-typeahead'];
    if (offScreen) {
        classes.push('off-screen');
    }

    return (
        <div ref={ref} className={classes.join(' ')}>
            <ul>
                {(typeaheadOptions || []).map((option) => (
                    <TypeaheadOption
                        onSelect={typeaheadOnSelect}
                        setTypeaheadOpen={setTypeaheadOpen}
                        active={inputValue === option.label}
                        key={option.value}
                        setInputValue={setInputValue}
                        {...option}
                    />
                ))}
            </ul>
        </div>
    );
};

TypeaheadOption.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    labelName: PropTypes.string,
    onSelect: PropTypes.func,
    setTypeaheadOpen: PropTypes.func,
    active: PropTypes.bool,
    setInputValue: PropTypes.func,
};

TypeaheadBox.propTypes = {
    typeaheadLoading: PropTypes.bool,
    typeaheadOnSelect: PropTypes.func,
    setTypeaheadOpen: PropTypes.func,
    inputValue: PropTypes.string,
    setInputValue: PropTypes.func,
    typeaheadOptions: PropTypes.array,
    offScreen: PropTypes.bool,
    setTypeaheadOffscreen: PropTypes.func,
};

export { TypeaheadBox };
