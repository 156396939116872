import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export default function createProvider({ reducer = {}, initialState = {} } = { reducer: {}, initialState: {} }) {
    const Context = createContext(initialState);

    const mappedReducer = (state, action) => {
        return reducer[action.type](state, action);
    };

    const Provider = ({ children, state }) => {
        const [s, dispatch] = useReducer(mappedReducer, { ...initialState, ...state });

        return <Context.Provider value={{ state: s, dispatch }}>{children}</Context.Provider>;
    };

    Provider.propTypes = {
        children: PropTypes.node.isRequired,
        state: PropTypes.object,
    };

    const useProvider = () => useContext(Context);

    return [useProvider, Provider];
}
