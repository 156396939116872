import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentSection from '../content-section/content-section';
import { PageHeader } from '../../../../components';
import { processLeadershipData } from './components/process-leadership-data';
import { Chart } from '../../../../components';
import { formatNumber } from '../../../../utils/numbers';

import './style.scss';
import CompanyPageNavigationButtons from '../page-navigation-button/page-navigation-buttons';

export default function CompanyGovernance({ data }) {
    const whatIsThis = {
        tldr: {
            importance: 2,
            text: 'Insider transactions refer to the buying or selling of a company&#39;s stock by its executives, directors, and other insiders. Insiders can sell for any number of reasons, such as a desire to diversify, personal need for money, etc. However, insiders buy for only one reason: confidence in the stock.',
        },
        text: `<p>Insider transactions refer to the buying or selling of a company's stock by its executives, directors, and other insiders who have access to non-public, material information about the corporation. These transactions are closely watched by investors and analysts, as they can provide unique insights into the outlook of those who know the company best.  Buying indicates confidence, while selling might signal potential risks. Significant insider ownership can suggest an alignment of interests between management and shareholders.</p>
               
               `,
    };

    const whyIsThisImportant = {
        text: `Insider transactions can provide investors with valuable signals about a company's prospects and the confidence level of its insiders. Here's why they are important:
            <ul>
            <li><p><strong>Confidence Signal:</strong> Insider buying can serve as a positive signal. It can indicate that insiders have confidence in the company's future and believe that the current stock price is undervalued.</p></li>
            <li><p><strong>Risk Signal:</strong> On the other hand, insider selling can be a risk signal. While selling is not always a negative sign (as insiders might sell for personal reasons), a significant amount of selling could indicate potential problems that are not yet publicly known.</p></li>
            <li><p><strong>Alignment of Interests:</strong> When insiders own a significant portion of the company's stock, it aligns their interests with those of other shareholders. This alignment can potentially lead to better decisions being made for the company, as insiders have a vested interest in the company's success.</p></li>
            </ul>`,
    };

    const [governanceChartData, setGovernanceChartData] = useState([]);

    const processChartData = (data, type) => {
        const processedLeadershipData = processLeadershipData(data, type);
        setGovernanceChartData(processedLeadershipData.aggregateChartJsData);
    };

    useMemo(() => {
        processChartData(data, 'all');
    }, [data]);

    return (
        <div className="company-governance">
            <div className="company-page-header">
                <PageHeader text={'GOVERNANCE'} titleOnly={true} />{' '}
            </div>
            <div className="barchart-table-wrapper">
                <Chart
                    type="bar"
                    data={governanceChartData}
                    options={{
                        scales: {
                            x: {
                                ticks: {
                                    display: false,
                                },
                            },
                            y: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    drawBorder: false,
                                },
                            },
                        },
                    }}
                />
                <div className="flank-comparison">
                    <table className="flank-comparison-table bar-table">
                        <thead>
                            <tr className="bar-row">
                                <th className="icon"></th>
                                {governanceChartData.labels &&
                                    governanceChartData.labels.map((label, index) => (
                                        <th key={index}>
                                            <div className="header">{label}</div>
                                        </th>
                                    ))}
                            </tr>
                        </thead>
                        <tbody>
                            {governanceChartData.datasets &&
                                governanceChartData.datasets.map((dataset, idx) => (
                                    <tr key={idx} className="bar-row">
                                        <td className="icon">
                                            <img src={dataset.iconPath} />
                                        </td>
                                        {dataset.data.map((value, index) => (
                                            <td key={index}>
                                                <div className="cell-value">
                                                    {formatNumber(value, ['dollar', 'multiply', 1])}
                                                </div>
                                            </td>
                                        ))}
                                        <td className="remove"></td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="content-section">
                <ContentSection title="What is this?" {...whatIsThis} />
                <ContentSection title="Why is this important?" {...whyIsThisImportant} />
            </div>
            <CompanyPageNavigationButtons />
        </div>
    );
}

CompanyGovernance.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            transactions: PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string,
                    price: PropTypes.number,
                    change: PropTypes.number,
                    shares: PropTypes.number,
                })
            ),
            title: PropTypes.string,
        })
    ),
};
