import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function CurrencyInput({ name, label, defaultValue, companyName, register, validate, ...rest }) {
    delete rest.componentType;
    const [rawValue, setRawValue] = useState(defaultValue || '');
    const [displayValue, setDisplayValue] = useState('$');
    const [error, setError] = useState(false);

    useEffect(() => {
        let formatted = rawValue ? new Intl.NumberFormat().format(rawValue) : '';
        setDisplayValue(`$${formatted}`);
    }, [rawValue]);

    const handleOnChange = (e) => {
        let value = e.target.value;
        value = value.replace(/[^0-9.]/g, '');
        value = value.replace(/(\..*)\./g, '$1');

        if (value !== '' && !isNaN(value)) {
            setRawValue(value);
        } else {
            setRawValue('');
        }
    };

    function prettyDisplayValue(value) {
        if (value >= 1_000_000_000_000) {
            return `${(value / 1_000_000_000_000).toFixed(1)} trillion`;
        } else if (value >= 1_000_000_000) {
            return `${(value / 1_000_000_000).toFixed(1)} billion`;
        } else if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1)} million`;
        } else if (value >= 1_000) {
            return `${(value / 1_000).toFixed(1)} thousand`;
        }
        return value;
    }

    let registered = null;

    if (register) {
        registered = register(name, {
            onChange: handleOnChange,
            setValueAs: (value) => parseFloat(value.replace(/[^0-9.]/g, '')),
            validate: (value) => {
                if (!validate || typeof validate !== 'function') {
                    return true;
                }

                const validation = validate(value);
                if (!value || isNaN(value)) {
                    setError(validation);
                    return false;
                } else {
                    setError(false);
                    return true;
                }
            },
        });
    }

    return (
        <div className={`flank-form-group ${error ? 'shake' : ''}`}>
            {label && <label className="flank-form-label">{label}</label>}
            <input
                id={name}
                ref={registered.ref}
                name={registered.name}
                className="flank-form-control"
                onChange={registered.onChange}
                value={displayValue}
                {...rest}
            />
            {error && <p className="error">{error}</p>}
            <div className="valuation-text">
                {rawValue && `You value ${companyName} at $${prettyDisplayValue(parseFloat(rawValue))} dollars`}
            </div>
        </div>
    );
}

CurrencyInput.propTypes = {
    componentType: PropTypes.string,
    register: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    validate: PropTypes.func,
    onBlur: PropTypes.func,
    companyName: PropTypes.string,
};

CurrencyInput.defaultProps = {
    componentType: 'CurrencyInput',
};
