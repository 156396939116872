import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GuideHeadline } from '../../../components/Research/guide-headline';
import { Button } from '../../../components';
import './style.scss';
import { useQuery } from '@apollo/client';
import { GET_ALL_JOURNEYS, GET_ALL_THESISES } from '../../Company/gql-data';
import SearchBar from '../../Home/search-bar';
import { Spacer, Alert, Link, SearchResultRow } from '../../../components';
import { ReactSVG } from 'react-svg';
import ResearchButton from '../../../components/Button/special-buttons/researchButton';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../../../providers/sidebar';

export function StepZero({ setCurrentPage, currentPage }) {
    const [inProgress, setInProgress] = useState([]);
    const navigate = useNavigate();
    const { state: sidebarState } = useSidebar();

    const getCompanyPercentageChange = (company) => {
        if (!company) {
            return;
        }
    };

    const constructDataPoints = (company) => {
        const defaultDataPoints = [
            {
                title: 'Market Cap',
                value: company.marketCap,
                modifier: ['dollar', 'multiply', 1000000],
                inline: false,
                color: 'green',
            },
            {
                title: 'P/E Ratio',
                value: company.peRatio,
                modifier: ['ratio'],
                inline: false,
                color: 'green',
            },
            {
                title: 'Dividend Yield',
                value: company.dividendYield,
                modifier: ['ratio'],
                inline: false,
                color: 'green',
            },
        ];

        return defaultDataPoints;
    };

    const { data: watchlist } = useQuery(GET_ALL_THESISES, {
        variables: {
            filters: [
                {
                    field: 'tracking',
                    operator: 'eq',
                    value: 'watchlist',
                },
            ],
            groupBy: ['company.id'],
        },
    });

    const { data: myCompanies } = useQuery(GET_ALL_THESISES, {
        variables: {
            filters: [
                {
                    field: 'tracking',
                    operator: 'eq',
                    value: 'investing',
                },
            ],
            groupBy: ['company.id'],
        },
    });

    useQuery(GET_ALL_JOURNEYS, {
        onCompleted: (res) => {
            const rows = res.getAllUserJourneiesForSelf.rows;
            setInProgress(rows.filter((row) => row.status === 'in-progress'));
        },
    });

    const hasWatchlist = watchlist?.getAllUserCompanyThesisesForSelf?.rows?.length > 0;
    const hasInProgress = inProgress.length > 0;
    const hasStartedJourneys = hasWatchlist || hasInProgress;
    if (!hasStartedJourneys) {
        return (
            <>
                <div className="guided-research">
                    <SearchBar></SearchBar>
                    <GuideHeadline step="Step 0">
                        <div>
                            <h2>Getting Started with Flank</h2>
                        </div>
                        <p>
                            Investing <strong>smartly</strong> today is lonely, boring and overwhelming. We are here to
                            change that.
                        </p>
                        <p>
                            Flank gives you the tools to follow our trusted investing methodology that will make it
                            easier to perform your due diligence just like Warren Buffett and other great investors.
                        </p>
                        <p>
                            To begin your journey, click <strong>Find a Company</strong> below and we&apos;ll help you
                            select your first business to start researching!
                        </p>
                    </GuideHeadline>
                    <Button
                        className="start-button"
                        text={'Get Started Researching'}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <SearchBar></SearchBar>

                <section className="in-progress">
                    <h3 className="section-header">In Progress</h3>
                    <Spacer />
                    {inProgress.length === 0 ? (
                        <Alert type="info" text="You have no journeys in progress. Start one now!" />
                    ) : (
                        inProgress.map((data) => (
                            <div className="in-progress-row-wrapper" key={data.id}>
                                <Link to={`/company/${data.company.displaySymbol}/overview`} naked>
                                    <div className="in-progress-row">
                                        <div className="company">
                                            <img
                                                className="logo"
                                                src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${data.company.logoPath}`}
                                                alt={`${data.company.name} logo`}
                                            />
                                            <span className="name">{data.company.name}</span>
                                        </div>
                                        <ResearchButton progress={data.percentageComplete} showResearchButton={false} />
                                    </div>
                                </Link>
                                <Spacer />
                            </div>
                        ))
                    )}
                </section>

                <section className="my-companies">
                    <h3 className="section-header">My Companies</h3>
                    <Spacer />
                    {!myCompanies?.getAllUserCompanyThesisesForSelf?.rows ||
                    myCompanies?.getAllUserCompanyThesisesForSelf?.rows.length === 0 ? (
                        <Alert
                            type="info"
                            text="Your Companies List is empty. Click 'research' on a company and complete thesis!"
                        />
                    ) : (
                        myCompanies?.getAllUserCompanyThesisesForSelf?.rows &&
                        myCompanies.getAllUserCompanyThesisesForSelf.rows.map((data) => (
                            <div className="company-row-wrapper" key={data.id}>
                                <div className="company-row">
                                    <div className="company-container">
                                        <SearchResultRow
                                            company={data.company}
                                            previouslyClosed={{
                                                value: data.company.currentPrice,
                                                change: getCompanyPercentageChange(data.company),
                                                period: sidebarState.timescale,
                                            }}
                                            dataPoints={constructDataPoints(data.company)}
                                        />
                                    </div>

                                    <div>
                                        <Button
                                            className="view-thesis"
                                            text="View Thesis"
                                            greenOutline
                                            onClick={() => navigate(`/company/${data.company.displaySymbol}/overview`)}
                                        />
                                    </div>
                                </div>
                                <Spacer />
                            </div>
                        ))
                    )}
                </section>
                <section className="watchlist">
                    <h3 className="section-header">WatchList</h3>
                    <Spacer />
                    {!watchlist?.getAllUserCompanyThesisesForSelf?.rows ||
                    watchlist.getAllUserCompanyThesisesForSelf.rows.length === 0 ? (
                        <div className="empty-watchlist-message">
                            <Alert
                                type="info"
                                text="Your watchlist is empty. Finish thesis on a company to add to watchlist!"
                            />{' '}
                        </div>
                    ) : (
                        watchlist?.getAllUserCompanyThesisesForSelf?.rows &&
                        watchlist.getAllUserCompanyThesisesForSelf.rows.map((data) => (
                            <div className="watchlist-row-wrapper" key={data.id}>
                                <div className="watchlist-row">
                                    <div className="company">
                                        <img
                                            className="logo"
                                            src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${data.company.logoPath}`}
                                            alt={`${data.company.name} logo`}
                                        />
                                        <span className="name">{data.company.name}</span>
                                    </div>
                                    <div>
                                        <ReactSVG src="../../../assets/images/checkbox.svg" />
                                    </div>
                                </div>
                                <Spacer />
                            </div>
                        ))
                    )}
                </section>
                <div className="guided-research">
                    <GuideHeadline step="Step 0">
                        <div>
                            <h2>Find more companies on Flank!</h2>
                        </div>
                        <p>
                            Investing <strong>smartly</strong> today is lonely, boring and overwhelming. We&apos;re to
                            change that.
                        </p>
                        <p>
                            Flank gives you the tools to follow our trusted investing methodology that will make it
                            easier to perform your due diligence just like Warren Buffett and other great investors.
                        </p>
                        <p>
                            To begin your journey, click <strong>Find a Company</strong> below and we&apos;ll help you
                            select your first business to start researching!
                        </p>
                    </GuideHeadline>
                    <Button
                        className="start-button"
                        text={'Find More Companies'}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    />
                </div>
            </>
        );
    }
}

StepZero.propTypes = {
    setCurrentPage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
};
