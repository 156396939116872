import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../../../components';
import { ReactSVG } from 'react-svg';
import './style.scss';

export default function ExternalCompanyLinks({ company }) {
    return (
        <div className="company-links-wrapper">
            {company.wikiLink && (
                <div className="company-link">
                    <div className="link-icon">
                        <ReactSVG src="../../../assets/images/wikipedia.svg" />
                    </div>

                    <div className="link-text-wrapper">
                        <div className="link-text">
                            <Link
                                external
                                to={company.wikiLink}
                                text={`Read ${company.name}'s wikipedia`}
                                target="_blank"
                                rel="noopener noreferrer"
                            />
                        </div>
                        <div className="link-subtext">Learn the history, verticals, controversies, and more.</div>
                    </div>
                </div>
            )}

            {company.websiteLink && (
                <div className="company-link">
                    <div className="link-icon">
                        <img src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${company.logoPath}`} alt={company.name} />
                    </div>

                    <div className="link-text-wrapper">
                        <div className="link-text">
                            <Link
                                external
                                to={company.websiteLink}
                                text={`${company.name}'s website`}
                                target="_blank"
                                rel="noopener noreferrer"
                            />
                        </div>
                        <div className="link-subtext">Learn the history, verticals, controversies, and more.</div>
                    </div>
                </div>
            )}

            <div className="company-link">
                <div className="link-icon">
                    <ReactSVG src="../../../assets/images/google.svg" />
                </div>

                <div className="link-text-wrapper">
                    <div className="link-text">
                        <Link
                            external
                            to={`https://www.google.com/news?q=${company.name}`}
                            text={`Read the latest news about ${company.name}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    </div>
                    <div className="link-subtext">Learn the history, verticals, controversies, and more.</div>
                </div>
            </div>
        </div>
    );
}

ExternalCompanyLinks.propTypes = {
    company: PropTypes.object.isRequired,
};
