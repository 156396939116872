import React from 'react';
import PropTypes from 'prop-types';

export default function FormGroup({ chained, collected, children, ...rest }) {
    const classes = ['flank-form-group', ...(rest?.className || [])];
    if (chained) {
        classes.push('chained');
    }
    if (collected) {
        classes.push('collected');
    }

    return <div className={classes.join(' ')}>{children}</div>;
}

FormGroup.propTypes = {
    chained: PropTypes.bool,
    collected: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};
