import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Navigation from './components/navigation/navigation';
import ContentTemplate from '../ContentTemplate';

export default function BaseTemplate(props) {
    return (
        <div className="flank-base-layout">
            <div className="flank-base-navigation">
                <Navigation />
            </div>

            <div className="flank-base-content">
                <ContentTemplate {...props}>{props.children}</ContentTemplate>
            </div>
        </div>
    );
}

BaseTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};
