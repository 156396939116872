import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { CustomForm, Button } from '..';

export default function FilterPanel({ submitFilters, methods, clearFilters, children }) {
    const { isDirty } = methods.formState;
    const formState = methods.watch();
    let submitFormTimeout = useRef(null);
    let lastValues = useRef(formState);
    let dirty = useRef(null);

    useEffect(() => {
        if (
            (isDirty && !dirty.current) ||
            (dirty.current && JSON.stringify(formState) !== JSON.stringify(lastValues.current))
        ) {
            dirty.current = true;
            clearTimeout(submitFormTimeout.current);
            submitFormTimeout.current = setTimeout(() => {
                lastValues.current = { ...formState };
                submitFilters(formState);
                clearTimeout(submitFormTimeout.current);
            }, 300);

            return () => clearTimeout(submitFormTimeout.current);
        }
    }, [formState, isDirty, submitFilters]);

    return (
        <div className="flank-filter-panel">
            <div className="flank-filter-panel-header">
                Filter
                <Button type="button" text="Clear Filters" onClick={clearFilters} />
            </div>
            <CustomForm onSubmit={submitFilters} methods={methods}>
                {children}
            </CustomForm>
        </div>
    );
}

FilterPanel.propTypes = {
    submitFilters: PropTypes.func.isRequired,
    methods: PropTypes.object.isRequired,
    clearFilters: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};
