import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Link } from '../../../../../components';
import { toFixed } from '../../../../../utils/numbers';
import { useCompany } from '../../../../../providers/company';

export default function CompanyDataTable({ symbol, data, timescale, priceHistory }) {
    const { state } = useCompany();

    if (!data || !symbol) {
        return null;
    }

    const sandpIndex = data.industry.findIndex((collection) => collection === 'S&P 500');

    let industryName = null;
    if (sandpIndex === data.industry.length - 1) {
        industryName = data.industry[0];
    } else {
        industryName = data.industry[data.industry.length - 1];
    }

    const industry = state.company.collections.find((collection) => collection.name === industryName);
    const sandp = state.company.collections.find((collection) => collection.name === 'S&P 500');

    let timescalePhrase;
    switch (timescale) {
        case '1M':
            timescalePhrase = 'one month ago';
            break;
        case '3M':
            timescalePhrase = 'three months ago';
            break;
        case '6M':
            timescalePhrase = 'six months ago';
            break;
        case '1Y':
            timescalePhrase = 'one year ago';
            break;
        case '5Y':
            timescalePhrase = 'five years ago';
            break;
        default:
            timescalePhrase = 'one month ago';
    }

    const calculatePriceDifference = (priceHistory) => {
        if (priceHistory.length < 2) {
            return 0;
        }

        const firstPrice = priceHistory[0].price;
        const lastPrice = priceHistory[priceHistory.length - 1].price;
        return lastPrice - firstPrice;
    };
    const priceDifference = calculatePriceDifference(priceHistory);

    const initialInvestment = 1000;
    let investmentValue = 0;
    if (priceHistory > 0) {
        investmentValue = initialInvestment + (initialInvestment * priceDifference) / priceHistory[0].price || 0;
    }
    return (
        <div className="company-data-table">
            <div className="row">
                <div className="label">
                    If you invested $1,000 <span>{timescalePhrase}</span>
                </div>

                <div className="value">${toFixed(investmentValue)}</div>
            </div>
            <div className="row">
                <div className="label">Previous close</div>

                <div className="value">${data.previousClose}</div>
            </div>
            <div className="row">
                <div className="label">Year range</div>

                <div className="value">
                    ${data.yearRange[0]} - ${data.yearRange[1]}
                </div>
            </div>

            <div className="row">
                <div className="label">Dividend yield (TTM)</div>

                {!data.dividendYield ? (
                    <div className="value">-</div>
                ) : (
                    <div className="multi-value">
                        <div className="row">
                            <div className="label">${symbol}</div>
                            <div className="value">{data.dividendYield ? `${data.dividendYield}%` : '-'}</div>
                        </div>
                        <div className="row">
                            <div className="label">
                                <Link to={`/collection/${industryName}`}>{industryName}</Link>
                            </div>
                            <div className="value">{industry ? `${industry.dividendYieldAvg}%` : '-'}</div>
                        </div>
                        <div className="row">
                            <div className="label">
                                <Link to="/">S&P 500</Link>
                            </div>
                            <div className="value">
                                {state.company.collections[0].dividendYieldAvg
                                    ? `${state.company.collections[0].dividendYieldAvg}%`
                                    : '-'}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="row">
                <div className="label">Price/Earnings</div>

                <div className="multi-value">
                    <div className="row">
                        <div className="label">${symbol}</div>
                        <div className="value">{data?.pe ? data.pe : '-'}</div>
                    </div>
                    <div className="row">
                        <div className="label">
                            <Link to={`/collection/${industryName}`}>{industryName}</Link>
                        </div>
                        <div className="value">{industry ? industry.peAvg : '-'}</div>
                    </div>
                    <div className="row">
                        <div className="label">
                            <Link to="/">S&P 500</Link>
                        </div>
                        <div className="value">{sandp?.peAvg ? toFixed(sandp.peAvg) : '-'}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CompanyDataTable.propTypes = {
    symbol: PropTypes.string.isRequired,
    data: PropTypes.object,
    timescale: PropTypes.string.isRequired,
    priceHistory: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })
    ).isRequired,
};
