import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSidebar } from '../../providers/sidebar';
import { useLazyQuery } from '@apollo/client';
import { GET_SIDEBAR_COMPANY_MARKET_CAPS, GET_SIDEBAR_SPARK_DATA } from '../../gql-data';
import { cloneDeep } from 'lodash';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
    ArcElement,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import { getRandomColor } from '../../colors';
import { LoadingModal, PercentageChange } from '..';
import { ReactSVG } from 'react-svg';
import { IoChevronForward } from 'react-icons/io5';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement
);

// use this for debugging, didn't want to get rid of the logs
// because they are pretty useful where they are
// remove `=== false` to enable
const log = (...args) => {
    // eslint-disable-next-line
    if (true === false) console.log(...args);
};

function SidebarItem({
    collection,
    pieData,
    index,
    colors,
    openIds,
    currentId,
    sidebarDispatch,
    sidebarState,
    stack,
    setStack,
    topLevel,
    children,
    childrenLoading,
    sparkLoading,
    sparkData,
}) {
    const trendColorMap = { up: '#1F793C', down: '#A50F0D' };
    const navigate = useNavigate();
    // const [type, setType] = useState();
    const isCurrent = useMemo(() => stack && stack[stack.length - 1] === collection.id, [stack, collection.id]);
    const [chartData, setChartData] = useState(null);
    const thisSparkData = useMemo(() => {
        if (sparkData && sparkData[collection.id]) {
            return sparkData[collection.id];
        }

        return null;
    }, [sparkData, collection.id]);

    // pie chart data
    useEffect(() => {
        if (pieData) {
            setChartData({
                labels: [],
                datasets: [
                    {
                        label: '# of Votes',
                        data: pieData.map((i) => i.value),
                        backgroundColor: pieData.map((i) =>
                            topLevel && stack.length > 0 ? i.color : i.index === index ? i.color : '#fff'
                        ),
                        borderWidth: 0,
                        borderColor: '#fff',
                    },
                ],
            });
        }
    }, [pieData, index, stack.length, topLevel]);

    // type (collection or company) onload
    useEffect(() => {
        // setType(collection.children || collection.companies ? 'collection' : 'company');
    }, [collection.children, collection.companies]);

    // open children callback
    const openChildren = () => {
        if (currentId === collection.id) {
            return;
        }

        const temp = [...stack];
        if (topLevel && stack && stack.length > 0) {
            temp.pop();
        } else {
            temp.push(collection.id);
        }

        if (!collection.children && !collection.companies) {
            navigate(`/company/${collection.displaySymbol}/overview`, { replace: false });
            sidebarDispatch({
                type: 'setIsOpen',
                isOpen: false,
            });
        } else {
            setStack(temp);
        }
    };

    // const lengthString = useMemo(() => {
    //     let temp;
    //     let type = '';

    //     if (collection.children && collection.children.length > 0) {
    //         temp = collection.children.length;
    //         type = 'industr';
    //     } else if (collection.companies && collection.companies.length > 0) {
    //         temp = collection.companies.length;
    //         type = 'compan';
    //     } else {
    //         return null;
    //     }

    //     return `${temp} ${type}${temp > 1 ? 'ies' : 'y'}`;
    // }, [collection]);

    return (
        <div className="sidebar-item">
            <button
                onClick={() => openChildren()}
                className={['sidebar-item-title', currentId === collection.id ? 'active' : null]
                    .filter(Boolean)
                    .join(' ')}
            >
                <div
                    className={[
                        'sidebar-item-icon',
                        thisSparkData &&
                            (thisSparkData.change > 0 ? 'up' : 'down',
                            !collection.children && !collection.companies ? 'company' : 'chart'),
                    ].join(' ')}
                >
                    {!collection.children && !collection.companies ? (
                        <img src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${collection.logoPath}`} alt="" />
                    ) : (
                        chartData && (
                            <Pie
                                className="sidebar-item-chart"
                                data={chartData}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        tooltip: {
                                            enabled: false,
                                        },
                                    },
                                }}
                            />
                        )
                    )}
                </div>
                <div className="sidebar-item-text">
                    <div className="sidebar-item-text-title">{collection.name}</div>
                    {thisSparkData && <PercentageChange value={thisSparkData.change} />}
                    {/* {type !== 'none' && <div className="sidebar-item-text-subtitle">{lengthString}</div>} */}
                </div>
                {sparkLoading ? (
                    <ReactSVG className="spark-spinner" src="../../../assets/images/spinner.svg" />
                ) : (
                    thisSparkData && (
                        <div className="spark-data">
                            <Line
                                className="spark-chart"
                                data={{
                                    labels: thisSparkData.sparkData.map((i) => i),
                                    datasets: [
                                        {
                                            label: 'value',
                                            data: thisSparkData.sparkData,
                                            fill: false,
                                            backgroundColor: 'transparent',
                                            borderColor: trendColorMap[thisSparkData.change > 0 ? 'up' : 'down'],
                                            pointRadius: 0,
                                            pointHoverRadius: 0,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                options={{
                                    animation: false,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        tooltip: {
                                            enabled: false,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            display: false,
                                        },
                                        y: {
                                            display: false,
                                        },
                                    },
                                }}
                            />
                        </div>
                    )
                )}
            </button>

            {childrenLoading ? (
                <LoadingModal block={true} />
            ) : (
                isCurrent &&
                children &&
                children.length > 0 && (
                    <div className="sidebar-item-subitems">
                        {children.map((child, index) => (
                            <SidebarItem
                                key={child.id}
                                collection={child}
                                index={index}
                                pieData={children.map((collection, i) => {
                                    let color = colors[collection.id];

                                    return {
                                        index: i,
                                        value: collection.marketCap,
                                        color,
                                    };
                                })}
                                colors={colors}
                                openIds={openIds}
                                currentId={currentId}
                                sidebarDispatch={sidebarDispatch}
                                sidebarState={sidebarState}
                                stack={stack}
                                setStack={setStack}
                                topLevel={false}
                                sparkLoading={sparkLoading}
                                sparkData={sparkData}
                            />
                        ))}
                    </div>
                )
            )}
        </div>
    );
}

function Sidebar() {
    const { state, dispatch } = useSidebar();
    const [stack, setStack] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [collections, setCollections] = useState([]);
    const [children, setChildren] = useState([]);
    const [parent, setParent] = useState(null);
    const [pieData, setPieData] = useState(null);
    const [cache, setCache] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    const [getCompanyMarketCaps, { loading: marketCapLoading }] = useLazyQuery(GET_SIDEBAR_COMPANY_MARKET_CAPS, {
        onCompleted: ({ getCurrentMarketCapsForCollection: data }) => {
            if (!parent) {
                return;
            }
            let c = [];
            if (parent.children && parent.children.length > 0) {
                c = cloneDeep(parent.children);
            } else if (parent.companies && parent.companies.length > 0) {
                c = cloneDeep(parent.companies);
            }
            c.forEach((child, i) => {
                const childData = data.find((d) => d.id === child.id);

                if (childData) {
                    c[i].marketCap = childData.marketCap;
                }
            });

            setChildren(c);
            setCache({
                ...cache,
                [parent.id]: c,
            });
        },
    });

    const [getSparkData, { loading: sparkLoading }] = useLazyQuery(GET_SIDEBAR_SPARK_DATA, {
        onCompleted: ({ getSidebarDataForCollection: data }) => {
            const parentId = parent ? parent.id : '-1';

            let obj = {};
            data.forEach((d) => {
                if (!obj[d.id]) {
                    obj[d.id] = {
                        sparkData: [],
                        change: null,
                    };
                }
                obj[d.id].sparkData.push(d.value);
            });

            const cacheData = {};

            for (const id in obj) {
                let last = obj[id].sparkData[0];
                let first = obj[id].sparkData[obj[id].sparkData.length - 1];
                let change = (last - first) / first;
                obj[id].change = change;
                obj[id].sparkData = obj[id].sparkData.reverse();

                cacheData[id] = obj[id];
            }

            dispatch({
                type: 'addSparkCache',
                id: parentId,
                data: cacheData,
            });
            log('got spark data for', parent ? parent.id : '-1', obj);
            dispatch({
                type: 'addSparkParentLoaded',
                id: parent ? parent.id : '-1',
            });
        },
    });

    const getCollections = useCallback(
        (collections, id) => {
            if (stack.length > 0) {
                if (!id) {
                    id = stack[0];
                }
            } else {
                return collections;
            }

            let collection = collections.find((c) => c.id === id);
            if (id === stack[stack.length - 1]) {
                return [collection];
            } else {
                return getCollections(collection.children, stack[stack.length - 1]);
            }
        },
        [stack]
    );

    const getBreadCrumbs = useCallback(
        (collections, id) => {
            let crumbs = [];
            if (stack.length > 0) {
                if (!id) {
                    id = stack[0];
                }
            } else {
                return crumbs;
            }

            let collection = collections.find((c) => c.id === id);
            if (collection) {
                crumbs.push(collection);
            }

            if (id !== stack[stack.length - 1]) {
                crumbs.push(...getCollections(collection.children, stack[stack.length - 1]));
            }

            return crumbs;
        },
        [stack, getCollections]
    );

    const generateCompanyPaths = useCallback(
        (collections, namePrefix, idPrefix) => {
            let stack = { names: [], ids: [] };
            if (!collections) {
                collections = state.collections;
            }
            if (!namePrefix) {
                namePrefix = '';
            }
            if (!idPrefix) {
                idPrefix = '';
            }

            for (const collection of collections) {
                const name = namePrefix ? namePrefix + '.' + collection.name : collection.name;
                const id = idPrefix ? idPrefix + '.' + collection.id : collection.id;
                stack.names.push(name);
                stack.ids.push(id);

                if (collection.children) {
                    const inner = generateCompanyPaths(collection.children, name, id);
                    stack.names.push(...inner.names);
                    stack.ids.push(...inner.ids);
                }

                if (!collection.children && collection.companies) {
                    for (const company of collection.companies) {
                        stack.names.push(name + '.' + company.displaySymbol);
                        stack.ids.push(id + '.' + company.id);
                    }
                }
            }

            return stack;
        },
        [state.collections]
    );

    const findIdPathFromName = (name, stack, type) => {
        const index = stack.names.findIndex((i) => {
            return i.split('.').pop() === name;
        });
        if (index > -1) {
            const idStack = stack.ids[index].split('.');
            if (type === 'company') {
                return idStack.slice(0, idStack.length - 1);
            } else {
                return idStack;
            }
        }

        return null;
    };

    const findIdPathFromId = (id, stack) => {
        const index = stack.ids.findIndex((i) => {
            return i.split('.').pop() === id;
        });

        return index > -1 ? stack.ids[index].split('.') : null;
    };

    const getCollectionFromIdPath = useCallback(
        (idPath, collections) => {
            if (!collections || !idPath) {
                collections = state.collections;
            }
            if (idPath.length === 1) {
                return collections.find((c) => c.id === idPath[0]);
            } else {
                const collection = collections.find((c) => c.id === idPath[0]);
                if (!collection) {
                    return null;
                }
                return getCollectionFromIdPath(idPath.slice(1), collection.children);
            }
        },
        [state.collections]
    );

    const stackPaths = useMemo(() => generateCompanyPaths(), [generateCompanyPaths]);
    const colors = useMemo(() => {
        const tempColors = {};
        for (const idPath of stackPaths.ids) {
            const id = idPath.split('.').pop();
            tempColors[id] = getRandomColor();
        }
        return tempColors;
    }, [stackPaths.ids]);

    useEffect(() => {
        setBreadcrumbs(getBreadCrumbs(state.collections));
        setCollections(getCollections(state.collections));
    }, [state.collections, stack, getBreadCrumbs, getCollections]);

    useEffect(() => {
        let newParent = null;

        if (stack.length > 0) {
            const id = stack[stack.length - 1];
            log('go into', id);
            log('current collection set', collections);
            const idPath = findIdPathFromId(id, stackPaths);
            log('id path', idPath);
            log('parent collection', getCollectionFromIdPath(idPath, state.collections));
            newParent = getCollectionFromIdPath(idPath, state.collections);
        }

        setParent(newParent);
    }, [stack, collections, getCollectionFromIdPath, stackPaths, state.collections]);

    useEffect(() => {
        if (state.sparkParents && state.sparkCache) {
            if (
                !state.sparkParents?.[state.timescale] ||
                !(state.sparkParents?.[state.timescale] || []).includes(parent ? parent.id : '-1')
            ) {
                log(
                    'havent loaded spark data for',
                    parent ? parent.id : '-1',
                    'with the timescale',
                    state.timescale,
                    'yet, loading now'
                );
                getSparkData({
                    variables: {
                        getSidebarDataForCollectionId: parent ? parent.id : null,
                        timescale: state.timescale,
                    },
                });
            } else {
                log(
                    'already loaded spark data for',
                    parent ? parent.id : '-1',
                    'with the timescale',
                    state.timescale,
                    state.sparkCache[state.timescale][parent ? parent.id : '-1']
                );
            }
        }
    }, [parent, state.timescale, state.sparkCache, getSparkData, state.sparkParents]);

    useEffect(() => {
        if (parent && (parent.companies || parent.children)) {
            log('get companies for', parent.id, cache);
            if (cache[parent.id]) {
                setChildren(cache[parent.id]);
            } else {
                getCompanyMarketCaps({
                    variables: {
                        getCurrentMarketCapsForCollectionId: parent.id,
                    },
                });
            }
        }
    }, [parent, cache, getCompanyMarketCaps]);

    useEffect(() => {
        setPieData(
            state.collections.map((collection, i) => {
                let color = colors[collection.id];

                return {
                    index: i,
                    value: collection.marketCap,
                    color,
                };
            })
        );
    }, [state.collections, colors]);

    useEffect(() => {
        let locationParts = location.pathname.split('/');
        let type = locationParts[1] === 'company' ? 'company' : 'collection';
        let current = decodeURIComponent(locationParts[2]);

        if (!current) {
            setParent(null);
            return;
        }

        const path = findIdPathFromName(current, stackPaths, type);
        if (path) {
            setStack(path);
        }
    }, [location.pathname, stackPaths]);

    useEffect(() => {
        let pieChartData = children;

        if (pieChartData.length > 0) {
            setPieData(
                pieChartData.map((collection, i) => {
                    let color = colors[collection.id];

                    return {
                        index: i,
                        value: collection.marketCap,
                        color,
                    };
                })
            );
        }
    }, [children, colors]);

    return (
        <div className="flank-sidebar-wrapper">
            <div className="breadcrumbs">
                <button
                    className="breadcrumb-btn"
                    onClick={() => {
                        dispatch({ type: 'setIsOpen', isOpen: false });
                        navigate('/');
                        setStack([]);
                    }}
                >
                    S&P 500
                </button>
                {breadcrumbs.filter(Boolean).map((collection, index) => {
                    return (
                        <button
                            className="breadcrumb-btn"
                            key={index}
                            onClick={() => {
                                dispatch({ type: 'setIsOpen', isOpen: false });
                                navigate(`/collection/${collection.name}`);
                            }}
                        >
                            <IoChevronForward className="breadcrumb-separator" />
                            {collection.name}
                        </button>
                    );
                })}
            </div>
            {state.loading || (parent === null && (marketCapLoading || sparkLoading)) ? (
                <LoadingModal block={true} />
            ) : (
                collections.filter(Boolean).map((collection, index) => {
                    if (stack.length > 0 && !stack.includes(collection.id)) {
                        return null;
                    }

                    return (
                        <SidebarItem
                            key={collection.id}
                            collection={collection}
                            pieData={pieData}
                            index={index}
                            colors={colors}
                            openIds={state.openIds}
                            currentId={state.currentId}
                            sidebarDispatch={dispatch}
                            sidebarState={state}
                            stack={stack}
                            setStack={setStack}
                            topLevel={true}
                            childrenLoading={marketCapLoading}
                            sparkLoading={sparkLoading}
                            sparkData={state.sparkCache[state.timescale]?.[parent ? parent.id : '-1']}
                        >
                            {children}
                        </SidebarItem>
                    );
                })
            )}
        </div>
    );
}

export default memo(Sidebar);

SidebarItem.propTypes = {
    collection: PropTypes.object,
    pieData: PropTypes.array,
    index: PropTypes.number,
    colors: PropTypes.object,
    openIds: PropTypes.array,
    currentId: PropTypes.string,
    sidebarDispatch: PropTypes.func,
    sidebarState: PropTypes.object,
    stack: PropTypes.array,
    setStack: PropTypes.func,
    topLevel: PropTypes.bool,
    children: PropTypes.array,
    childrenLoading: PropTypes.bool,
    sparkLoading: PropTypes.bool,
    sparkData: PropTypes.object,
};
