import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
// import { PercentageChange } from '../../../../components';
import { formatNumber } from '../../../../utils/numbers';

export default function BalanceSheetTable({ data, balanceSheetTimescale }) {
    const balanceSheetMetrics = [
        { key: 'totalAssets', displayName: 'TOTAL ASSETS', formatting: ['dollar', 'multiply', 1000000] },
        { key: 'totalLiabilities', displayName: 'TOTAL LIABILITIES', formatting: ['dollar', 'multiply', 1000000] },
        { key: 'bookValue', displayName: 'BOOK VALUE', formatting: ['dollar', 'multiply', 1000000] },
    ];

    // let latestYear = data[0].totalAssets[data[0].totalAssets.length - 1].period.split;

    return (
        <div className="financials-container full-content-width">
            <table className="financials">
                <thead>
                    <tr>
                        <th className="metrics"></th>
                        {data.map((item, index) => (
                            <th className="table-values time" key={index}>
                                <div>{balanceSheetTimescale === 'Y' ? item.year : item.quarter}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {balanceSheetMetrics.map((metricObject) => {
                        const metric = metricObject.key;
                        const metricDisplayName = metricObject.displayName;
                        const metricFormatting = metricObject.formatting;
                        return (
                            <tr key={metric}>
                                <td className="metrics">{metricDisplayName}</td>
                                {data.map((item, index) => (
                                    <td className="table-values" key={index}>
                                        <div>
                                            {formatNumber(
                                                balanceSheetTimescale === 'Y'
                                                    ? item[metric][0].value
                                                    : item[metric][1].value,
                                                metricFormatting
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

BalanceSheetTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            totalAssets: PropTypes.array.isRequired,
            totalLiabilities: PropTypes.array.isRequired,
            bookValue: PropTypes.array.isRequired,
        })
    ).isRequired,
    balanceSheetTimescale: PropTypes.string,
};
