import React from 'react';
import PropTypes from 'prop-types';
import SearchResultBrick from '../SearchResultBrick';
import PercentageChange from '../PercentageChange';
import './style.scss';
import Link from '../Link';

export default function SearchResultRow({ company, previouslyClosed, dataPoints }) {
    return (
        <div className="search-result-row-container">
            <img
                src={`${process.env.REACT_APP_LOGO_ENDPOINT}/${company.logoPath}`}
                alt={`${company.name} logo`}
                id="company-logo"
            />
            <div className="company-details-container">
                <div className="header">
                    <Link to={`/$${company.displaySymbol}`} plain={true} target="_blank">
                        ${company.displaySymbol.toLowerCase()}
                    </Link>
                    <span className="company-name">{company.name}</span>
                </div>
                <div className="bricks-row">
                    <SearchResultBrick
                        title="Previously Close"
                        value={previouslyClosed.value}
                        modifier={['dollar', 'multiply', 1]}
                        color={'green'}
                        inline
                    />
                    <PercentageChange
                        value={previouslyClosed.change}
                        range={previouslyClosed.period ?? '1y'}
                        border
                        className="percentage-change-range"
                    />
                </div>
                <div className="bricks-row">
                    <SearchResultBrick
                        title="Industry"
                        value={company.industry}
                        modifier={['default']}
                        color={'green'}
                        inline
                    />
                </div>
                <div className="bricks-row">
                    {dataPoints.map((data) => (
                        <SearchResultBrick
                            title={data.title}
                            value={data.value}
                            modifier={data.modifier}
                            color={data.color}
                            inline={data.inline}
                            key={data.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

SearchResultRow.propTypes = {
    company: PropTypes.object,
    previouslyClosed: PropTypes.shape({
        value: PropTypes.number,
        change: PropTypes.number,
        period: PropTypes.string,
    }),
    dataPoints: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
            modifier: PropTypes.array.isRequired,
            color: PropTypes.oneOf(['red', 'green', 'gray']),
            inline: PropTypes.bool,
        })
    ),
};
