import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { companySizesMarketCap, companySizesBookValue } from '../search-filters-data';
import { Popover, Badge, HorizontalScrollable } from '../../../components';
import '../style.scss';

export const CompanySizeFilterBy = {
    MARKET_CAP: 'marketCap',
    BOOK_VALUE: 'bookValue',
};

export default function CompanySizeFilters({
    onChange,
    companySize = {
        field: CompanySizeFilterBy.MARKET_CAP,
        values: [],
    },
}) {
    function renderCompanySizes() {
        const data =
            companySize.field === CompanySizeFilterBy.MARKET_CAP ? companySizesMarketCap : companySizesBookValue;
        return data.map((cSize) => {
            return (
                <Badge
                    key={cSize.label}
                    label={cSize.label}
                    selected={isCompanySizeSelected(cSize.label !== 'All' ? cSize.label : undefined)}
                    onClick={() => onCompanySizeClick(cSize.label !== 'All' ? cSize : undefined)}
                />
            );
        });
    }

    function changeCompanyFilterBy(label) {
        if (label !== companySize.field) {
            onChange(label, []);
        }
    }

    function onCompanySizeClick(size) {
        if (!size) {
            onChange(companySize.field, []);
            return;
        }

        const previouslySelectedSize = companySize.values.find((c) => c.label === size.label);
        if (previouslySelectedSize) {
            const newArray = companySize.values.filter((s) => s.label !== previouslySelectedSize.label);
            onChange(companySize.field, newArray);
        } else {
            const newArray = [...companySize.values, size];

            const allCompanySizes = CompanySizeFilterBy.MARKET_CAP ? companySizesMarketCap : companySizesBookValue;

            let maxIndex = -1;
            let minIndex = -1;
            for (let j = 0; j < newArray.length; j++) {
                const index = allCompanySizes.findIndex((s) => s.label === newArray[j].label);
                if (maxIndex === -1 || index > maxIndex) maxIndex = index;
                if (minIndex === -1 || index < minIndex) minIndex = index;
            }

            for (let i = minIndex; i <= maxIndex; i++) {
                const size = allCompanySizes[i];
                if (!newArray.some((s) => s.label === size.label)) {
                    newArray.push(size);
                }
            }

            const sorted = newArray.sort((a, b) => {
                const aValue = Array.isArray(a.value) ? a.value[0] : a.value;
                const bValue = Array.isArray(b.value) ? b.value[0] : b.value;

                return aValue - bValue;
            });

            onChange(companySize.field, sorted);
        }
    }

    const isCompanySizeSelected = useCallback(
        (label) => {
            if (!label) {
                return companySize.values.length === 0;
            }
            return companySize.values.some((c) => c.label === label);
        },
        [companySize, onchange]
    );

    return (
        <>
            <div className="company-size-header">
                <h3>Company Size</h3>
                <Popover
                    label={companySize.field === CompanySizeFilterBy.MARKET_CAP ? 'Market Cap' : 'Book Value'}
                    openLeft={true}
                    id="company-size-category"
                    actions={[
                        {
                            type: 'button',
                            label: 'Market Cap',
                            onClick: () => {
                                changeCompanyFilterBy(CompanySizeFilterBy.MARKET_CAP);
                            },
                        },
                        {
                            type: 'button',
                            label: 'Book Value',
                            onClick: () => {
                                changeCompanyFilterBy(CompanySizeFilterBy.BOOK_VALUE);
                            },
                        },
                    ]}
                />
            </div>

            <HorizontalScrollable>{renderCompanySizes()}</HorizontalScrollable>
        </>
    );
}

CompanySizeFilters.propTypes = {
    onChange: PropTypes.func,
    companySize: PropTypes.shape({
        field: PropTypes.oneOf(['marketCap', 'bookValue']),
        values: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
            })
        ),
    }),
};
