import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { loadMainCompanyData } from './services/company-data-svc';
import { useFooter } from '../../providers/template';
import { useTitle } from '../../hooks/use-title';
import { CompanyProvider } from '../../providers/company';
import CompanyPageHandler from './components/company-page-handler';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, LoadingModal } from '../../components';
import { useNavigate } from 'react-router-dom';
import CompanyPages from './company-pages';
import { useSidebar } from '../../providers/sidebar';
export default function CompanyPage({ setSidebarLinks }) {
    useTitle('Company');
    useFooter(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { dispatch: sidebarDispatch } = useSidebar();
    const { '*': page } = useParams();
    const { symbol } = useParams();
    const [companyData, setCompanyData] = useState({});

    const [loadCompanyData, { loading: loadingCompanyData, error: companyDataError }] = loadMainCompanyData({
        onCompleted: ({ getOneCompany: data }) => {
            setCompanyData(data);
        },
    });

    useMemo(() => {
        loadCompanyData({
            variables: {
                filter: [
                    {
                        field: 'displaySymbol',
                        operator: 'eq',
                        value: symbol,
                    },
                ],
            },
        });
    }, [symbol]);

    const currentPage = useMemo(() => {
        const routePage = page.split('/');
        const parent = CompanyPages[routePage[0]];
        const sub = parent?.children?.[routePage[1]];

        const currentPage = [];

        if (!parent) {
            navigate('/not-found');
            return;
        } else {
            currentPage.push(routePage[0]);
            if (routePage.length > 1) {
                if (!sub) {
                    navigate('/not-found');
                    return;
                } else {
                    currentPage.push(routePage[1]);
                }
            }
        }

        return currentPage;
    }, []);

    useEffect(() => {
        sidebarDispatch({
            type: 'setIsOpen',
            isOpen: false,
        });
    }, [location.pathname]);

    if (loadingCompanyData) {
        return <LoadingModal block={true} text={'Loading...'} />;
    }

    if (companyDataError) {
        return <Alert type="danger" text={companyDataError.message} />;
    }

    return (
        <CompanyProvider state={{ symbol, company: companyData, filterIds: [companyData.id], currentPage }}>
            <CompanyPageHandler setSidebarLinks={setSidebarLinks} />
        </CompanyProvider>
    );
}

CompanyPage.propTypes = {
    setCompany: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSidebarLinks: PropTypes.func.isRequired,
};
