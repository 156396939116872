import React from 'react';
import PropTypes from 'prop-types';
import { useCompany } from '../../../../providers/company';
import ContentSection from '../content-section/content-section';
import CashFlowTable from '../cash-flow-table';
import { Popover, PageHeader } from '../../../../components';
import './style.scss';
import CompanyPageNavigationButtons from '../page-navigation-button/page-navigation-buttons';
import { IoCreateOutline } from 'react-icons/io5';
import SectionHeader from '../../../../components/SectionHeader';

export default function CompanyCashFlow({ data }) {
    const { state, dispatch } = useCompany();

    const whatIsThis = {
        tldr: {
            importance: 3,
            text: 'The Cash Flow Statement is a financial report that shows how changes in balance sheet accounts and income affect a company&#39;s cash and cash equivalents, broken down into operating, investing, and financing activities. It provides key insights into a company&#39;s <strong>liquidity, solvency, and investment potential.</strong> It&#39;s helpful for understanding the company&#39;s ability to generate cash, which is vital for operations, paying debts, and returning value to shareholders.',
        },
        text: `<p>The Cash Flow Statement, also known as the Statement of Cash Flows, is one of the three main financial statements that businesses use to report their financial performance and position, the other two being the Balance Sheet and the Income Statement.</p>
            <p>The Cash Flow Statement shows how changes in balance sheet accounts and income affect cash and cash equivalents, and breaks the analysis down according to three main types of business activities:</p>
            <ul>
                <li><p><strong>Operating Activities:</strong> This section reports the cash effects of transactions that create revenues and expenses, thus affecting net income. It reflects how much cash is generated from a company&#39;s core business operations.</p></li>
                <li><p><strong>Investing Activities:</strong> This part shows the cash flow from the purchase and sale of long-term assets and investments. Examples include the purchase of machinery or the proceeds from selling a building.</p></li>
                <li><strong>Financing Activities:</strong> This area reveals the cash flow from all financing activities, such as issuing stock, paying dividends, or borrowing from a bank.</li>
            </ul>`,
    };

    const whyIsThisImportant = {
        text: `Understanding the cash flow statement is crucial for both internal and external stakeholders of a business:
        <ul>
        <li><p><strong>Liquidity and Solvency:</strong> The Cash Flow Statement provides important insights into a company&#39;s liquidity (its ability to meet short-term obligations) and solvency (its ability to continue in the long term).</p></li>
        <li><p><strong>Cash Generation:</strong> While the Income Statement includes non-cash items like depreciation and can be affected by accrual accounting, the Cash Flow Statement shows how much actual cash a company is generating.</p></li>
        <li><strong>Investment Decisions:</strong> Investors use the Cash Flow Statement to understand where a company&#39;s cash is coming from and how it&#39;s being used. This can be vital in assessing the company&#39;s quality of earnings and investment potential.</p>`,
    };

    return (
        <div className="company-cash-flow-statement">
            <div className="company-page-header">
                <PageHeader
                    text={'Cash Flow Statement'}
                    action={
                        <Popover
                            label={state.financialTimescale === 'Y' ? 'Yearly' : 'Quarterly'}
                            openLeft={true}
                            actions={[
                                {
                                    type: 'button',
                                    label: 'Yearly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Y' });
                                    },
                                },
                                {
                                    type: 'button',
                                    label: 'Quarterly',
                                    onClick: () => {
                                        dispatch({ type: 'setFinancialTimescale', financialTimescale: 'Q' });
                                    },
                                },
                            ]}
                        />
                    }
                    titleOnly={true}
                />{' '}
            </div>
            <CashFlowTable data={data} cashFlowTimescale={state.financialTimescale} />
            <div className="content-section">
                <ContentSection title="What is this?" {...whatIsThis} />
                <ContentSection title="Why is this important?" {...whyIsThisImportant} />
            </div>
            <SectionHeader
                text="The Societe"
                Icon={IoCreateOutline}
                iconLink={`/company/${state.company.displaySymbol}/create-post`}
                linkState={{ origin: 'SectionHeader', topLevelResource: 'overview' }}
            />
            <CompanyPageNavigationButtons />
        </div>
    );
}

CompanyCashFlow.propTypes = {
    data: PropTypes.array,
    financialTimescale: PropTypes.string,
};
