import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const SelectInput = forwardRef(function SelectInput({ register, name, label, options, ...rest }, ref) {
    delete rest.componentType;
    let registered = null;
    if (register) {
        registered = register(name);
    }

    return (
        <div className="flank-form-group">
            {label && (
                <label className="flank-form-label" htmlFor={name}>
                    {label}
                </label>
            )}
            <select
                {...registered}
                ref={(e) => {
                    if (ref) ref.current = e;
                    registered?.ref(e);
                }}
                id={name}
                className="flank-form-control"
                {...rest}
            >
                {options &&
                    options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
            </select>
        </div>
    );
});

SelectInput.defaultProps = {
    componentType: 'SelectInput',
};

export default SelectInput;

SelectInput.propTypes = {
    register: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    validate: PropTypes.object,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};
