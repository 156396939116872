/**
 * callback to run when search query is completed,
 * generates a function for the callback where we just
 * pass in the set state function and it uses/passes
 * that to the callback where it is used
 */
export const searchQueryCompleted = (setSearchResults, setResultPageData) => {
    return (data) => {
        if (data.getAllCompanies.rows.length > 0) {
            setSearchResults((r) => [...r, ...data.getAllCompanies.rows]);
            setResultPageData({
                currentPage: data.getAllCompanies.currentPage,
                totalPages: data.getAllCompanies.totalPages,
            });
        } else {
            setSearchResults([]);
            setResultPageData({
                currentPage: 1,
                totalPages: 1,
            });
        }
    };
};

/**
 * compiles all of the filters required to search,
 * then calls the search request callback with the
 * compiled filters
 */
export const search = (filters, searchRequestCallback) => {
    return searchRequestCallback({
        variables: {
            ...filters,
        },
    });
};

export const loadMoreSearchResults = (filters, searchRequestCallback, currentPage) => {
    return searchRequestCallback({
        variables: {
            page: currentPage + 1,
            ...filters,
        },
    });
};
