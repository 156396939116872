import { gql } from '@apollo/client';

export const GET_ONE_COMPANY_THESIS = gql`
    query GetOneUserCompanyThesisForSelf($filter: [WhereClause!]!) {
        getOneUserCompanyThesisForSelf(filter: $filter) {
            id
            thesis
            sentiment
            tracking
            valuation
            createdAt
            positionOpened
            company {
                marketCap
            }
        }
    }
`;
