import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
    BarElement,
} from 'chart.js';
import { merge } from 'lodash';
import LineChart from './line-chart';
import BubbleChart from './bubble-chart';
import BarChart from './bar-chart';

export default function Chart({ type, options, data, setChartDateSelected, setToolTipActive }) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        BarController,
        BarElement
    );

    const defaultOptions = {
        events: ['click', 'mouseout', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend'],
        animation: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                intersect: false,
                mode: 'index',
            },
            hover: {
                mode: 'index',
                intersect: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
    };

    if (type === 'line') {
        return (
            <LineChart
                data={data}
                options={merge(defaultOptions, options || {})}
                setChartDateSelected={setChartDateSelected}
                setToolTipActive={setToolTipActive}
            />
        );
    }

    if (type === 'bubble') {
        return (
            <BubbleChart
                data={data}
                options={merge(defaultOptions, options || {})}
                setToolTipActive={setToolTipActive}
            />
        );
    }

    if (type === 'bar') {
        return (
            <BarChart data={data} options={merge(defaultOptions, options || {})} setToolTipActive={setToolTipActive} />
        );
    }

    return null;
}

Chart.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    options: PropTypes.object,
    setToolTipActive: PropTypes.func,
    setChartDateSelected: PropTypes.func,
};
