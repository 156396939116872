import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Link, Popover, Alert, Button, ButtonChain } from '..';
import { IoArrowUpOutline, IoArrowDownOutline } from 'react-icons/io5';

function TableHeaderCell({ header, headerMap, orderBy, onOrderChange }) {
    const headerValue = headerMap?.[header];

    if (!headerValue) {
        return null;
    }

    const headerText = headerValue && typeof headerValue === 'object' ? headerValue.value : headerValue;
    const sortable = headerValue && typeof headerValue === 'object' ? headerValue.sortable : true;

    const cellClasses = ['flank-table-cell'];
    if (headerValue && typeof headerValue === 'object' && headerValue.omitOnMobile) {
        cellClasses.push('omit-on-mobile');
    }

    let orderIcon = null;
    if (orderBy && orderBy.length > 0 && orderBy.find) {
        const orderByHeader = orderBy.find((order) => order.field === header);
        if (orderByHeader) {
            if (orderByHeader.direction === 'asc') {
                orderIcon = <IoArrowUpOutline />;
            } else {
                orderIcon = <IoArrowDownOutline />;
            }
        }
    }

    const handleOrderChange = () => {
        if (orderBy && orderBy.length > 0) {
            const orderByHeader = orderBy.find((order) => order.field === header);
            if (orderByHeader) {
                if (orderByHeader.direction === 'asc') {
                    onOrderChange([{ field: header, direction: 'desc' }]);
                } else {
                    onOrderChange([]);
                }
            } else {
                onOrderChange([{ field: header, direction: 'asc' }]);
            }
        } else {
            onOrderChange([{ field: header, direction: 'asc' }]);
        }
    };

    return (
        <th className={cellClasses.join(' ')}>
            {onOrderChange && sortable ? (
                <button type="button" className="cell-button" onClick={handleOrderChange}>
                    {headerText}
                    {orderIcon}
                </button>
            ) : (
                headerText
            )}
        </th>
    );
}

function TableRow({ cell, actions, headerMap }) {
    const ignoreCells = ['omitOnMobile'];

    const renderObject = (cell) => {
        if (cell.to) {
            return <Link to={cell.to}>{cell.text}</Link>;
        } else if (cell.value) {
            return cell.value;
        }

        return null;
    };

    let popoverActions = [];
    if (actions) {
        actions
            .map((a) => ({ ...a }))
            .forEach((action) => {
                if (action.to && typeof action.to === 'function') {
                    action.to = action.to(cell);
                }

                if (action.label && typeof action.label === 'function') {
                    action.label = action.label(cell);
                }

                popoverActions.push(action);
            });
    }

    const rawData = {};
    Object.keys(cell).forEach((key) => {
        rawData[key] = typeof cell[key] === 'object' && cell[key] ? cell[key].text : cell[key];
    });

    const cells = Object.keys(headerMap || cell)
        .filter((key) => !ignoreCells.includes(key))
        .map((key) => {
            if (headerMap && headerMap[key] === undefined) {
                return null;
            }

            const cellClasses = ['flank-table-cell'];
            if (headerMap && headerMap[key] && typeof headerMap[key] === 'object' && headerMap[key].omitOnMobile) {
                cellClasses.push('omit-on-mobile');
            }

            if (cell[key] === undefined || cell[key] === null) {
                return (
                    <td className={cellClasses.join(' ')} key={Math.random()}>
                        [ERROR!] TableRow: cell missing key &quot;${key}&quot; from header
                    </td>
                );
            }

            return (
                <td className={cellClasses.join(' ')} key={typeof cell[key] === 'object' ? cell[key].text : cell[key]}>
                    {typeof cell[key] === 'object' ? renderObject(cell[key]) : cell[key].toString()}
                </td>
            );
        });

    return (
        <tr className="flank-table-row">
            {cells}
            {popoverActions.length > 0 && (
                <td className="flank-table-row-actions">
                    <Popover actions={popoverActions} openLeft data={rawData} />
                </td>
            )}
        </tr>
    );
}

export default function Table({
    data,
    details,
    actions,
    pushLeft,
    pagination,
    onPageChange,
    onLimitChange,
    onOrderChange,
    noHeader,
    loadingModal,
    isLoading,
    tableActions,
}) {
    const [tableRef, setTableRef] = useState(null);
    const [shadowLeft, setShadowLeft] = useState(null);
    const [shadowRight, setShadowRight] = useState(null);
    const [scrollable, setScrollable] = useState(false);
    const [horizontalScrollable, setHorizontalScrollable] = useState(true);

    useEffect(() => {
        if (data.length > 0 && scrollable) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setScrollable(false);
        }
    }, [data, scrollable]);

    useEffect(() => {
        const scrollShadow = () => {
            const total = tableRef.scrollWidth - tableRef.offsetWidth;
            const left = tableRef.scrollLeft;
            const opacityPercentage = left / total;

            if (isNaN(opacityPercentage)) {
                setHorizontalScrollable(false);
            } else {
                setHorizontalScrollable(true);

                if (shadowLeft && shadowRight) {
                    shadowLeft.style.opacity = opacityPercentage;
                    shadowRight.style.opacity = 1 - opacityPercentage;
                }
            }
        };

        if (tableRef) {
            scrollShadow();
            tableRef.addEventListener('scroll', scrollShadow);
            window.addEventListener('resize', scrollShadow);
            return () => {
                if (tableRef) {
                    tableRef.removeEventListener('scroll', scrollShadow);
                    window.removeEventListener('resize', scrollShadow);
                }
            };
        }
    }, [tableRef, shadowLeft, shadowRight]);

    const tableClasses = ['flank-table'];
    if (pushLeft) {
        tableClasses.push('push-left');
    }

    const changePage = (page) => {
        if (onPageChange) {
            setScrollable(true);
            onPageChange(page);
        }
    };

    const changeLimit = (limit) => {
        if (onLimitChange) {
            setScrollable(true);
            onLimitChange(limit);
        }
    };

    const paginationItems = [];
    if (pagination) {
        for (let i = 1; i <= pagination.totalPages; i++) {
            paginationItems.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
    }

    const renderTableActions = (tableActions) => {
        return tableActions.map((action, index) => {
            if (action.type === 'link') {
                return <Link key={index} {...action} buttonClass />;
            } else if (action.type === 'button') {
                return <Button key={index} {...action} buttonClass />;
            }

            return null;
        });
    };

    if (!data || data.length === 0) {
        return <Alert text="No results found" />;
    }

    if (isLoading) {
        return loadingModal;
    }

    return (
        <div className="flank-table-wrapper with-actions">
            {tableActions && tableActions.length > 0 && (
                <div className="flank-table-actions">
                    <ButtonChain>{renderTableActions(tableActions)}</ButtonChain>
                </div>
            )}
            <div style={{ position: 'relative' }}>
                {horizontalScrollable && (
                    <>
                        <div ref={(e) => setShadowLeft(e)} className="shadow shadow-left" />
                        <div ref={(e) => setShadowRight(e)} className="shadow shadow-right" />
                    </>
                )}
                <div ref={(e) => setTableRef(e)} className="flank-table-container">
                    <table className={tableClasses.join(' ')}>
                        {!noHeader && (
                            <thead className="flank-table-header">
                                <tr className="flank-table-row">
                                    {Object.keys(details.headerMap || data[0]).map((key) => {
                                        return (
                                            <TableHeaderCell
                                                key={key}
                                                header={key}
                                                headerMap={details.headerMap}
                                                onOrderChange={onOrderChange}
                                                orderBy={pagination?.orderBy}
                                            />
                                        );
                                    })}

                                    {actions && actions.length > 0 && <th className="flank-table-row-actions"></th>}
                                </tr>
                            </thead>
                        )}
                        <tbody className="flank-table-body">
                            {data.map((cell) => {
                                let key =
                                    details.key && typeof details.key === 'function'
                                        ? details.key(cell)
                                        : cell[details.key || Object.keys(data[0])[0]];
                                if (typeof key === 'object') {
                                    key = key.text || key.value;
                                }
                                return (
                                    <TableRow
                                        actions={actions ? [...actions] : []}
                                        cell={cell}
                                        key={key}
                                        headerMap={details.headerMap || {}}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {pagination && (
                <div className="flank-table-pagination">
                    <div className="flank-table-pagination-controls">
                        {pagination.totalPages > 1 && onPageChange && (
                            <>
                                <button
                                    type="button"
                                    onClick={() => changePage(pagination.current - 1)}
                                    className="pagination-control buttons"
                                    disabled={pagination.current === 1}
                                >
                                    &lt;
                                </button>
                                <button
                                    type="button"
                                    onClick={() => changePage(pagination.current + 1)}
                                    className="pagination-control buttons"
                                    disabled={pagination.current === pagination.totalPages}
                                >
                                    &gt;
                                </button>
                                <span className="pagination-text">page: </span>
                                <select
                                    className="pagination-control"
                                    defaultValue={pagination.current || 1}
                                    onChange={(e) => changePage(parseInt(e.target.value))}
                                >
                                    {paginationItems}
                                </select>
                                <span className="pagination-text of">
                                    of <strong>{pagination.totalPages}</strong>
                                </span>
                                <div className="separator"></div>
                            </>
                        )}

                        {onLimitChange && (
                            <>
                                <span className="pagination-text">limit: </span>
                                <select
                                    className="pagination-control"
                                    defaultValue={pagination.limit}
                                    onChange={(e) => changeLimit(parseInt(e.target.value))}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </>
                        )}
                    </div>

                    <div className="flank-table-pagination-info">
                        {pagination.totalPages === 1 && (
                            <span className="pagination-text of">
                                {pagination.current} of <strong>{pagination.totalPages}</strong>
                            </span>
                        )}
                        <span className="pagination-text">{pagination.total} total records</span>
                    </div>
                </div>
            )}
        </div>
    );
}

TableHeaderCell.propTypes = {
    header: PropTypes.string.isRequired,
    headerMap: PropTypes.object,
    onOrderChange: PropTypes.func,
    orderBy: PropTypes.string,
};

TableRow.propTypes = {
    actions: PropTypes.array,
    cell: PropTypes.object.isRequired,
    headerMap: PropTypes.object,
};

Table.propTypes = {
    data: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
    actions: PropTypes.array,
    pushLeft: PropTypes.bool,
    pagination: PropTypes.object,
    onPageChange: PropTypes.func,
    onLimitChange: PropTypes.func,
    onOrderChange: PropTypes.func,
    noHeader: PropTypes.bool,
    loadingModal: PropTypes.element,
    isLoading: PropTypes.bool,
    tableActions: PropTypes.array,
};
