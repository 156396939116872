import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IoArrowBackOutline, IoArrowForwardOutline } from 'react-icons/io5';
import './style.scss';
import { cleanClasses } from '../../../../utils/utils';

export default function CompanyPageNavigationButton({ path, text, direction }) {
    return (
        <Link className="flank-page-navigation-button" to={path}>
            <div className={cleanClasses(['direction', direction])}>
                {direction === 'previous' ? 'Previously' : 'Next up'}
            </div>
            <div className="page-title">
                {direction === 'previous' && <IoArrowBackOutline />}
                {text}
                {direction === 'next' && <IoArrowForwardOutline />}
            </div>
        </Link>
    );
}

CompanyPageNavigationButton.propTypes = {
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['previous', 'next']),
};
