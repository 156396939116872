import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { HorizontalScrollable, Badge } from '../../../components';
import { ReactSVG } from 'react-svg';
import './style.scss';

export default function SectorFilters({ layout, onChange, selectedFilters = [] }) {
    (() => {})(layout);
    const onSectorClick = (label) => {
        if (!label) {
            onChange([]);
            return;
        }

        if (selectedFilters.includes(label)) {
            const newArray = selectedFilters.filter((s) => s !== label);
            onChange(newArray);
        } else {
            const newArray = [...selectedFilters, label];
            onChange(newArray);
        }
    };

    const isSectorSelected = useCallback(
        (label) => {
            if (!label) {
                return selectedFilters.length === 0;
            }
            return selectedFilters.includes(label);
        },
        [selectedFilters, onChange]
    );

    function renderBadges() {
        return (
            <>
                <Badge
                    label="Technology"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Technology.svg" />}
                    bigBox
                    selected={isSectorSelected('Technology')}
                    onClick={() => onSectorClick('Technology')}
                />
                <Badge
                    label="Healthcare"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Healthcare.svg" />}
                    bigBox
                    selected={isSectorSelected('Healthcare')}
                    onClick={() => onSectorClick('Healthcare')}
                />
                <Badge
                    label="Financials"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Financials.svg" />}
                    bigBox
                    selected={isSectorSelected('Financials')}
                    onClick={() => onSectorClick('Financials')}
                />
                <Badge
                    label="Real Estate"
                    icon={
                        <ReactSVG
                            className=""
                            src="../../../assets/images/icons/sector-icons/Real Estate.svg"
                            style={{ width: '24px', height: '24px' }}
                        />
                    }
                    bigBox
                    selected={isSectorSelected('Real Estate')}
                    onClick={() => onSectorClick('Real Estate')}
                />
                <Badge
                    label="Energy"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Energy.svg" />}
                    bigBox
                    selected={isSectorSelected('Energy')}
                    onClick={() => onSectorClick('Energy')}
                />
                <Badge
                    label="Materials"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/materials.svg" />}
                    bigBox
                    selected={isSectorSelected('Materials')}
                    onClick={() => onSectorClick('Materials')}
                />
                <Badge
                    label="Consumer Discretionary"
                    icon={
                        <ReactSVG
                            className=""
                            src="../../../assets/images/icons/sector-icons/Consumer Discretionary.svg"
                        />
                    }
                    bigBox
                    selected={isSectorSelected('Consumer Discretionary')}
                    onClick={() => onSectorClick('Consumer Discretionary')}
                />
                <Badge
                    label="Industrials"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Industrials.svg" />}
                    bigBox
                    selected={isSectorSelected('Industrials')}
                    onClick={() => onSectorClick('Industrials')}
                />
                <Badge
                    label="Consumer Staples"
                    icon={
                        <ReactSVG className="" src="../../../assets/images/icons/sector-icons/Consumer Staples.svg" />
                    }
                    bigBox
                    selected={isSectorSelected('Consumer Staples')}
                    onClick={() => onSectorClick('Consumer Staples')}
                />
                <Badge
                    label="Communications"
                    icon={
                        <ReactSVG
                            className=""
                            src="../../../assets/images/icons/sector-icons/Communications.svg"
                            style={{ width: '24px', height: '24px' }}
                        />
                    }
                    bigBox
                    selected={isSectorSelected('Communications')}
                    onClick={() => onSectorClick('Communications')}
                />
                <Badge
                    label="Utilities"
                    icon={<ReactSVG className="" src="../../../assets/images/icons/sector-icons/Utilities.svg" />}
                    bigBox
                    selected={isSectorSelected('Utilities')}
                    onClick={() => onSectorClick('Utilities')}
                />
            </>
        );
    }
    return layout === 'scroll' ? (
        <HorizontalScrollable>{renderBadges()}</HorizontalScrollable>
    ) : (
        <div className="grid-layout-container">{renderBadges()}</div>
    );
}

SectorFilters.propTypes = {
    layout: PropTypes.oneOf(['grid', 'scroll']),
    onChange: PropTypes.func,
    selectedFilters: PropTypes.arrayOf(PropTypes.string),
};
