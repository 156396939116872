import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
    query GetOneCompany($filter: [WhereClause!]!) {
        getOneCompany(filter: $filter) {
            id
            displaySymbol
            name
            logoPath
            updatedAt
            description
            wikiLink
            websiteLink
            industry
            collections {
                id
                name
                peAvg
                dividendYieldAvg
            }
        }
    }
`;

export const GET_PRICE_HISTORY = gql`
    query GetPriceHistoryForCompany($range: String!, $getPriceHistoryForCompanyId: String!) {
        getPriceHistoryForCompany(range: $range, id: $getPriceHistoryForCompanyId) {
            price
            date
            marketCap
        }
    }
`;

export const GET_COMPANY_QUICK_DATA = gql`
    query GetCompanyQuickData($getCompanyQuickDataId: String!, $range: String!) {
        getCompanyQuickData(id: $getCompanyQuickDataId, range: $range) {
            previousClose
            yearRange
            currentMarketCap
            timescaleMarketCap
            marketCapChange
            industry
            dividendYield
            pe
        }
    }
`;

export const GET_COMPANIES_INCOME_STATEMENT_QUERY = gql`
    query GetCompaniesIncomeStatement($ids: [String!]!, $range: String!) {
        getCompaniesIncomeStatement(ids: $ids, range: $range) {
            revenue {
                value
                period
            }
            costOfGoodsSold {
                value
                period
            }
            grossProfit {
                value
                period
            }
            grossProfitMargin {
                value
                period
            }
            operatingExpenses {
                value
                period
            }
            operatingIncome {
                value
                period
            }
            operatingMargin {
                value
                period
            }
            totalOtherIncome {
                value
                period
            }
            pretaxIncome {
                value
                period
            }
            incomeTaxExpense {
                value
                period
            }
            netIncome {
                value
                period
            }
            earningsPerShare {
                value
                period
            }
            company {
                id
                name
                displaySymbol
                logoPath
            }
        }
    }
`;

export const GET_COMPANIES_BALANCE_SHEET_QUERY = gql`
    query GetCompaniesBalanceSheet($ids: [String!]!, $range: String!) {
        getCompaniesBalanceSheet(ids: $ids, range: $range) {
            company {
                id
                name
                displaySymbol
                logoPath
            }
            totalAssets {
                value
                period
            }
            currentAssets {
                value
                period
            }
            totalLiabilities {
                value
                period
            }
            bookValue {
                value
                period
            }
            cashEquivalents {
                value
                period
            }
            inventory {
                value
                period
            }
            accountsReceivable {
                value
                period
            }
            otherReceivables {
                value
                period
            }
            longTermAssets {
                value
                period
            }
            propertyEquipment {
                value
                period
            }
            intangibleAssets {
                value
                period
            }
            goodwill {
                value
                period
            }
            otherLongTermAssets {
                value
                period
            }
            currentLiabilities {
                value
                period
            }
            longTermLiabilities {
                value
                period
            }
            accountsPayable {
                value
                period
            }
            shortTermDebt {
                value
                period
            }
            otherCurrentLiabilities {
                value
                period
            }
            longTermDebt {
                value
                period
            }
            otherLongTermLiabilities {
                value
                period
            }
        }
    }
`;

export const GET_COMPANIES_CASH_FLOW_QUERY = gql`
    query GetCompaniesCashFlow($ids: [String!]!, $range: String!) {
        getCompaniesCashFlow(ids: $ids, range: $range) {
            company {
                id
                name
                displaySymbol
                logoPath
            }
            netIncome {
                value
                period
            }
            adjustmentToNetCashOperating {
                value
                period
            }
            depreciationAmortization {
                value
                period
            }
            otherAdjustmentsToNetCashOperating {
                value
                period
            }
            netOperatingCashFlow {
                value
                period
            }
            cashFlowFromInvestingActivities {
                value
                period
            }
            capex {
                value
                period
            }
            otherAdjustmentsInvesting {
                value
                period
            }
            cashFlowFromFinancingActivities {
                value
                period
            }
            stockBuybacks {
                value
                period
            }
            otherAdjustmentsFromFinancingActivity {
                value
                period
            }
            changeInCash {
                value
                period
            }
        }
    }
`;

export const COMPANY_GOVERNANCE_QUERY = gql`
    query GetCompanyLeadership($getCompanyLeadershipId: String!) {
        getCompanyLeadership(id: $getCompanyLeadershipId) {
            firstName
            lastName
            title
            age
            compensation
            since
            transactions {
                date
                code
                price
                change
                shares
            }
        }
    }
`;

export const COMPANY_STRATEGY_QUERY = gql`
    query Future($filter: [WhereClause!]!) {
        getOneCompany(filter: $filter) {
            id
            future {
                headline
                datetime
                url
            }
        }
    }
`;

export const START_JOURNEY = gql`
    mutation StartJourney($companyId: String!) {
        startJourney(companyId: $companyId) {
            balanceSheetStatus
            cashFlowStatus
            createdAt
            governanceStatus
            id
            incomeStatementStatus
            overviewStatus
            percentageComplete
            status
            strategyStatus
            thesisStatus
        }
    }
`;

export const GET_ONE_JOURNEY = gql`
    query GetOneUserJourneyForSelf($filter: [WhereClause!]!) {
        getOneUserJourneyForSelf(filter: $filter) {
            id
            overviewStatus
            percentageComplete
            status
            strategyStatus
            thesisStatus
            incomeStatementStatus
            governanceStatus
            cashFlowStatus
            balanceSheetStatus
        }
    }
`;

export const UPDATE_JOURNEY = gql`
    mutation UpdateJourneyProgress($data: UserJourneyStepProgress!, $journeyId: String!) {
        updateJourneyProgress(data: $data, journeyId: $journeyId) {
            balanceSheetStatus
            cashFlowStatus
            createdAt
            createdBy
            governanceStatus
            id
            incomeStatementStatus
            overviewStatus
            percentageComplete
            status
            strategyStatus
            thesisStatus
        }
    }
`;

export const COMPLETE_JOURNEY = gql`
    mutation CompleteJourney($journeyId: String!) {
        completeJourney(journeyId: $journeyId) {
            id
            status
        }
    }
`;
export const CREATE_THESIS = gql`
    mutation CreateUserCompanyThesisForSelf($data: CreateUserCompanyThesis!) {
        createUserCompanyThesisForSelf(data: $data) {
            id
        }
    }
`;

export const GET_ALL_JOURNEYS = gql`
    query GetAllUserJourniesForSelf {
        getAllUserJourneiesForSelf {
            rows {
                id
                balanceSheetStatus
                cashFlowStatus
                governanceStatus
                incomeStatementStatus
                overviewStatus
                percentageComplete
                status
                strategyStatus
                thesisStatus
                company {
                    displaySymbol
                    name
                    logoPath
                }
            }
        }
    }
`;

export const GET_ALL_THESISES = gql`
    query GetAllUserCompanyThesisesForSelf($groupBy: [String!], $filters: [WhereClause!]) {
        getAllUserCompanyThesisesForSelf(groupBy: $groupBy, filters: $filters) {
            rows {
                company {
                    id
                    name
                    logoPath
                    revenue
                    displaySymbol
                    industry
                    marketCap
                    peRatio
                    dividendYield
                    currentPrice
                }
                id
                sentiment
                thesis
                tracking
                valuation
            }
        }
    }
`;

export const CREATE_COMPANY_POST_FOR_SELF_MUTATION = gql`
    mutation CreatePostForSelf($data: CreatePost!) {
        createPostForSelf(data: $data) {
            id
            slug
        }
    }
`;

export const GET_ONE_COMPANY_SOCIAL_POST_FOR_SELF = gql`
    query GetOnePostForSelf($filter: [WhereClause!]!) {
        getOnePostForSelf(filter: $filter) {
            id
            type
            title
            subtitle
            content
            coverImage
            slug
            topLevelResource
            company {
                displaySymbol
            }
        }
    }
`;

export const UPDATE_COMPANY_POST_FOR_SELF_MUTATION = gql`
    mutation UpdatePostForSelf($data: UpdatePost!, $updatePostForSelfId: String!) {
        updatePostForSelf(data: $data, id: $updatePostForSelfId) {
            id
        }
    }
`;

export const GET_ALL_POSTS = gql`
    query GetAllPosts($filters: [WhereClause!]) {
        getAllPosts(filters: $filters) {
            currentPage
            rows {
                company {
                    name
                    logoPath
                    displaySymbol
                }
                content
                title
                reports {
                    id
                    createdBy
                    contextId
                }
                coverImage
                subtitle
                slug
                id
                publishedAt
                id
                createdByUser {
                    username
                    firstName
                    lastName
                }
                comments {
                    id
                }
            }
        }
    }
`;

export const GET_ONE_POST = gql`
    query GetOnePost($filter: [WhereClause!]!) {
        getOnePost(filter: $filter) {
            slug
            id
            reports {
                id
                createdBy
                contextId
            }
            coverImage
            subtitle
            title
            content
            createdBy
            createdAt
            publishedAt
            id
            company {
                displaySymbol
                logoPath
            }
            createdByUser {
                firstName
                lastName
                username
            }
            comments {
                id
            }
        }
    }
`;

export const GET_ONE_POST_FOR_SELF = gql`
    query GetOnePostForSelf($filter: [WhereClause!]!) {
        getOnePostForSelf(filter: $filter) {
            slug
            publishedAt
            subtitle
            title
            coverImage
            content
            company {
                logoPath
                displaySymbol
            }
            createdByUser {
                firstName
                lastName
                username
            }
            comments {
                id
            }
            reports {
                id
                createdBy
                contextId
            }
        }
    }
`;

export const GET_ALL_POSTS_FOR_SELF = gql`
    query GetAllPostsForSelf($filters: [WhereClause!]) {
        getAllPostsForSelf(filters: $filters) {
            currentPage
            rows {
                createdByUser {
                    firstName
                    lastName
                    username
                }
                company {
                    logoPath
                    displaySymbol
                }
                title
                reports {
                    id
                    createdBy
                    contextId
                }
                subtitle
                content
                coverImage
                createdAt
                publishedAt
                slug
                id
                comments {
                    id
                }
            }
            totalPages
            limit
        }
    }
`;

export const CREATE_COMMENT_MUTATION = gql`
    mutation CreateCommentForSelf($data: CreateComment!) {
        createCommentForSelf(data: $data) {
            post {
                id
            }
            postId
            parentId
            createdBy
            createdAt
            content
            id
            user {
                username
                firstName
                lastName
            }
            children {
                id
                content
                user {
                    username
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_ALL_COMMENTS = gql`
    query GetAllComments($filters: [WhereClause!], $orderBy: [OrderBy!], $limit: Float) {
        getAllComments(filters: $filters, orderBy: $orderBy, limit: $limit) {
            rows {
                id
                post {
                    slug
                    id
                }
                reports {
                    id
                    reason
                    createdBy
                }
                user {
                    firstName
                    lastName
                    username
                }
                parentId
                createdAt
                publishedAt
                createdBy
                content
                children {
                    reports {
                        reason
                        createdBy
                    }
                    user {
                        firstName
                        lastName
                        username
                    }
                    parentId
                    id
                    content
                    createdBy
                    createdAt
                    publishedAt
                    children {
                        user {
                            firstName
                            lastName
                            username
                        }
                        reports {
                            reason
                            createdBy
                        }
                        parentId
                        id
                        content
                        createdBy
                        createdAt
                        publishedAt
                        children {
                            user {
                                firstName
                                lastName
                                username
                            }
                            reports {
                                reason
                            }
                            parentId
                            id
                            content
                            createdBy
                            createdAt
                            publishedAt
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ALL_COMMENTS_FOR_SELF = gql`
    query GetAllCommentsForSelf($limit: Float, $filters: [WhereClause!], $orderBy: [OrderBy!]) {
        getAllCommentsForSelf(limit: $limit, filters: $filters, orderBy: $orderBy) {
            limit
            rows {
                id
                post {
                    slug
                    id
                    title
                    company {
                        logoPath
                        displaySymbol
                    }
                }
                reports {
                    id
                    reason
                    createdBy
                }
                content
                createdBy
                createdAt
                publishedAt
                user {
                    username
                    lastName
                    firstName
                    email
                }
                children {
                    user {
                        firstName
                        lastName
                        username
                    }
                    reports {
                        reason
                        createdBy
                    }
                    parentId
                    id
                    content
                    createdBy
                    createdAt
                    publishedAt
                    children {
                        user {
                            firstName
                            lastName
                            username
                        }
                        reports {
                            reason
                            createdBy
                        }
                        parentId
                        id
                        content
                        createdBy
                        createdAt
                        publishedAt
                        children {
                            user {
                                firstName
                                lastName
                                username
                            }
                            reports {
                                reason
                            }
                            parentId
                            id
                            content
                            createdBy
                            createdAt
                            publishedAt
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_REPORT_MUTATION = gql`
    mutation CreateReport($data: CreateReport!) {
        createReport(data: $data) {
            reason
            createdBy
            createdAt
            id
            contextId
        }
    }
`;
